<template>
	<div class="CaseDetails mb-3">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<Notivue v-slot="item">
			<Notification :item="item" :theme="theme" />
		</Notivue>
		<!-- メインコンテンツ -->
		<template v-if="bind_data.fbs_info">
			<div class="container text-start">
				<b-button v-on:click="back()" variant="outline-secondary" class="m-2">戻る</b-button>

				<!-- コメント表示 -->
				<template v-if="isConsult()">
					<!-- <b-card header-bg-variant="info" header-text-variant="white" bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h5 class="mb-0 font-weight-bold">相談内容</h5>
					</template>
					<b-card-body>
						<b-row>
							<b-col cols="3">
								<b>コメント日時</b>
							</b-col>
							<b-col cols="9">
								{{bind_data.fbs_info.fbs_comment_date_time}}
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="3">
								<b>コメント</b>
							</b-col>
							<b-col cols="9" class="report-comment">
								{{bind_data.fbs_info.fbs_comment}}
							</b-col>
						</b-row>
					</b-card-body>
				</b-card> -->

					<template v-if="isShowConsult">
						<b-card header-bg-variant="warning" class="mx-2 mb-2">
							<template #header>
								<h5 class="mb-0 float-start">相談内容</h5>
								<b-button v-if="local_data.consultList.length > 1" v-b-toggle.collapse-1 size="sm" class="float-end">{{
									consultListCallaoseBtnTitle
								}}</b-button>
							</template>
							<div v-for="(consult, index) in local_data.consultList" :key="consult">
								<b-collapse
									v-model="state_data.consultListVisible"
									id="collapse-1"
									v-if="index < local_data.consultList.length - 1">
									<table style="width: 100%">
										<template v-if="consult.cnm_consult_content != ''">
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">相談日時</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.cnm_consult_date_time }}</td>
											</tr>
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">相談内容</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">
													<span style="white-space: pre-wrap">{{ consult.cnm_consult_content }}</span>
												</td>
											</tr>
										</template>
										<template v-if="consult.cnm_status == 'answer'">
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">回答日時</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.cnm_answer_date_time }}</td>
											</tr>
											<tr v-if="consult.contact_user_name != ''">
												<td valign="top" style="width: 1%; white-space: nowrap">回答者</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.contact_user_name }}</td>
											</tr>
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">回答内容</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">
													<span style="white-space: pre-wrap">{{ consult.cnm_answer_content }}</span>
												</td>
											</tr>
										</template>
									</table>
									<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red">
										この相談はキャンセルされました。
									</div>
									<hr />
								</b-collapse>
								<template v-else>
									<table style="width: 100%">
										<template v-if="consult.cnm_consult_content != ''">
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">相談日時</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.cnm_consult_date_time }}</td>
											</tr>
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">相談内容</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">
													<span style="white-space: pre-wrap">{{ consult.cnm_consult_content }}</span>
												</td>
											</tr>
										</template>
										<template v-if="consult.cnm_status == 'answer'">
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">回答日時</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.cnm_answer_date_time }}</td>
											</tr>
											<tr v-if="consult.contact_user_name != ''">
												<td valign="top" style="width: 1%; white-space: nowrap">回答者</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">{{ consult.contact_user_name }}</td>
											</tr>
											<tr>
												<td valign="top" style="width: 1%; white-space: nowrap">回答内容</td>
												<td valign="top" style="width: 1%; white-space: nowrap">：</td>
												<td valign="top">
													<span style="white-space: pre-wrap">{{ consult.cnm_answer_content }}</span>
												</td>
											</tr>
										</template>
									</table>
									<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red">
										この相談はキャンセルされました。
									</div>
								</template>
							</div>
						</b-card>
					</template>
				</template>

				<!-- 事案データ編集 -->
				<b-row>
					<b-col>
						<b-card header-bg-variant="light" class="mx-2 mb-4">
							<template #header>
								<h4 class="m-0 p-0 font-weight-bold">番号</h4>
							</template>
							<div v-if="bind_data.fbs_info.fbs_version_number">{{ bind_data.fbs_info.fbs_version_number }}</div>
							<div v-else>-</div>
						</b-card>
					</b-col>
					<b-col>
						<b-card header-bg-variant="light" class="mx-2 mb-4">
							<template #header>
								<h4 class="m-0 p-0 font-weight-bold">コピー元</h4>
							</template>
							<div v-if="bind_data.fbs_info.fbs_original_number">{{ bind_data.fbs_info.fbs_original_number }}</div>
							<div v-else>-</div>
						</b-card>
					</b-col>
				</b-row>
				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="m-0 p-0 font-weight-bold">報告書名称</h4>
					</template>
					{{ bind_data.fbs_info.fbs_report_title }}
				</b-card>
				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="mb-0 font-weight-bold">件名</h4>
					</template>
					<b-form-input v-model="bind_data.fbs_info.bas_subject" class="m-0" maxlength="100"></b-form-input>
				</b-card>
				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="m-0 p-0 font-weight-bold">事案番号</h4>
					</template>
					{{ bind_data.fbs_info.bas_case_number }}
				</b-card>
				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="mb-0 font-weight-bold">発生日時</h4>
					</template>
					<b-row
						><b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<!-- <b-form-datepicker v-model="bind_data.bas_occur_date" locale="ja" label-no-date-selected="日付を選択してください"
						:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" class="m-0" ></b-form-datepicker> -->
							<VueDatePicker
								v-model="bind_data.bas_occur_date"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="日付を選択してください"
								:state="null"
								:enable-time-picker="false"
								:clearable="false"
								class="mb-2"
								week-start="0"
								required
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
							</VueDatePicker> </b-col
						><b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<!-- <b-form-timepicker v-model="bind_data.bas_occur_time" locale="ja" label-no-time-selected="時刻を選択してください" class="m-0"></b-form-timepicker> -->
							<VueDatePicker
								v-model="bind_data.bas_occur_time"
								model-type="HH:mm:ss"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択してください"
								:state="null"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="false"
								class="m-0"
								auto-apply
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker> </b-col
					></b-row>
				</b-card>
				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="mb-0 font-weight-bold">管理対象</h4>
					</template>
					<treeselect
						v-model="local_data.mng_uuid"
						:multiple="true"
						:disable-branch-nodes="true"
						:options="local_data.mng_name_options"
						placeholder="選択してください" />
				</b-card>

				<FormEditUtils
					:form_info="bind_data.form_info"
					:form_data="bind_data.form_data"
					@save="handleFormEdit($event)"></FormEditUtils>

				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="mb-0 font-weight-bold">図面</h4>
					</template>
					<b-button variant="outline-secondary" class="ms-2 mt-2 float-end" @click="toggleViewBlueprint()"
						>図面を表示
						<BootstrapIcon icon="caret-down-fill" :rotate="isViewBlueprint ? 180 : 0"></BootstrapIcon>
					</b-button>
					<div v-if="isViewBlueprint" class="pt-1" style="clear: both">
						<template v-for="draw2 in local_data.drawImage" :key="draw2">
							<b-card-group deck class="mt-3">
								<template v-for="draw in draw2" :key="draw">
									<b-card :header="draw.name" class="text-center">
										<template v-if="draw.name != ''">
											<template v-if="draw.image != ''">
												<b-card-text>
													<b-img :src="draw.image" class="w-100"></b-img>
												</b-card-text>
											</template>
											<template v-else>
												<p>図面データが存在しません</p>
											</template>
											<hr />
											<b-button
												variant="outline-secondary"
												v-on:click="handleConfirmDeleteBlueprint(draw.file_name)"
												class="float-end"
												>図面削除</b-button
											>
										</template>
									</b-card>
								</template>
							</b-card-group>
						</template>
					</div>
					<div v-if="hasDrawingRegistPerm" class="pt-2" style="clear: both">
						<hr />
						<b-form-group label="図面（画像）" label-for="add_blurprint_input" label-cols="2">
							<!-- スマホかブラウザか-->
							<template v-if="state_isSmartPhone">
								<p>ファイルを選択してください。</p>
							</template>
							<template v-if="!state_isSmartPhone">
								<p>ファイルを選択するか、ファイルをドロップしてください。</p>
							</template>
							<b-form-file
								id="add_blurprint_input"
								ref="add_blurprint_input"
								placeholder="ファイルを選択する。"
								v-model="local_data.addBlueprintFile"
								:state="state_data.addBlueprintFile"
								drop-placeholder="ここにファイルをドロップしてください。"
								browse-text="参照"
								accept="image/jpeg, image/png">
							</b-form-file>
						</b-form-group>
						<b-form-group label="図面名" label-for="add_blurprint_name" label-cols="2" class="mt-2">
							<b-form-input
								id="add_blurprint_name"
								ref="add_blurprint_name"
								placeholder="図面名を入力してください。"
								v-model="local_data.addBlueprintName"
								:state="state_data.addBlueprintName"
								maxlength="50"></b-form-input>
						</b-form-group>
						<b-button class="mt-2 ms-2 float-end" variant="success" @click="handleAddBlueprintFile()">追加</b-button>
						<b-button class="mt-2 float-end" @click="handleClearBlueprintFile()">クリア</b-button>
					</div>
					<div v-if="local_data.addBlueprintFiles.length > 0" class="pt-2" style="clear: both">
						<hr />
						<b-row v-for="(fileInfo, index) in local_data.addBlueprintFiles" :key="index">
							<b-col cols="1"></b-col>
							<b-col
								cols="3"
								class="text-start ms-1 me-1 pb-1"
								style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); padding-top: 15px">
								<span>{{ fileInfo.name }}</span>
							</b-col>
							<b-col class="text-start ms-1 me-1 pb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); padding-top: 15px">
								<span>{{ fileInfo.file.name }}</span>
							</b-col>
							<b-col cols="1" class="text-end ms-1 pt-2 pb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
								<b-button @click="handleRemoveBlueprintFile(fileInfo.id)">削除</b-button>
							</b-col>
							<b-col cols="1"></b-col>
						</b-row>
					</div>
				</b-card>

				<template v-if="isViewTimeline">
					<!-- タイムラインデータ編集 -->
					<div class="pt-2 mb-4" v-for="tml_item in bind_data.fbs_info.form_timeline_list" :key="tml_item.ftl_uuid">
						<div class="ms-2 font-weight-bold">タイムライン</div>
						<b-card class="ms-2">
							<b-row>
								<b-col xs="12" sm="12" md="6" lg="6" xl="6">
									<b-card header-bg-variant="light" class="mb-2">
										<template #header>
											<h4 class="mb-0 font-weight-bold">日時</h4>
										</template>
										<b-row
											><b-col xs="12" sm="12" md="6" lg="6" xl="6">
												<!-- <b-form-datepicker v-model="tml_item.tml_rpt_date" label-no-date-selected="日付を選択"
												:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
												:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
												class="m-0" ></b-form-datepicker> -->
												<VueDatePicker
													v-model="tml_item.tml_rpt_date"
													model-type="yyyy-MM-dd"
													locale="ja"
													:format="date_format"
													placeholder="日付を選択"
													:state="null"
													:enable-time-picker="false"
													:clearable="false"
													class="mb-2"
													week-start="0"
													required
													auto-apply>
													<template #input-icon>
														<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
													</template>
												</VueDatePicker> </b-col
											><b-col xs="12" sm="12" md="6" lg="6" xl="6">
												<!-- <b-form-timepicker v-model="tml_item.tml_rpt_time" label-no-time-selected="時刻を選択"
												:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
												class="m-0"></b-form-timepicker> -->
												<VueDatePicker
													v-model="tml_item.tml_rpt_time"
													model-type="HH:mm:ss"
													locale="ja"
													format="HH:mm"
													placeholder="時刻を選択"
													:state="null"
													time-picker
													:config="{ modeHeight: 150 }"
													:clearable="false"
													class="m-0"
													auto-apply>
													<template #input-icon>
														<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
													</template>
												</VueDatePicker> </b-col
										></b-row>
									</b-card> </b-col
								><b-col xs="12" sm="12" md="6" lg="6" xl="6">
									<b-card header-bg-variant="light" class="mb-2">
										<template #header>
											<b-badge pill variant="danger" class="float-start me-2">必須</b-badge>
											<h4 class="mb-0 font-weight-bold">報告者</h4>
										</template>
										<b-form-input
											v-model="tml_item.tml_reporter_name"
											class="m-0"
											:state="state_data.tml_reporter_name"
											maxlength="100"></b-form-input>
									</b-card>
								</b-col>
							</b-row>
							<b-card header-bg-variant="light" class="mb-2">
								<template #header>
									<h4 class="mb-0 font-weight-bold">状況（説明）</h4>
								</template>
								<b-form-textarea v-model="tml_item.tml_status_explain" class="m-0" maxlength="500"></b-form-textarea>
							</b-card>

							<template v-if="isShowAttacheFileList">
								<template v-if="tml_item.tml_media_type === 'image'">
									<div v-for="(media, index) in tml_item.mediaList" :key="media" style="clear: both">
										<div class="float-end">
											<b-button
												variant="outline-danger"
												class="ms-2 mt-2"
												@click="handleConfirmDeleteImageAndVideo(tml_item, index, media.attachedIndex)"
												>画像を削除</b-button
											>
											<b-button
												variant="outline-secondary"
												class="ms-2 mt-2"
												@click="handleToggleRotate(media.fileUuid, tml_item.tml_media_type, media.dataUrl)">
												画像を表示
												<BootstrapIcon icon="caret-down-fill" :rotate="rotate(media.fileUuid)"></BootstrapIcon>
											</b-button>
										</div>
										<div v-if="rotate(media.fileUuid) === 180" style="clear: both">
											<div style="float: right">
												<b-img :src="local_data.imageSrc" fluid max-width="800" class="mt-2"></b-img>
											</div>
										</div>
									</div>
								</template>
								<template v-if="tml_item.tml_media_type === 'video'">
									<div v-for="(media, index) in tml_item.mediaList" :key="media" style="clear: both">
										<div class="float-end">
											<b-button
												variant="outline-danger"
												class="ms-2 mt-2"
												@click="handleConfirmDeleteImageAndVideo(tml_item, index, media.attachedIndex)"
												>動画を削除</b-button
											>
											<b-button
												variant="outline-secondary"
												class="ms-2 mt-2"
												@click="handleToggleRotate(media.fileUuid, tml_item.tml_media_type, media.dataUrl)">
												動画を表示
												<BootstrapIcon icon="caret-down-fill" :rotate="rotate(media.fileUuid)"></BootstrapIcon>
											</b-button>
										</div>
										<div v-if="rotate(media.fileUuid) === 180" style="clear: both">
											<div style="float: right">
												<video-player :options="local_data.video_options" class="mt-2" />
											</div>
										</div>
									</div>
								</template>
							</template>

							<div v-if="isFileAttachField" class="pt-1" style="clear: both">
								<hr v-if="attacheFileNum(tml_item) > 0" />
								<div v-for="(attacheFile, index) in tml_item.timelineAttachFileList" :key="attacheFile" style="clear: both">
									<!-- <hr v-if="index == 0"> -->
									<div class="mb-2" style="float: right">
										<span>{{ attacheFile.fileName }}</span>
										<b-button class="ms-1" @click="handleClearSelectedFile(tml_item, index)">クリア</b-button>
									</div>
								</div>
								<template v-if="isPossibleFileAttache(tml_item)">
									<div class="mt-2" style="clear: both">
										<!-- <hr v-if="attacheFileNum( tml_item ) > 0"> -->
										<b-form-group :label-for="tml_item.ftl_uuid">
											<template #label>
												<span style="font-size: large">{{ mediaAttacheLabel(tml_item).label }}</span>
												<span style="font-size: small">{{ mediaAttacheLabel(tml_item).note }}</span>
											</template>
											<template v-if="state_isSmartPhone">
												<p>ファイルを選択してください。</p>
											</template>
											<template v-if="!state_isSmartPhone">
												<p>ファイルを選択するか、ファイルをドロップしてください。</p>
											</template>
											<b-form-file
												:id="tml_item.ftl_uuid"
												:ref="tml_item.ftl_uuid"
												placeholder="ファイルを選択する。"
												v-model="tml_item.selectedFile"
												@change="handleChangeSelectAttacheFile($event, tml_item)"
												drop-placeholder="ここにファイルをドロップしてください。"
												browse-text="参照"
												:accept="attacheFileAccept(tml_item.tml_media_type)">
											</b-form-file>
										</b-form-group>
									</div>
								</template>
							</div>

							<div style="clear: both">
								<b-button variant="danger" class="mt-2" @click="handleConfirmDeleteTimeline(tml_item)">削除</b-button>
							</div>
						</b-card>
					</div>
				</template>

				<b-card header-bg-variant="light" class="mx-2 mt-2 mb-4">
					<template #header>
						<b-badge pill variant="danger" class="float-start me-2">必須</b-badge>
						<h4 class="mb-0 font-weight-bold">編集理由</h4>
					</template>
					<b-form-textarea
						v-model="bind_data.fbs_info.fbs_chg_reason"
						class="m-0"
						:state="state_data.fbs_chg_reason"
						maxlength="500"></b-form-textarea>
				</b-card>

				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<b-badge pill variant="danger" class="float-start me-2">必須</b-badge>
						<h4 class="mb-0 font-weight-bold">報告者</h4>
					</template>
					<b-form-input
						v-model="bind_data.fbs_info.fbs_reporter"
						class="m-0"
						:state="state_data.fbs_reporter"
						maxlength="100"></b-form-input>
				</b-card>

				<b-card header-bg-variant="light" class="mx-2 mb-4">
					<template #header>
						<h4 class="mb-0 font-weight-bold">共有先</h4>
					</template>
					<template v-if="state_data.treeselectdialogs">
						<treeselectdialogs
							:dpt_selected_array="bind_data.dpt_selected"
							:multiple="true"
							:branch_nodes="false"
							@save="get_dpt_info($event)"></treeselectdialogs>
					</template>
				</b-card>

				<div class="mx-2 mb-4">
					<b-row>
						<b-col
							><div class="text-end">最終更新者：{{ bind_data.fbs_info.fbs_upd_usr_uuid_name }}</div></b-col
						>
						<b-col
							><div>最終更新日時：{{ bind_data.fbs_info.fbs_upd_date_time }}</div></b-col
						>
					</b-row>
				</div>

				<template v-if="bind_data.fbs_info.fbs_uuid && bind_data.fbs_info.fbs_form_comp_type !== 'initial' && hasConsultPerm">
					<b-form-checkbox class="mx-2" v-model="local_data.status" value="accepted" unchecked-value="not_accepted"
						>相談相手を選択する</b-form-checkbox
					>
				</template>
				<!-- 相談先リスト -->
				<b-card v-if="local_data.status === 'accepted'" header-bg-variant="light" class="mx-2 mb-4 p-0">
					<b-form-group class="mb-0">
						<b-form-radio-group class="consult_list mb-3" v-model="bind_data.fbs_info.fbs_consult_usr_uuid">
							<div class="ms-2 mb-2 text-start" v-for="user in local_data.person_options" :key="user.value">
								<b-form-radio :value="user.value">{{ user.text }}</b-form-radio>
								<div class="small ms-4">
									<div>{{ user.org }} {{ user.dept }}</div>
								</div>
							</div>
						</b-form-radio-group>
						<b-form-group class="mb-0">
							<slot name="label">
								<div>
									<b-badge pill variant="danger" class="float-start me-2" style="margin-top: 2px">必須</b-badge>
									<span>相談内容</span>
								</div>
							</slot>
							<b-form-textarea
								class="consult_content"
								v-model="bind_data.consult_content"
								rows="4"
								max-rows="8"
								maxlength="500"></b-form-textarea>
						</b-form-group>
					</b-form-group>
				</b-card>

				<div v-if="local_data.status !== 'accepted'" class="text-end mx-2">
					<template v-if="bind_data.fbs_info.fbs_uuid && bind_data.fbs_info.fbs_form_comp_type !== 'initial'">
						<b-button class="ms-3" variant="outline-secondary" @click="save_rewrite_report">上書き保存</b-button>
					</template>
					<b-button class="ms-3" variant="outline-secondary" @click="save_named_report">名前を付けて保存</b-button>
					<template v-if="bind_data.fbs_info.fbs_uuid && bind_data.fbs_info.fbs_form_comp_type !== 'initial'">
						<b-button class="ms-3" variant="outline-secondary" @click="save_confirmed_report">編集内容を確定する</b-button>
					</template>
				</div>
				<div v-if="local_data.status === 'accepted'" class="text-end mx-2">
					<template v-if="bind_data.fbs_info.fbs_uuid && bind_data.fbs_info.fbs_form_comp_type !== 'initial'">
						<b-button class="ms-3" variant="outline-secondary" @click="save_consult_confirm_report()">相談して保存</b-button>
					</template>
				</div>
			</div>

			<b-modal ref="named_report" title="名前を付けて保存" @ok="handleSaveAs()"
				>名称を入力してください
				<b-form-input v-model="bind_data.fbs_info.fbs_report_title" class="m-0" required maxlength="100"></b-form-input>
			</b-modal>
			<b-modal ref="rewrite_report" title="上書き保存" @ok="handleOverWrite()"
				>上書きされて保存されます。よろしいですか？</b-modal
			>
			<b-modal ref="consult_confirm_report" title="相談して保存" @ok="handleSaveAsConsultConfirm()"
				>相談して保存されます。よろしいですか？</b-modal
			>
			<b-modal ref="confirmed_report" title="確認済みにする" @ok="handleSaveConfirmedReport()"
				>確認済みにします。よろしいですか？</b-modal
			>
			<b-modal ref="deleteTimeline" title="タイムライン削除" @ok="handleDeleteTimeline()"
				>選択したタイムラインを削除します。よろしいですか？</b-modal
			>
			<b-modal ref="deletePicture" title="画像削除" @ok="handleDeleteImageAndVideo()"
				>選択した画像を削除します。よろしいですか？</b-modal
			>
			<b-modal ref="deleteMovie" title="動画削除" @ok="handleDeleteImageAndVideo()"
				>選択した動画を削除します。よろしいですか？</b-modal
			>
			<b-modal ref="deleteBlueprint" title="図面削除" @ok="handleDeleteBlueprint()"
				>選択した図面を削除します。よろしいですか？</b-modal
			>
		</template>

		<canvas ref="canvas" style="display: none"></canvas>
	</div>
</template>

<style scoped lang="scss">
.alert_border {
	border: 2px solid red;
}
</style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import dataset from "@/lib/dataset.js";
import perm from "@/lib/perm.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import image from "@/lib/image.js";
import FormEditUtils from "@/components/FormEditUtils.vue";
import treeselectdialogs from "@/components/TreeSelectDialogs.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import "video.js/dist/video-js.css";
import { mapMutations, mapState } from "vuex";
import {
	BAlert,
	BButton,
	BCard,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
	vBToggle,
	BModal,
	BForm,
	BFormRadio,
	BFormRadioGroup,
	BFormCheckbox,
	BFormTextarea,
	BCol,
	BRow,
	BCollapse,
	BImg,
	BCardGroup,
	BFormFile,
	BFormInput,
	BFormGroup,
} from "bootstrap-vue-next";
import { Notivue, Notification, lightTheme, push } from "notivue";
import { uuid } from "vue3-uuid";

export default {
	name: "CaseDetailsEdit",
	components: {
		Loading,
		treeselectdialogs,
		FormEditUtils,
		VideoPlayer,
		Notivue,
		Notification,
		push,
		BAlert,
		BButton,
		BCard,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
		vBToggle,
		BModal,
		BForm,
		BFormRadio,
		BFormRadioGroup,
		BFormCheckbox,
		BFormTextarea,
		BCol,
		BRow,
		BCollapse,
		BImg,
		BCardGroup,
		BFormFile,
		BFormInput,
		BFormGroup,
	},

	directives: {
		BToggle: vBToggle,
	},

	// 共通関数
	mixins: [common, dataset, CommonDataSet, image, perm],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				fbs_info: null,
				form_info: null,
				form_data: null,
				dpt_selected: [],
				dpt_options: this.data_get_dpt_info(),
				bas_occur_date: null,
				bas_occur_time: null,
				consult_content: "",
			},
			// ローカルデータ
			local_data: {
				status: "not_accepted",
				// 管理対象
				mng_uuid: [],
				mng_name_options: [],
				mng_uuid_set: "",
				// 相談先リスト
				person_selected: "",
				person_options: [],
				// 名前を付けて保存
				named_report_name: "",
				deleteTml: -1,
				toggleRotate: null,
				rotate: 0,
				imageSrc: null,
				video_options: {
					width: 640, // 幅
					height: 360, // 高さ
					autoplay: true,
					controls: true,
					sources: [
						{
							src: "",
							type: "video/mp4",
						},
					],
				},
				drawImage: [], // 画面に表示する図面の配列（2列表示のため、2次元配列）
				bas_draw_info: "", // 図面情報のJSON文字列
				drawInfoList: [], // 図面情報JSONの配列
				deleteDraw: -1, // 削除する図面の配列インデックス
				deleteDrawInfoList: [], // 削除する図面情報の配列
				addBlueprintName: "", // 追加する図面の図面名
				addBlueprintFile: null, // 追加する図面（画像）ファイル
				addBlueprintFiles: [], // 追加する図面（画像）ファイルの配列
				deleteAttachTml: null, // 添付を削除するタイムライン
				deleteAttachFtlOrgIdxList: [], // 添付を削除したタイムラインのftl_uuid
				consultList: [],
			},
			// ステート
			state_data: {
				treeselectdialogs: true,
				fbs_chg_reason: null,
				fbs_reporter: null,
				tml_reporter_name: null,
				addBlueprintName: null,
				addBlueprintFile: null,
				consultListVisible: false,
			},
			isViewBlueprint: false,
			isViewTimeline: false,
			isShowAttacheFileList: true,
			isFileAttachField: true,
			// notivueスタイル
			theme: {
				...lightTheme,
				"--nv-width": "100%", // Automatically set to 100% on mobile devices
				"--nv-min-width": "4000px", // Minimum width
				// '--nv-spacing': '0.625rem', // Padding and column gap
				// '--nv-y-align': 'center', // Icon and close button vertical alignment: flex-start, flex-end
				// '--nv-y-align-has-title': 'center', // Icon and close button vertical alignment when title is present
				// '--nv-radius': '0.75rem', // Container border radius
				// '--nv-border-width': '1px', // Container border width
				// '--nv-icon-size': '1.25rem', // Icon and close button size
				// '--nv-title-size': '0.925rem', // Title font size
				// '--nv-message-size': '0.925rem', // Message font size
				// '--nv-shadow': '0 0.5rem 0.5rem rgba(0, 0, 0, 0.1)', // Container box shadow
				// '--nv-tip-width': '0px', // Tip width, will inherit accent color
				// '--nv-progress-height': '4px', // Progress bar height

				// // Colors
				// '--nv-global-bg': '#fff', // Container background
				// '--nv-global-fg': '#000', // Title, message, close icon color
				// '--nv-global-accent': '#28b780', // Icon and tip color
				// '--nv-global-border': '#000' // Container border color
			},
		};
	},

	computed: {
		...mapState(["system_info"]),

		isShowConsult: function () {
			return this.local_data.consultList && this.local_data.consultList.length > 0;
		},

		//スマホかブラウザか
		state_isSmartPhone() {
			if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
				return true;
			} else {
				return false;
			}
		},

		consultListCallaoseBtnTitle: function () {
			if (this.state_data.consultListVisible) {
				return "履歴を閉じる▲";
			} else {
				return "履歴を開く▼";
			}
		},

		attacheLimitNum: function () {
			return function (tmlItem) {
				let limitNum = 0;

				if (tmlItem.tml_media_type == "image") {
					limitNum = this.system_info.timeline_picture_count;
				} else if (tmlItem.tml_media_type == "video") {
					limitNum = this.system_info.timeline_movie_count;
				} else {
					if (this.system_info.timeline_picture_count > this.system_info.timeline_movie_count) {
						limitNum = this.system_info.timeline_picture_count;
					} else {
						limitNum = this.system_info.timeline_movie_count;
					}
				}

				return limitNum;
			};
		},

		attacheFileNum: function () {
			return function (tmlItem) {
				return tmlItem.mediaList.length + tmlItem.timelineAttachFileList.length;
			};
		},

		isPossibleFileAttache: function () {
			return function (tmlItem) {
				return this.attacheFileNum(tmlItem) < this.attacheLimitNum(tmlItem);
			};
		},

		mediaAttacheLabel: function () {
			return function (tmlItem) {
				let remainNum = Number(this.attacheLimitNum(tmlItem)) - Number(this.attacheFileNum(tmlItem));
				let retJson = {
					label: "",
					note: "",
				};

				if (tmlItem.tml_media_type == "image") {
					retJson.label = "画像";
					retJson.note = "（あと" + remainNum + "つ添付することができます）";
				} else if (tmlItem.tml_media_type == "video") {
					retJson.label = "動画";
					retJson.note = "（あと" + remainNum + "つ添付することができます）";
				} else {
					if (this.system_info.timeline_picture_count > 0 && this.system_info.timeline_movie_count > 0) {
						retJson.label = "画像または動画";
						retJson.note =
							"（画像：あと" +
							this.system_info.timeline_picture_count +
							"つ、動画：あと" +
							this.system_info.timeline_movie_count +
							"つ添付することができます）";
					} else if (this.system_info.timeline_picture_count > 0) {
						retJson.label = "画像";
						retJson.note = "（あと" + remainNum + "つ添付することができます）";
					} else if (this.system_info.timeline_movie_count > 0) {
						retJson.label = "動画";
						retJson.note = "（あと" + remainNum + "つ添付することができます）";
					}
				}

				return retJson;
			};
		},

		attacheFileAccept: function () {
			return function (type) {
				if (type == "image") {
					return "image/jpeg, image/png";
				} else if (type == "video") {
					return "video/mp4, video/quicktime";
				} else {
					return "image/jpeg, image/png, video/mp4, video/quicktime";
				}
			};
		},

		rotate() {
			return (fileUuid) => {
				if (this.local_data.toggleRotate === fileUuid) {
					return this.local_data.rotate;
				} else {
					return 0;
				}
			};
		},
	},

	// インスタンス初期化後
	created() {
		// this.$eventHub.$emit( "navbar_title", "報告データ編集" );
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		this.isViewTimeline = false;

		await this.$nextTick();

		this.bind_data.fbs_info = this.$store.state.selected_fbs_info;
		this.bind_data.form_info = this.$store.state.form_info;
		this.bind_data.form_data = JSON.parse(this.bind_data.fbs_info.bas_element);

		const occurDatetime = this.bind_data.fbs_info.bas_occur_date_time.split(" ");
		this.bind_data.bas_occur_date = occurDatetime[0];
		this.bind_data.bas_occur_time = occurDatetime[1];

		for (let i = 0; i < this.bind_data.fbs_info.form_timeline_list.length; i++) {
			const tml = this.bind_data.fbs_info.form_timeline_list[i];
			const tmlRptDatetime = tml.tml_rpt_date_time.split(" ");

			tml.tml_rpt_date = tmlRptDatetime[0];
			tml.tml_rpt_time = tmlRptDatetime[1];
			tml.selectedFile = null;
			tml.orgIdx = i;

			let tmlSaveIdJson = JSON.parse(tml.tml_save_id);
			console.log(tmlSaveIdJson);

			let mediaList = Array();

			// 複数添付未対応への対応
			if (tml.tml_web_view_url && !tml.tml_web_view_url.startsWith("{")) {
				// 複数添付未対応のバージョンで登録されたデータの場合

				console.log(tml.tml_web_view_url);

				let dataUrl = await this.getS3Media(tml.tml_web_view_url);
				let media = {
					fileUuid: this.getFileUuid(tml.tml_web_view_url),
					blob: null,
					dataUrl: dataUrl,
					width: "",
					height: "",
					isEditMode: false,
					isAttached: true,
					attachedIndex: 0,
				};

				mediaList.push(media);
			} else {
				let webViewUrlInfo = JSON.parse(tml.tml_web_view_url);

				if (webViewUrlInfo && webViewUrlInfo.url_list) {
					for (let idx in webViewUrlInfo.url_list) {
						let webViewUrl = webViewUrlInfo.url_list[idx];
						let dataUrl = await this.getS3Media(webViewUrl);
						let media = {
							fileUuid: this.getFileUuid(webViewUrl),
							blob: null,
							dataUrl: dataUrl,
							width: "",
							height: "",
							isEditMode: false,
							isAttached: true,
							attachedIndex: idx,
						};

						mediaList.push(media);
					}
				}
			}

			tml.mediaList = mediaList;
			tml.timelineAttachFileList = [];
			this.bind_data.fbs_info.form_timeline_list[i] = tml;
		}

		this.local_data.mng_uuid = [];
		for (let i = 0; i < this.bind_data.fbs_info.bas_mng_list.length; i++) {
			this.local_data.mng_uuid.push(this.bind_data.fbs_info.bas_mng_list[i].bas_mng_uuid);
		}

		this.bind_data.dpt_selected = [];
		for (let i = 0; i < this.bind_data.fbs_info.fbs_share_dest_list.length; i++) {
			this.bind_data.dpt_selected.push(this.bind_data.fbs_info.fbs_share_dest_list[i].fbs_share_dest_uuid);
		}

		// if (this.bind_data.fbs_info.fbs_consult_usr_uuid) {
		//   this.local_data.status = 'accepted';
		// }
		await this.get_dpt();

		await this.getManaged();

		await this.getConsultations();

		if (this.bind_data.fbs_info.bas_draw_info) {
			this.local_data.bas_draw_info = this.bind_data.fbs_info.bas_draw_info;
			this.local_data.drawInfoList = [];

			const drawInfo = JSON.parse(this.local_data.bas_draw_info);

			if (Array.isArray(drawInfo)) {
				this.local_data.drawInfoList.push(...drawInfo);
			} else {
				this.local_data.drawInfoList.push(drawInfo);
			}

			await this.getDrawInfo(true);
		}

		let ret = await this.getConsultList({
			fbsUuid: this.bind_data.fbs_info.fbs_uuid,
		});

		if (ret.result === "OK") {
			console.log(ret);

			this.local_data.consultList = ret.consult_list;
		} else {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = ret.message;

			// if ( ret.result === 'ERR' ) {
			// 	this.logout( {
			// 		_this: this,
			// 	} );
			// }
		}

		if (this.bind_data.fbs_info.fbs_comment != null && this.bind_data.fbs_info.fbs_comment != "") {
			console.log(this.bind_data.fbs_info.fbs_comment);
			this.local_data.consultList.unshift({
				consult_user_name: "",
				contact_user_name: "",
				cnm_status: "answer",
				cnm_consult_content: "",
				cnm_consult_date_time: "",
				cnm_answer_content: this.bind_data.fbs_info.fbs_comment,
				cnm_answer_date_time: this.bind_data.fbs_info.fbs_comment_date_time,
			});
		}

		this.isViewTimeline = true;
		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		...mapMutations(["set_sid"]),

		toggleViewBlueprint: async function () {
			this.isViewBlueprint = !this.isViewBlueprint;

			await this.$nextTick();
		},

		async getDrawInfo(init = false) {
			let drawImage = [];
			this.local_data.drawImage = [];

			for (let i = 0; i < this.local_data.drawInfoList.length; i++) {
				if (init) {
					this.local_data.drawInfoList[i].orgIdx = i;
				}

				const param = {
					params: {
						sid: this.$store.state.sid,
						bucket_name: this.local_data.drawInfoList[i].bucket_name,
						bucket_key: this.local_data.drawInfoList[i].bucket_key,
						file_name: this.local_data.drawInfoList[i].file_name,
					},
				};

				try {
					const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_S3_MEDIA_FILE, param);

					if (res.data.result === "OK") {
						this.set_sid(res.data.sid);

						let image = "";

						if (this.local_data.drawInfoList[i].content_type != "" && res.data.media_file != "") {
							image = `data:${this.local_data.drawInfoList[i].content_type};base64,${res.data.media_file}`;
						}

						drawImage.push({
							name: this.local_data.drawInfoList[i].drw_name,
							file_name: this.local_data.drawInfoList[i].file_name,
							image: image,
						});

						if (drawImage.length == 2) {
							this.local_data.drawImage.push(drawImage);
							drawImage = [];
						}
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch (e) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = JSON.stringify(e);
				}
			}

			if (drawImage.length > 0) {
				if (drawImage.length == 1) {
					drawImage.push({
						name: "",
						file_name: "",
						image: "",
					});
				}

				this.local_data.drawImage.push(drawImage);
			}
		},

		isConsult() {
			console.log(this.bind_data.fbs_info.fbs_form_comp_type);
			console.log(this.bind_data.fbs_info.fbs_open_comment);
			console.log(this.bind_data.fbs_info.fbs_request_usr_uuid);

			console.log(JSON.stringify(this.bind_data.fbs_info));
			if (this.bind_data.fbs_info.fbs_form_comp_type == "fixpending") {
				if (this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid) {
					return true;
				}
			}
			return false;
		},

		back: function () {
			this.transition("/Home/CaseDetailsSelect");
		},

		save_named_report: function () {
			if (!this.valid()) {
				return;
			}

			this.$refs["named_report"].show();
		},

		save_rewrite_report: function () {
			if (!this.valid()) {
				return;
			}

			this.$refs["rewrite_report"].show();
		},

		save_consult_confirm_report() {
			if (!this.valid(true)) {
				return;
			}

			this.$refs["consult_confirm_report"].show();
		},

		save_confirmed_report() {
			if (!this.valid()) {
				return;
			}

			this.$refs["confirmed_report"].show();
		},

		media_file_state: function (tml_item) {
			tml_item.tml_media_file_state = !tml_item.tml_media_file_state;
		},

		get_dpt_info(data) {
			this.bind_data.dpt_selected = data;
		},

		/**
		 * 共有先の組織を取得する
		 */
		async get_dpt() {
			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;

			for (let i = 0; i < this.local_data.mng_uuid.length; i++) {
				if (i == 0) {
					this.local_data.mng_uuid_set = this.local_data.mng_uuid[i];
				} else {
					this.local_data.mng_uuid_set = this.local_data.mng_uuid_set + "," + this.local_data.mng_uuid[i];
				}
			}

			await this.set_org_map("allmng", null, this.local_data.mng_uuid_set);

			this.state_data.treeselectdialogs = true;

			// ローディング終了
			this.loader.is_loading = false;
		},

		/**
		 * 管理対象を取得する
		 */
		async getManaged() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_MANAGED_LIST, config);
				if (res.data.result === "OK") {
					const managed = res.data.managed_list;

					this.local_data.mng_name_options = [];

					for (let i = 0; i < managed.length; i++) {
						this.local_data.mng_name_options.push({
							label: managed[i].mng_name,
							value: managed[i].mng_uuid,
							id: managed[i].mng_uuid,
						});
					}

					this.set_sid(res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * 相談先リストを取得する
		 */
		async getConsultations() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_CONSULTATION_LIST, config);

				if (res.data.result === "OK") {
					const consultations = res.data.consultation_list;
					this.local_data.person_options = [];

					for (let i = 0; i < consultations.length; i++) {
						if (consultations[i].usr_uuid != this.$store.state.users_info.usr_uuid) {
							this.local_data.person_options.push({
								text: consultations[i].usr_name,
								value: consultations[i].usr_uuid,
								dept: consultations[i].usr_post,
								org: consultations[i].usr_org_long_name,
							});
						}
					}

					this.set_sid(res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (ex) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		/**
		 * 入力データをチェックする
		 */
		valid(isConsult = false) {
			let ret = true;
			this.state_data.fbs_chg_reason = null;
			this.state_data.fbs_reporter = null;
			this.state_data.tml_reporter_name = null;

			if (!this.bind_data.fbs_info.fbs_chg_reason) {
				this.state_data.fbs_chg_reason = false;
				ret = false;
			}
			if (!this.bind_data.fbs_info.fbs_reporter) {
				this.state_data.fbs_reporter = false;
				ret = false;
			}

			this.bind_data.fbs_info.bas_occur_date_time = `${this.bind_data.bas_occur_date} ${this.bind_data.bas_occur_time}`;
			this.bind_data.fbs_info.bas_mng_uuid = this.local_data.mng_uuid.join(",");
			this.bind_data.fbs_info.fbs_share_dest = this.bind_data.dpt_selected.join(",");
			for (let i = 0; i < this.bind_data.fbs_info.form_timeline_list.length; i++) {
				const tml = this.bind_data.fbs_info.form_timeline_list[i];
				tml.tml_rpt_date_time = `${tml.tml_rpt_date} ${tml.tml_rpt_time}`;
				if (!tml.tml_reporter_name) {
					this.state_data.tml_reporter_name = false;
					ret = false;
				}
			}

			if (isConsult) {
				document.querySelector("div.consult_list").classList.remove("alert_border");
				document.querySelector("textarea.consult_content").classList.remove("alert_border");

				if (this.bind_data.fbs_info.fbs_consult_usr_uuid == "") {
					document.querySelector("div.consult_list").classList.add("alert_border");
					ret = false;
				}

				if (this.bind_data.consult_content == "") {
					document.querySelector("textarea.consult_content").classList.add("alert_border");
					ret = false;
				}
			}

			return ret;
		},

		/**
		 * FormEditデータの変更
		 */
		handleFormEdit(data) {
			this.bind_data.fbs_info.bas_element = JSON.stringify(data);
		},

		/**
		 * 名前を付けて保存
		 */
		async handleSaveAs() {
			await this.save("naming");
		},

		/**
		 * 上書き保存
		 */
		async handleOverWrite() {
			await this.save("overwrite");
		},

		/**
		 * 相談して保存
		 */
		async handleSaveAsConsultConfirm() {
			await this.save("consulconfirm");
		},

		async handleSaveConfirmedReport() {
			await this.save("editconfirm");
		},

		/**
		 * 報告データ保存
		 */
		async save(saveMode) {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				this.setMediaType();

				const tmls = [];
				for (let idx = 0; idx < this.bind_data.fbs_info.form_timeline_list.length; idx++) {
					const tml = this.bind_data.fbs_info.form_timeline_list[idx];

					tmls.push({
						ftl_uuid: tml.ftl_uuid,
						tml_uuid: tml.tml_uuid,
						tml_bas_uuid: this.bind_data.fbs_info.bas_uuid,
						tml_rpt_date_time: tml.tml_rpt_date_time,
						tml_reporter: tml.tml_reporter,
						tml_reporter_name: tml.tml_reporter_name,
						tml_status_explain: tml.tml_status_explain,
						tml_media_type: tml.tml_media_type,
						timelineAttachFileList: tml.timelineAttachFileList,
						deleteAttacheFileIndex: tml.deleteAttacheFileIndex,
					});
				}

				const data = {
					sid: this.$store.state.sid,
					save_mode: saveMode,
					fbs_uuid: this.bind_data.fbs_info.fbs_uuid,
					fbs_report_title: this.bind_data.fbs_info.fbs_report_title,
					fbs_chg_reason: this.bind_data.fbs_info.fbs_chg_reason,
					fbs_reporter: this.bind_data.fbs_info.fbs_reporter,
					bas_uuid: this.bind_data.fbs_info.bas_uuid,
					bas_rpt_type: this.bind_data.fbs_info.bas_rpt_type,
					bas_mng_uuid: this.bind_data.fbs_info.bas_mng_uuid,
					bas_org_uuid: this.bind_data.fbs_info.bas_org_uuid,
					bas_subject: this.bind_data.fbs_info.bas_subject,
					bas_occur_date_time: this.bind_data.fbs_info.bas_occur_date_time,
					bas_element: this.bind_data.fbs_info.bas_element,
					bas_dlv_list: this.bind_data.fbs_info.bas_dlv_list,
					bas_case_number: this.bind_data.fbs_info.bas_case_number,
					form_timeline_list: tmls,
				};

				if (saveMode == "consulconfirm") {
					data.fbs_consult_content = this.bind_data.consult_content;
					data.fbs_consult_date_time = this.$dayjs().tz("Asia/Tokyo").format("YYYYMMDDHHmmss");
				}

				if (this.bind_data.fbs_info.fbs_consult_usr_uuid) {
					data.fbs_consult_usr_uuid = this.bind_data.fbs_info.fbs_consult_usr_uuid;
				}

				if (this.bind_data.fbs_info.fbs_share_dest) {
					data.fbs_share_dest = this.bind_data.fbs_info.fbs_share_dest;
				}

				console.log("data", data);

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_FORM_BASICS_INFO, data);

				console.log("res", res);

				if (res.data.result === "OK") {
					this.set_sid(res.data.sid);
					await this.updateBlueprint(res.data.s3_fbs_info);
					await this.updateImageAndVideo(res.data.s3_ftl_list, tmls);
					this.back();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		async _save(saveMode) {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				this.setMediaType();

				const tmls = [];
				for (let i = 0; i < this.bind_data.fbs_info.form_timeline_list.length; i++) {
					const tml = this.bind_data.fbs_info.form_timeline_list[i];

					tmls.push({
						ftl_uuid: tml.ftl_uuid,
						tml_uuid: tml.tml_uuid,
						tml_bas_uuid: this.bind_data.fbs_info.bas_uuid,
						tml_rpt_date_time: tml.tml_rpt_date_time,
						tml_reporter: tml.tml_reporter,
						tml_reporter_name: tml.tml_reporter_name,
						tml_status_explain: tml.tml_status_explain,
						tml_media_type: tml.tml_media_type,
					});
				}

				const data = {
					sid: this.$store.state.sid,
					save_mode: saveMode,
					fbs_uuid: this.bind_data.fbs_info.fbs_uuid,
					fbs_report_title: this.bind_data.fbs_info.fbs_report_title,
					fbs_chg_reason: this.bind_data.fbs_info.fbs_chg_reason,
					fbs_reporter: this.bind_data.fbs_info.fbs_reporter,
					bas_uuid: this.bind_data.fbs_info.bas_uuid,
					bas_rpt_type: this.bind_data.fbs_info.bas_rpt_type,
					bas_mng_uuid: this.bind_data.fbs_info.bas_mng_uuid,
					bas_org_uuid: this.bind_data.fbs_info.bas_org_uuid,
					bas_subject: this.bind_data.fbs_info.bas_subject,
					bas_occur_date_time: this.bind_data.fbs_info.bas_occur_date_time,
					bas_element: this.bind_data.fbs_info.bas_element,
					bas_dlv_list: this.bind_data.fbs_info.bas_dlv_list,
					bas_case_number: this.bind_data.fbs_info.bas_case_number,
					form_timeline_list: tmls,
				};
				if (this.bind_data.fbs_info.fbs_consult_usr_uuid) {
					data.fbs_consult_usr_uuid = this.bind_data.fbs_info.fbs_consult_usr_uuid;
				}
				if (this.bind_data.fbs_info.fbs_share_dest) {
					data.fbs_share_dest = this.bind_data.fbs_info.fbs_share_dest;
				}

				console.log("data", data);

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_FORM_BASICS_INFO, data);

				console.log("res", res);

				if (res.data.result === "OK") {
					this.set_sid(res.data.sid);
					await this.updateBlueprint(res.data.s3_fbs_info);
					await this.updateImageAndVideo(res.data.s3_ftl_list, res.data.org_s3_ftl_list);
					this.back();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		/**
		 * タイムラインのメディアタイプを設定する
		 * ファイルが選択されているところだけのメディアタイプを設定する
		 */
		setMediaType() {
			for (let i = 0; i < this.bind_data.fbs_info.form_timeline_list.length; i++) {
				const tml = this.bind_data.fbs_info.form_timeline_list[i];
				if (tml.selectedFile instanceof File) {
					if (tml.selectedFile.type === "image/jpeg" || tml.selectedFile.type === "image/png") {
						tml.tml_media_type = "image";
					} else {
						tml.tml_media_type = "video";
					}
				}
			}
		},

		/**
		 * タイムラインの画像、動画をS3にアップロードする
		 */
		updateImageAndVideo: async function (ftlList, tmlList) {
			console.log("ftlList", ftlList);
			console.log("tmlList", tmlList);

			const formData = new FormData();

			formData.append("ftl_list", JSON.stringify(ftlList));

			let newTmlList = Array();

			for (let idx = 0; idx < tmlList.length; idx++) {
				let tml = tmlList[idx];
				let newTml = {
					ftl_uuid: tml.ftl_uuid,
					deleteAttacheFileIndex: tml.deleteAttacheFileIndex,
				};

				let newAttacheList = Array();

				for (let idx2 = 0; idx2 < tml.timelineAttachFileList.length; idx2++) {
					let attacheFile = tml.timelineAttachFileList[idx2];

					newAttacheList.push({
						fileType: attacheFile.type,
						fileUuid: attacheFile.fileUuid,
						fileName: attacheFile.fileName,
					});

					let ext = "";

					if (attacheFile.type !== "video/quicktime") {
						ext = attacheFile.type.substring(attacheFile.type.indexOf("/") + 1);
					} else {
						ext = "mov";
					}

					let fileName = attacheFile.fileUuid + "." + ext;

					formData.append("media_file", attacheFile.blob, fileName);
				}

				newTml.timelineAttachFileList = newAttacheList;

				newTmlList.push(newTml);
			}

			console.log("newTmlList", JSON.stringify(newTmlList));

			formData.append("tml_list", JSON.stringify(newTmlList));
			formData.append("sid", this.$store.state.sid);

			try {
				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
					timeout: 0,
				};

				const res = await axios.post(import.meta.env.VITE_RESTAPI_SET_S3_FORM_MEDIA_FILE2, formData, config);

				console.log(res);

				if (res.data.result != "OK") {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (ex) {
				console.error(ex);

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		_updateImageAndVideo: async function (ftlList, ftlListOrg) {
			console.log("ftlList", ftlList);

			let addedFtluuid = [];

			// 追加
			for (let idx = 0; idx < this.bind_data.fbs_info.form_timeline_list.length; idx++) {
				const tml = this.bind_data.fbs_info.form_timeline_list[idx];

				if (tml.selectedFile instanceof File) {
					if (tml.tml_media_type === "image") {
						const dataUrl = await this.readImageAsBase64(tml.selectedFile);
						tml.selectedFile = await this.resizeImage(dataUrl);
					}

					const ftl = ftlList[idx];

					console.log("ftl", ftl);

					let s3Info = JSON.parse(ftl.tml_save_id);

					const bucketName = s3Info.upload_bucket_name;
					const bucketKey = s3Info.bucket_key;

					console.log(bucketName, bucketKey);

					try {
						const formData = new FormData();
						formData.append("sid", this.$store.state.sid);
						formData.append("ftl_uuid", ftl.ftl_uuid);
						formData.append("bucket_name", bucketName);
						formData.append("bucket_key", bucketKey);
						let ext;

						if (tml.selectedFile.type !== "video/quicktime") {
							ext = tml.selectedFile.type.substring(tml.selectedFile.type.indexOf("/") + 1);
						} else {
							ext = "mov";
						}
						formData.append("media_file", tml.selectedFile, `${uuid.v4()}.${ext}`);

						const config = {
							headers: {
								"content-type": "multipart/form-data",
							},
							timeout: 0,
						};

						const res = await axios.post(import.meta.env.VITE_RESTAPI_SET_S3_FORM_MEDIA_FILE, formData, config);

						if (res.data.result == "OK") {
							addedFtluuid.push(ftl.ftl_uuid);
						} else {
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = res.data.message;
						}
					} catch (e) {
						console.error(e);
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = JSON.stringify(e);
					}
				}
			}

			// 削除
			for (let idx = 0; idx < this.local_data.deleteAttachFtlOrgIdxList.length; idx++) {
				const ftl = ftlListOrg[this.local_data.deleteAttachFtlOrgIdxList[idx]];

				console.log("ftl", ftl);

				let s3Info = JSON.parse(ftl.tml_save_id);

				const bucketName = s3Info.upload_bucket_name;
				const bucketKey = s3Info.bucket_key;

				console.log(bucketName, bucketKey);

				let postData = {
					sid: this.$store.state.sid,
					mode: addedFtluuid.includes(ftl.ftl_uuid) ? "update" : "delete",
					ftl_uuid: ftl.ftl_uuid,
					upload_bucket_name: s3Info.upload_bucket_name,
					download_bucket_name: s3Info.download_bucket_name,
					bucket_key: s3Info.download_bucket_name,
					file_name: s3Info.download_bucket_name,
				};

				try {
					const res = await axios.post(import.meta.env.VITE_RESTAPI_DELETE_S3_FORM_MEDIA_FILE, postData);

					if (res.data.result !== "OK") {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch (ex) {
					console.error(ex);
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = JSON.stringify(ex);
				}
			}
		},

		/**
		 * タイムライン削除を確認する
		 */
		handleConfirmDeleteTimeline(tml) {
			this.local_data.deleteTml = this.bind_data.fbs_info.form_timeline_list.findIndex((t) => t.ftl_uuid === tml.ftl_uuid);
			if (this.local_data.deleteTml !== -1) {
				this.$refs.deleteTimeline.show();
			}
		},

		/**
		 * タイムラインを削除する
		 */
		handleDeleteTimeline() {
			if (this.local_data.deleteTml !== -1) {
				let delTml = this.bind_data.fbs_info.form_timeline_list.splice(this.local_data.deleteTml, 1);

				this.local_data.deleteAttachFtlOrgIdxList.push(delTml[0].orgIdx);
				this.local_data.deleteTml = -1;
			}
		},

		getFileUuid: function (webViewUrl) {
			const urls = webViewUrl.split("?");
			const params = urls[1].split("&");
			const fileName = params.find((p) => p.substr(0, 10) === "file_name=");

			return fileName.replace(/^.*=/, "").replace(/\..*$/, "");
		},

		getS3Media: async function (webViewUrl) {
			try {
				console.log("import.meta.env.VITE_GET_LIMIT_URL = " + import.meta.env.VITE_GET_LIMIT_URL);
				console.log("import.meta.env.VITE_API_KEY = " + import.meta.env.VITE_API_KEY);

				const urls = webViewUrl.split("?");
				const params = urls[1].split("&");
				const basUuid = params.find((p) => p.substr(0, 9) === "bas_uuid=");
				const tmlUuid = params.find((p) => p.substr(0, 9) === "tml_uuid=");
				const fileName = params.find((p) => p.substr(0, 10) === "file_name=");
				const url = `${import.meta.env.VITE_GET_LIMIT_URL}?sid=${
					this.$store.state.sid
				}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
				const config = {
					headers: {
						"x-api-key": import.meta.env.VITE_API_KEY,
					},
				};

				const res = await this.$http.get(url, config);

				if (res.data.result === "OK") {
					return res.data.url;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;

					return "";
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "エラーが発生しました。" + JSON.stringify(e);

				return "";
			}
		},

		async handleToggleRotate(ftlUuid, tmlMediaType, dataUrl) {
			this.loader.is_loading = true;

			if (this.local_data.toggleRotate === ftlUuid) {
				this.local_data.rotate = this.local_data.rotate === 0 ? 180 : 0;
			} else {
				if (tmlMediaType === "image") {
					this.local_data.imageSrc = dataUrl;
				} else if (tmlMediaType === "video") {
					this.local_data.video_options.sources[0].src = dataUrl;
				}

				this.local_data.toggleRotate = ftlUuid;
				this.local_data.rotate = 180;
			}

			this.loader.is_loading = false;
		},

		/**
		 * ファイルの追加
		 *
		 * @param {*} event
		 * @param {*} tmlItem
		 * @returns
		 */
		handleChangeSelectAttacheFile: async function (event, tmlItem) {
			console.log(event, tmlItem);

			let timelineAttachFileList = [];

			if (tmlItem.timelineAttachFileList) {
				timelineAttachFileList = tmlItem.timelineAttachFileList;
			}

			if (event.target.files.length > 0) {
				let fileUuid = uuid.v4();
				let file = event.target.files[0];
				let fileName = file.name;

				let dataUrl = null;
				let blob = null;

				dataUrl = await this.readImageAsBase64(file);

				if (file.type.startsWith("image")) {
					if (file.size > import.meta.env.VITE_PICTURE_FILE_SIZE_LIMIT * 1048576) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "選択可能なファイルの容量は" + import.meta.env.VITE_PICTURE_FILE_SIZE_LIMIT + "MB以内です。";

						this.$refs[tmlItem.ftl_uuid][0].reset();

						return;
					}

					tmlItem.tml_media_type = "image";
					blob = await this.resizeImage(dataUrl);
				} else {
					if (file.size > import.meta.env.VITE_MOVIE_FILE_SIZE_LIMIT * 1048576) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "選択可能なファイルの容量は" + import.meta.env.VITE_MOVIE_FILE_SIZE_LIMIT + "MB以内です。";

						this.$refs[tmlItem.ftl_uuid][0].reset();

						return;
					}

					tmlItem.tml_media_type = "video";
					blob = await fetch(dataUrl).then((r) => r.blob());
				}

				console.log(fileName, fileUuid, blob);

				timelineAttachFileList.push({
					fileUuid: fileUuid,
					file: file,
					type: file.type,
					fileName: fileName,
					dataUrl: dataUrl,
					blob: blob,
				});
			}

			this.$refs[tmlItem.ftl_uuid][0].reset();

			this.isFileAttachField = false;
			// this.$set(tmlItem, "timelineAttachFileList", timelineAttachFileList);
			tmlItem.timelineAttachFileList = timelineAttachFileList;
			this.isFileAttachField = true;
		},

		async _handleToggleRotate(tml) {
			if (this.local_data.toggleRotate === tml.ftl_uuid) {
				this.local_data.rotate = this.local_data.rotate === 0 ? 180 : 0;
			} else {
				this.loader.is_loading = true;

				try {
					const urls = tml.tml_web_view_url.split("?");
					const params = urls[1].split("&");
					const basUuid = params.find((p) => p.substr(0, 9) === "bas_uuid=");
					const tmlUuid = params.find((p) => p.substr(0, 9) === "tml_uuid=");
					const fileName = params.find((p) => p.substr(0, 10) === "file_name=");

					const url = `${import.meta.env.VITE_GET_LIMIT_URL}?sid=${
						this.$store.state.sid
					}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
					const config = {
						headers: {
							"x-api-key": import.meta.env.VITE_API_KEY,
						},
					};

					const res = await axios.get(url, config);
					if (res.data.result === "OK") {
						if (tml.tml_media_type === "image") {
							this.local_data.imageSrc = res.data.url;
						} else if (tml.tml_media_type === "video") {
							this.local_data.video_options.sources[0].src = res.data.url;
						}
						this.local_data.toggleRotate = tml.ftl_uuid;
						this.local_data.rotate = 180;
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch (e) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = "エラーが発生しました。" + JSON.stringify(e);
				}
				this.loader.is_loading = false;
			}
		},

		/**
		 * 選択されている画像、動画をリセットする
		 * @param {*} tml
		 */
		handleClearSelectedFile(tml, index) {
			console.log(tml.timelineAttachFileList[index]);

			this.isFileAttachField = false;
			tml.timelineAttachFileList.splice(index, 1);

			if (this.attacheFileNum(tml) == 0) {
				tml.tml_media_type = "none";
			}

			this.isFileAttachField = true;
		},

		_handleClearSelectedFile(tml) {
			this.$refs[tml.ftl_uuid][0].reset();
		},

		/**
		 * 画像または動画を削除する（確認）
		 */
		handleConfirmDeleteImageAndVideo(tml, index, attachedIndex) {
			this.local_data.deleteAttachTml = {
				tml: tml,
				index: index,
				attachedIndex: attachedIndex,
			};

			if (tml.tml_media_type == "image") {
				this.$refs.deletePicture.show();
			} else {
				this.$refs.deleteMovie.show();
			}
		},

		/**
		 * 画像または動画を削除する（実行）
		 */
		handleDeleteImageAndVideo() {
			let tml = this.local_data.deleteAttachTml.tml;
			let index = this.local_data.deleteAttachTml.index;
			let attachedIndex = this.local_data.deleteAttachTml.attachedIndex;

			console.log(tml, index);

			tml.mediaList.splice(index, 1);

			if (this.attacheFileNum(tml) == 0) {
				tml.tml_media_type = "none";
			}

			if (!tml.deleteAttacheFileIndex) {
				tml.deleteAttacheFileIndex = Array();
			}

			tml.deleteAttacheFileIndex.push(attachedIndex);

			this.isShowAttacheFileList = false;
			// this.$set(this.local_data.deleteAttachTml, "tml", tml);
			this.local_data.deleteAttachTml.tml = tml;
			this.isShowAttacheFileList = true;
		},

		/**
		 * 選択されている図面（画像）をリセットする
		 */
		handleClearBlueprintFile: function () {
			this.local_data.addBlueprintName = "";
			this.$refs.add_blurprint_input.reset();

			this.state_data.addBlueprintName = null;
			this.state_data.addBlueprintFile = null;
		},

		/**
		 * 図面を追加する
		 */
		handleAddBlueprintFile: function () {
			if (this.local_data.addBlueprintName == "" || !this.local_data.addBlueprintFile) {
				this.state_data.addBlueprintName = null;
				this.state_data.addBlueprintFile = null;

				if (this.local_data.addBlueprintName == "") {
					this.state_data.addBlueprintName = false;
				}

				if (!this.local_data.addBlueprintFile) {
					this.state_data.addBlueprintFile = false;
				}

				return;
			}

			let limitSizeByte = Number(import.meta.env.VITE_BLUEPRINT_FILE_SIZE_LIMIT) * 1048576;

			if (this.local_data.addBlueprintFile.size > limitSizeByte) {
				/* エラー表示がページトップに戻らないと見えないので、トーストに変更
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "選択可能なファイルの容量は" + import.meta.env.VITE_BLUEPRINT_FILE_SIZE_LIMIT + "MB以内です。";
				 */
				push.error({
					title: "ファイル容量エラー",
					message: `選択可能なファイルの容量は ${import.meta.env.VITE_BLUEPRINT_FILE_SIZE_LIMIT} MB以内です。`,
				});
				// this.toast("選択可能なファイルの容量は" + import.meta.env.VITE_BLUEPRINT_FILE_SIZE_LIMIT + "MB以内です。", "エラー", 5000, "danger");

				return;
			}

			//ファイルの種類をチェックする
			if (this.local_data.addBlueprintFile.type !== "image/jpeg" && this.local_data.addBlueprintFile.type !== "image/png") {
				push.error({
					title: "ファイル種類エラー",
					message: `選択可能なファイルの種類はJPEGまたはPNGです。`,
				});
				// this.toast("選択可能なファイルの種類はJPEGまたはPNGです。", "エラー", 5000, "danger");

				return;
			}

			let addFileInfo = {
				id: new Date().getTime(),
				name: this.local_data.addBlueprintName,
				file: this.local_data.addBlueprintFile,
			};

			this.local_data.addBlueprintFiles.push(addFileInfo);

			this.handleClearBlueprintFile();
		},

		/**
		 * 追加した図面を除去する
		 *
		 * @param {*} id
		 */
		handleRemoveBlueprintFile: function (id) {
			let idx = this.local_data.addBlueprintFiles.findIndex((t) => t.id === id);

			this.local_data.addBlueprintFiles.splice(idx, 1);
		},

		/**
		 * 登録済みの図面を削除する（確認）
		 *
		 * @param {*} filename
		 */
		handleConfirmDeleteBlueprint: function (filename) {
			this.local_data.deleteDraw = this.local_data.drawInfoList.findIndex((t) => t.file_name == filename);

			if (this.local_data.deleteDraw != -1) {
				this.$refs.deleteBlueprint.show();
			}
		},

		/**
		 * 登録済みの図面を削除する（実行）
		 */
		handleDeleteBlueprint: async function () {
			this.isViewBlueprint = false;

			await this.$nextTick();

			if (this.local_data.deleteDraw != -1) {
				this.local_data.deleteDrawInfoList = this.local_data.deleteDrawInfoList.concat(
					this.local_data.drawInfoList.splice(this.local_data.deleteDraw, 1)
				);
				this.local_data.deleteDraw = -1;
			}

			await this.getDrawInfo();

			this.isViewBlueprint = true;
		},

		/**
		 * S3の図面を更新する
		 */
		updateBlueprint: async function (s3Info) {
			// 削除する図面の有無をチェック
			if (this.local_data.deleteDrawInfoList.length > 0) {
				for (let idx = 0; idx < this.local_data.deleteDrawInfoList.length; idx++) {
					let orgIdx = this.local_data.deleteDrawInfoList[idx].orgIdx;
					let drawInfo = JSON.parse(s3Info.bas_draw_info);

					let postData = {
						sid: this.$store.state.sid,
						mode: "delete",
						fbs_uuid: s3Info.fbs_uuid,
						bucket_name: drawInfo[orgIdx].bucket_name,
						bucket_key: drawInfo[orgIdx].bucket_key,
						file_name: drawInfo[orgIdx].file_name,
					};

					try {
						const res = await axios.post(import.meta.env.VITE_RESTAPI_DELETE_S3_FORM_MEDIA_FILE, postData);

						if (res.data.result !== "OK") {
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = res.data.message;
						}
					} catch (ex) {
						console.error(ex);
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = JSON.stringify(ex);
					}
				}
			}

			// 追加する図面の有無をチェック
			if (this.local_data.addBlueprintFiles.length > 0) {
				for (let idx = 0; idx < this.local_data.addBlueprintFiles.length; idx++) {
					let fileInfo = this.local_data.addBlueprintFiles[idx];

					if (fileInfo.file instanceof File) {
						const dataUrl = await this.readImageAsBase64(fileInfo.file);
						fileInfo.file = await this.resizeImage(dataUrl);

						try {
							let ext = fileInfo.file.type.substring(fileInfo.file.type.indexOf("/") + 1);
							let formData = new FormData();

							formData.append("sid", this.$store.state.sid);
							formData.append("fbs_uuid", s3Info.fbs_uuid);
							formData.append("drw_name", fileInfo.name);
							formData.append("bucket_name", import.meta.env.VITE_BLUEPRINT_BAS_BUCKET_NAME);
							formData.append("bucket_key", import.meta.env.VITE_BLUEPRINT_BAS_BUCKET_KEY + `/${s3Info.fbs_uuid}`);
							formData.append("media_file", fileInfo.file, `${uuid.v4()}.${ext}`);

							const config = {
								headers: {
									"content-type": "multipart/form-data",
								},
								timeout: 0,
							};

							const res = await axios.post(import.meta.env.VITE_RESTAPI_SET_S3_FORM_MEDIA_FILE, formData, config);

							if (res.data.result !== "OK") {
								this.message.is_alert = true;
								this.message.variant = "danger";
								this.message.message = res.data.message;
							}
						} catch (ex) {
							console.error(ex);
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = JSON.stringify(ex);
						}
					}
				}
			}
		},

		async getConsultList({ mode = "mine", fbsUuid = "", status = "" }) {
			let value;
			let param = {
				params: {
					sid: this.$store.state.sid,
					mode: mode,
					fbs_uuid: fbsUuid,
					status: status,
				},
			};

			console.log(import.meta.env.VITE_RESTAPI_GET_CONSULT_LIST, param);

			try {
				let res = await axios.get(import.meta.env.VITE_RESTAPI_GET_CONSULT_LIST, param);

				if (res.data.result === "OK") {
					this.set_sid(res.data.sid);
				}

				value = res.data;
			} catch (err) {
				value = {
					result: "NG",
					message: "サーバから情報取得できませんでした。もう一度操作してください。",
				};
			}

			return value;
		},

		// /**
		//  * トーストを表示します。
		//  *
		//  * @param {string} message トーストに表示するメッセージ
		//  * @param {string} title トーストのタイトル
		//  * @param {number} autoHideDelay トーストが消えるまでの時間（単位：ミリ秒）
		//  * @param {string} variant トーストの見た目を表す文字列。"primary"/"secondary"/"danger"/"warning"/"success"/"info"のいずれか
		//  */
		// toast(message, title, autoHideDelay, variant){
		// 	this.$bvToast.toast(message, {
		// 		title: title,
		// 		autoHideDelay: autoHideDelay,
		// 		appendToast: true,
		// 		variant: variant,
		// 		solid: true,
		// 		toaster: "b-toaster-top-full"
		// 	});
		// },
	},
};
</script>
