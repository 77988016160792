<template>
	<div class="AccessLogDownload">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-card class="card-shadow border-1 mt-2">
				<b-row class="mt-2">
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form-select
							v-model="bind_data.company_selected_id"
							:options="bind_data.company_select_options"
							placeholder="選択してください"></b-form-select>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">ログ種別</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form-select v-model="bind_data.log_type" :options="local_data.log_type_options" placeholder="選択してください" />
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">期間</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form>
							<div class="row">
								<div class="col-lg-3">
									<!-- <b-form-datepicker v-model="bind_data.from_date" locale="ja" label-no-date-selected="開始日付を選択してください"
										:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
										class="m-0" :date-disabled-fn="dateDisabled" :state="state_date"></b-form-datepicker> -->
									<VueDatePicker
										v-model="bind_data.from_date"
										model-type="yyyy-MM-dd"
										locale="ja"
										:format="date_format"
										placeholder="開始日付を選択してください"
										:state="state_date"
										:enable-time-picker="false"
										:clearable="false"
										class="m-0"
										week-start="0"
										:max-date="$dayjs().format('YYYY-MM-DD')"
										required
										auto-apply>
										<template #input-icon>
											<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
										</template>
										<!-- <template #action-extra="{ selectCurrentDate }">
											<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
										</template> -->
									</VueDatePicker>
								</div>
								<div class="col-lg-1" style="text-align: center">−</div>
								<!-- <b-form-datepicker v-model="bind_data.to_date" locale="ja" label-no-date-selected="終了日付を選択してください"
									:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
									class="m-0" :date-disabled-fn="dateDisabled" :state="state_date"></b-form-datepicker> -->
								<div class="col-lg-3">
									<VueDatePicker
										v-model="bind_data.to_date"
										model-type="yyyy-MM-dd"
										locale="ja"
										:format="date_format"
										placeholder="終了日付を選択してください"
										:state="state_date"
										:enable-time-picker="false"
										:clearable="false"
										class="m-0"
										week-start="0"
										:max-date="$dayjs().format('YYYY-MM-DD')"
										required
										auto-apply>
										<template #input-icon>
											<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
										</template>
									</VueDatePicker>
								</div>
							</div>
						</b-form>
						<small>一度にダウンロードできる期間は、7日間以内です。終了日付は、本日以前を指定してください。</small>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<b-button
							variant="outline-secondary"
							class="ms-2 float-end"
							:disabled="state_download_button"
							v-on:click="click_download()"
							>CSVダウンロード</b-button
						>
					</b-col>
				</b-row>
			</b-card>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";

import common from "@/lib/common.js";
import { BAlert, BButton, BCard, BRow, BCol, BForm, BFormSelect } from "bootstrap-vue-next";

export default {
	name: "AccessLogDownload", // ココを更新
	components: {
		Loading,
		BAlert,
		BButton,
		BCard,
		BRow,
		BCol,
		BForm,
		BFormSelect,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				log_type: "",
				from_date: "",
				to_date: "",
				company_select_options: [],
				last_modified: "",
			},
			// ローカルデータ
			local_data: {
				log_type_options: [
					{ value: "login_log", text: "ログイン履歴" },
					{ value: "operations_log", text: "操作履歴" },
					{ value: "dbaccess_log", text: "DBアクセス履歴" },
				],
			},
			// 状態
			state_data: {},
		};
	},

	// 既定計算
	computed: {
		state_date: function () {
			let from_date = this.$dayjs(this.bind_data.from_date);
			let to_date = this.$dayjs(this.bind_data.to_date);
			if (from_date > to_date) {
				return false;
			}
			if (to_date.diff(from_date, "days") > 6) {
				return false;
			}
			return true;
		},
		state_download_button: function () {
			return !this.state_date;
		},
	},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "アクセスログダウンロード" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		// ローディング開始
		this.loader.is_loading = true;

		this.companys_list();

		this.bind_data.log_type = "login_log";
		this.bind_data.from_date = this.$dayjs().add(-1, "days").format("YYYY-MM-DD");
		this.bind_data.to_date = this.$dayjs().add(-1, "days").format("YYYY-MM-DD");

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		dateDisabled(ymd) {
			let today = this.$dayjs().format("YYYY-MM-DD");
			if (ymd > today) {
				return true;
			} else {
				return false;
			}
		},

		// 会社一覧
		async companys_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					this.bind_data.company_select_options = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let item = {};
							item.value = response.data.company_list[i].cmp_id;
							item.text = response.data.company_list[i].cmp_name;
							this.bind_data.company_select_options.push(item);
							if (i == 0) {
								this.bind_data.company_selected_id = item.value;
							}
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "検索結果がありません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// CSVダウンロード
		async click_download() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let header = {
				"x-api-key": import.meta.env.VITE_API_KEY,
			};
			let url = import.meta.env.VITE_BTALERT_GET_SUMMARIZED_LOG;
			let post_data = {};
			post_data.type = this.bind_data.log_type;
			post_data.cnt_uuid = this.bind_data.company_selected_id;
			post_data.from_date = this.$dayjs(this.bind_data.from_date).format("YYYY/MM/DD");
			post_data.to_date = this.$dayjs(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path + ":post_data=" + JSON.stringify(post_data));

			try {
				let response = await axios.post(url, post_data, { headers: header });
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					// ローディング終了
					this.loader.is_loading = false;

					if (response.data.csv_log === "") {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "ダウンロードするデータはありませんでした。";
					} else {
						let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
						let link = document.createElement("a");
						link.download = this.bind_data.log_type + "_" + this.bind_data.from_date + "_" + this.bind_data.to_date + ".csv";
						let blob = new Blob([UTF_8_BOM, response.data.csv_log], { type: "text/csv;charset=utf-8" });
						link.href = window.URL.createObjectURL(blob);
						document.body.appendChild(link);
						link.click();
					}
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 当日のログのサマリーデータの存在をチェックする
		/**
		async get_summarized_log_info(){

			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let header = {
				"x-api-key":import.meta.env.VITE_API_KEY,
			}
			let url = import.meta.env.VITE_BTALERT_GET_SUMMARIZED_LOG_INFO;
			let post_data = {};
			post_data.type = this.bind_data.log_type;
			post_data.cnt_uuid = this.bind_data.company_selected_id;
			post_data.date = this.$dayjs(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
			try {
				let response = await axios.post( url, post_data, { headers: header } );
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					// ローディング終了
					this.loader.is_loading = false;

					if( response.data.info == "" ){
						this.$refs['confirm-modal-1'].show();
					}
					else {
						this.bind_data.last_modified = response.data.info;
						this.$refs['confirm-modal-2'].show();
					}

				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}

		},

		// 当日のログのサマリーデータを作成する
		async log_summarizer(){

			this.$refs['confirm-modal-1'].hide();
			this.$refs['confirm-modal-2'].hide();
			
			this.message.is_alert = true;
			this.message.variant = "info";
			this.message.message = "集計処理中です。しばらくお待ちください。";

			// ローディング開始
			this.loader.is_loading = true;

			let header = {
				"x-api-key":import.meta.env.VITE_API_KEY,
			}
			let url = import.meta.env.VITE_BTALERT_LOG_SUMMARIZER;
			let post_data = {};
			post_data.date = this.$dayjs(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
			let response = null;
			try {
				response = await axios.post( url, post_data, { headers: header } );
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					// ローディング終了
					this.loader.is_loading = false;
					this.message.is_alert = true;
					this.message.variant = "success";
					this.message.message = "本日のログが集計されました。";

				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				//this.message.variant = "danger";
				//this.message.message = JSON.stringify(ex);
				this.message.variant = "warning";
				this.message.message = "タイムアウトが発生しました。集計処理に時間がかかっていることが予想されるため、しばらくした後に、再度、［CSVダウンロード］をクリックしてください。";
			}

		},

		cancel(){
			this.$refs['confirm-modal-1'].hide();
			this.$refs['confirm-modal-2'].hide();
		},
		**/
	},
};
</script>
