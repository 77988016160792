<template>
	<div class="Notice">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div class="border-top pt-2 mt-4" v-for="notice_list in bind_data.notice_lists" :key="notice_list.id">
				<b-row>
					<b-col
						>{{ notice_list.nws_subject }}<br />
						<small v-html="notice_list.nws_contents.replace(/\n/g, '<br>')"></small
					></b-col>
					<b-col class="text-end">{{ notice_list.nws_start_date }}</b-col>
				</b-row>
			</div>
			<!--
        <div class="text-end mt-3"><b-link href="#">もっと見る</b-link></div>
        -->
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import { mapActions, mapMutations, mapState } from "vuex";
import { BAlert, BRow, BCol } from "bootstrap-vue-next";

export default {
	name: "Notice", // ココを更新
	components: {
		Loading,
		BAlert,
		BRow,
		BCol,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				notice_lists: [],
				notice: {
					id: "",
					nws_subject: "",
					nws_start_date: "",
					nws_contents: "",
				},
			},
			// ローカルデータ
			local_data: {},
		};
	},

	// 既定計算
	computed: {
		...mapState("api", ["newsInfo"]),

		latestNewsUpdate: function () {
			return this.newsInfo.latest_update;
		},
	},

	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "お知らせ" );
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
		// await this.getNewsList( {_this: this} );
		await this.notice_list();

		console.log("latestNewsUpdate = ", this.latestNewsUpdate);

		this.setNewsLastCheckDateTime(this.latestNewsUpdate);
		this.setIsNewArrivalNews(false);
	},
	// ローカル関数
	methods: {
		...mapMutations("api", ["setNewsLastCheckDateTime", "setIsNewArrivalNews"]),

		...mapActions("api", ["getNewsList"]),

		async notice_list() {
			// ローディング開始
			this.loader.is_loading = true;
			let url =
				import.meta.env.VITE_RESTAPI_GET_NEWS_LIST +
				"?sid=" +
				this.$store.state.sid +
				"&org_uuid=" +
				this.$store.state.users_info.usr_org_uuid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.notice_lists = [];
					if (response.data.news_list.length > 0) {
						for (let i = 0; i < response.data.news_list.length; i++) {
							this.bind_data.notice = [];
							this.bind_data.notice.id = i;
							this.bind_data.notice.nws_subject = response.data.news_list[i].nws_subject;
							this.bind_data.notice.nws_start_date = response.data.news_list[i].nws_start_date;
							this.bind_data.notice.nws_contents = response.data.news_list[i].nws_contents;
							this.bind_data.notice_lists.push(this.bind_data.notice);
						}
					} else {
						this.bind_data.notice_lists = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "お知らせはありません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
	},
};
</script>
