<template>
<div class="CaseDetailsView">
    <!-- ローディング -->
    <loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>  <!-- ヘッダー -->
    <!-- メッセージ -->
    <b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
    <!-- メインコンテンツ -->

    <template v-if="bind_data.fbs_info">
      <div class="container text-start">
        <b-button v-on:click="back()" variant="outline-secondary" class="m-2">戻る</b-button>
        <div class="float-end">
          <b-button v-show="bind_data.fbs_info.bas_comp_type==='complete'" v-on:click="show_before()" variant="outline-secondary" class="m-2">編集前を表示</b-button>
        </div>
  
        <template v-if="isOpenCommented()">
          <!-- <b-card header-bg-variant="info" header-text-variant="white" bg-variant="light" class="mx-2 mb-4">
            <template #header>
              <h5 class="mb-0 font-weight-bold">相談内容</h5>
            </template>
            <b-card-body>
              <b-row>
                <b-col cols="3">
                  <b>コメント日時</b>
                </b-col>
                <b-col cols="9">
                  {{bind_data.fbs_info.fbs_comment_date_time}}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <b>コメント</b>
                </b-col>
                <b-col cols="9" class="report-comment">
                  {{bind_data.fbs_info.fbs_comment}}
                </b-col>
              </b-row>
            </b-card-body>
          </b-card> -->

			<template v-if="isShowConsult">
				<b-card header-bg-variant="warning" class="mx-2 mb-2">
					<template #header>
						<h5 class="mb-0 float-start">相談内容</h5>
						<b-button v-if="local_data.consultList.length > 1" v-b-toggle.collapse-1 size="sm" class="float-end">{{consultListCallaoseBtnTitle}}</b-button>
					</template>
					<div v-for="(consult, index) in local_data.consultList" :key="consult">
						<b-collapse v-model="state_data.consultListVisible" id="collapse-1" v-if="index < local_data.consultList.length - 1">
							<table style="width: 100%;">
								<template v-if="consult.cnm_consult_content != ''">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_consult_date_time}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_consult_content}}</span></td>
									</tr>
								</template>
								<template v-if="consult.cnm_status == 'answer'">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_answer_date_time}}</td>
									</tr>
									<tr v-if="consult.contact_user_name != ''">
										<td valign="top" style="width: 1%; white-space: nowrap;">回答者</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.contact_user_name}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_answer_content}}</span></td>
									</tr>
								</template>
							</table>
							<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red;">
								この相談はキャンセルされました。
							</div>
							<hr>
						</b-collapse>
						<template v-else>
							<table style="width: 100%;">
								<template v-if="consult.cnm_consult_content != ''">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_consult_date_time}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_consult_content}}</span></td>
									</tr>
								</template>
								<template v-if="consult.cnm_status == 'answer'">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_answer_date_time}}</td>
									</tr>
									<tr v-if="consult.contact_user_name != ''">
										<td valign="top" style="width: 1%; white-space: nowrap;">回答者</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.contact_user_name}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_answer_content}}</span></td>
									</tr>
								</template>
							</table>
							<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red;">
								この相談はキャンセルされました。
							</div>
						</template>
					</div>
				</b-card>
			</template>

		</template>

        <!-- 事案データ表示 -->
        <b-row>
          <b-col>
            <b-card header-bg-variant="light" class="mx-2 mb-4">
              <template #header>
                <h4 class="m-0 p-0 font-weight-bold">番号</h4>
              </template>
              <div v-if="bind_data.fbs_info.fbs_version_number">{{ bind_data.fbs_info.fbs_version_number }}</div>
              <div v-else>-</div>
            </b-card>
          </b-col>
          <b-col>
            <b-card header-bg-variant="light" class="mx-2 mb-4">
              <template #header>
                <h4 class="m-0 p-0 font-weight-bold">コピー元</h4>
              </template>
              <div v-if="bind_data.fbs_info.fbs_original_number">{{ bind_data.fbs_info.fbs_original_number }}</div>
              <div v-else>-</div>
            </b-card>
          </b-col>
        </b-row>
        
        <b-card header-bg-variant="light" class="mx-2 mb-4">
          <template #header>
            <h4 class="m-0 p-0 font-weight-bold">報告書名称</h4>
          </template>
          {{ bind_data.fbs_info.fbs_report_title }}
        </b-card>
        
        <BaseDataUtils :bas_info="bind_data.fbs_info"></BaseDataUtils>
  
        <b-card header-bg-variant="light" class="mx-2 mt-2 mb-4">
          <template #header>
            <h4 class="mb-0 font-weight-bold">編集理由</h4>
          </template>
          {{ bind_data.fbs_info.fbs_chg_reason }}
        </b-card>

        <b-card header-bg-variant="light" class="mx-2 mb-4">
          <template #header>
            <h4 class="mb-0 font-weight-bold">報告者</h4>
          </template>
          {{ bind_data.fbs_info.fbs_reporter }}
        </b-card>

        <!-- 完了していない時 -->
        <template v-if="bind_data.fbs_info.fbs_form_comp_type !== 'complete'">
          <b-card header-bg-variant="light" class="mx-2 mb-4">
            <template #header>
              <h4 class="mb-0 font-weight-bold">共有先</h4>
            </template>
            <!-- <template v-for="share in bind_data.fbs_info.fbs_share_dest_list" class="me-2"> -->
            <template v-for="share in bind_data.fbs_info.fbs_share_dest_list" :key="share">
              <!-- <span class="me-2">{{share.fbs_share_dest_uuid_name}}</span>   -->
              {{share.fbs_share_dest_uuid_name}}  
            </template>
          </b-card>

          <!-- 相談された者だけがコメントできる -->
          <!-- <template v-if="isConsulted()">
            <b-card header-bg-variant="light" class="ms-2 mt-2 mb-2">
              <template #header>
                <h4 class="mb-0 font-weight-bold">コメント</h4>
              </template>
              <b-form-textarea v-model="bind_data.fbs_comment" class="m-0" :state="state_data.fbs_comment"></b-form-textarea>
            </b-card>
            <div class="text-end ms-2 mt-2 mb-2">
              <template v-if="hasReportCommentVerifiedPerm">
                <b-button class="ms-3" variant="outline-secondary" @click="handleConfirmComment()">コメントして戻す</b-button>
              </template>
              <template v-if="hasReportCommentVerifiedPerm">
                <b-button class="ms-3" variant="outline-secondary" @click="handleConfirmCommentAndConfirm()">コメント非公開で確認済みにする</b-button>
              </template>
              <template v-if="hasReportCommentVerifiedPerm">
                <b-button class="ms-3" variant="outline-secondary" @click="handleConfirmConfirm()">コメント公開で確認済みにする</b-button>
              </template>
            </div>
          </template> -->
        </template>

        <template v-if="isCommented()">
          <!-- <b-card header-bg-variant="info" header-text-variant="white" bg-variant="light" class="mx-2 mb-4">
            <template #header>
              <h4 class="mb-0 font-weight-bold text-center">コメント</h4>
            </template>
            <b-card-body>
              <b-row>
                <b-col cols="3">
                  <b>コメント日時</b>
                </b-col>
                <b-col cols="9">
                  {{bind_data.fbs_info.fbs_comment_date_time}}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <b>コメント</b>
                </b-col>
                <b-col cols="9" class="report-comment">
                  {{bind_data.fbs_info.fbs_comment}}
                </b-col>
              </b-row>
            </b-card-body>
          </b-card> -->

			<template v-if="isShowConsult">
				<b-card header-bg-variant="warning" class="mx-2 mb-2">
					<template #header>
						<h5 class="mb-0 float-start">相談内容</h5>
						<b-button v-if="local_data.consultList.length > 1" v-b-toggle.collapse-1 size="sm" class="float-end">{{consultListCallaoseBtnTitle}}</b-button>
					</template>
					<div v-for="(consult, index) in local_data.consultList" :key="consult">
						<b-collapse v-model="state_data.consultListVisible" id="collapse-1" v-if="index < local_data.consultList.length - 1">
							<table style="width: 100%;">
								<template v-if="consult.cnm_consult_content != ''">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_consult_date_time}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_consult_content}}</span></td>
									</tr>
								</template>
								<template v-if="consult.cnm_status == 'answer'">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_answer_date_time}}</td>
									</tr>
									<tr v-if="consult.contact_user_name != ''">
										<td valign="top" style="width: 1%; white-space: nowrap;">回答者</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.contact_user_name}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_answer_content}}</span></td>
									</tr>
								</template>
							</table>
							<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red;">
								この相談はキャンセルされました。
							</div>
							<hr>
						</b-collapse>
						<template v-else>
							<table style="width: 100%;">
								<template v-if="consult.cnm_consult_content != ''">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_consult_date_time}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">相談内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_consult_content}}</span></td>
									</tr>
								</template>
								<template v-if="consult.cnm_status == 'answer'">
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答日時</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.cnm_answer_date_time}}</td>
									</tr>
									<tr v-if="consult.contact_user_name != ''">
										<td valign="top" style="width: 1%; white-space: nowrap;">回答者</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top">{{consult.contact_user_name}}</td>
									</tr>
									<tr>
										<td valign="top" style="width: 1%; white-space: nowrap;">回答内容</td>
										<td valign="top" style="width: 1%; white-space: nowrap;">：</td>
										<td valign="top"><span style="white-space: pre-wrap">{{consult.cnm_answer_content}}</span></td>
									</tr>
								</template>
							</table>
							<div v-if="consult.cnm_status == 'cancel'" class="mt-2 text-center" style="color: red;">
								この相談はキャンセルされました。
							</div>
						</template>
					</div>
				</b-card>
			</template>
	
        </template>

        <div class="mx-2 mb-4">
          <b-row>
            <b-col><div class="text-end">最終更新者：{{bind_data.fbs_info.fbs_upd_usr_uuid_name}}</div></b-col>
            <b-col><div>最終更新日時：{{bind_data.fbs_info.fbs_upd_date_time}}</div></b-col>
          </b-row>
        </div>

      </div>
      <div v-if="isCancelFormButton()" class="container text-end">
        <b-button v-on:click="handleCancelForm()" variant="outline-secondary" class="mb-2 me-2">相談をキャンセルする</b-button>
      </div>
    </template>
    <b-modal ref="cancelForm" title="相談をキャンセルする" @ok="cancelForm()">相談をキャンセルします。よろしいですか？</b-modal>
    <!-- <b-modal ref="confirmComment" title="コメントして戻す" @ok="handleComment()">コメントして戻します。よろしいですか？</b-modal> -->
    <!-- <b-modal ref="confirmCommentAndConfirm" title="コメント非公開で確認済みにする" @ok="handleCommentAndConfirm()">コメント非公開で確認済みにします。よろしいですか？</b-modal> -->
    <!-- <b-modal ref="confirmConfirm" title="コメント公開で確認済みにする" @ok="handleConfirm()">コメント公開で確認済みにします。よろしいですか？</b-modal> -->

    <br><br><br>
  </div>    
</template>

<style></style>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css'
import axios from 'axios';
// import moment from 'moment-timezone';
import common from '@/lib/common.js';
import BaseDataUtils from '@/components/BaseDataUtils.vue';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import dataset from '@/lib/dataset.js';
import perm from '@/lib/perm.js';
import { mapMutations } from 'vuex';
import { BAlert, BButton, BCard, BTable, BBadge, BCardTitle, BCardText, BModal, BCol, BRow, BCollapse, vBToggle, BImg} from 'bootstrap-vue-next';

export default {
  name: 'CaseDetailsView', // ココを更新
  components: {
    Loading,
    BaseDataUtils,
    treeselectdialogs,
    BAlert, BButton, BCard, BTable, BBadge, BCardTitle, BCardText, BModal, BCol, BRow, BCollapse, vBToggle, BImg
  },

  directives: {
		'BToggle': vBToggle,
  },

  // 共通関数
  mixins: [
    common,
    dataset,
    perm,
  ],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        fbs_info: null,
        dpt_selected: [],
        dpt_options: this.data_get_dpt_info(),
        fbs_comment: null,
      },
      // ローカルデータ
      local_data: {
		consultList: [],
	},
      // ローカルデータ
      state_data: {
        fbs_commnet: null,
		consultListVisible: false,
	}
    }
  },

  // 既定計算
  computed: {
	isShowConsult: function() {
		return (this.local_data.consultList && this.local_data.consultList.length > 0);
	},

	consultListCallaoseBtnTitle: function() {
		if ( this.state_data.consultListVisible ) {
			return '履歴を閉じる▲';
		} else {
			return '履歴を開く▼';
		}
	},
  },
  // インスタンス初期化後
  created(){
    // this.$eventHub.$emit( "navbar_title", "報告データ閲覧" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    this.bind_data.fbs_info = this.$store.state.selected_fbs_info;
    this.bind_data.fbs_info.timeline_list = this.bind_data.fbs_info.form_timeline_list;

	let ret = await this.getConsultList( {
		fbsUuid: this.bind_data.fbs_info.fbs_uuid,
	} );

	if ( ret.result === 'OK' ) {
		console.log( ret );

		this.local_data.consultList = ret.consult_list;
	} else {
		this.message.is_alert = true;
		this.message.variant = "danger";
		this.message.message = ret.message;

		// if ( ret.result === 'ERR' ) {
		// 	this.logout( {
		// 		_this: this,
		// 	} );
		// }
	}

	if ( this.bind_data.fbs_info.fbs_comment != null && this.bind_data.fbs_info.fbs_comment != '' ) {
		console.log( this.bind_data.fbs_info.fbs_comment );
		this.local_data.consultList.unshift(
			{
				consult_user_name: '',
				contact_user_name: '',
				cnm_status: 'answer',
				cnm_consult_content: '',
				cnm_consult_date_time: '',
				cnm_answer_content: this.bind_data.fbs_info.fbs_comment,
				cnm_answer_date_time: this.bind_data.fbs_info.fbs_comment_date_time,
			}
		);
	}
	
  },
  // ローカル関数
  methods: {
	...mapMutations( [
		'set_sid',
	] ),

    // 相談されたものだけがコメントできる
    isConsulted: function(){
      
      // 相談中の時
      if( this.bind_data.fbs_info.fbs_form_comp_type == "confirmpending" ){
        // 自分が相談者（確定者）の時
        if( this.bind_data.fbs_info.fbs_consult_usr_uuid === this.$store.state.users_info.usr_uuid ){
          return true;
        }
      }
      return false;
    },
    // 公開コメント表示
    isOpenCommented: function(){
      
      // 確認済みの時
      if( this.bind_data.fbs_info.fbs_form_comp_type == "complete" ){
        // 公開の時
        if( this.bind_data.fbs_info.fbs_open_comment == "on" ){
          return true;
        }
      }
      // 相談中の時 // 確認済みの時
      if( this.bind_data.fbs_info.fbs_form_comp_type == "fixpending" ){
        // 自分が相談した者（依頼者）の時
        if( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ){
          if( this.bind_data.fbs_info.fbs_comment !== "" ){
            return true;
          }
        }
        // 自分が相談者（確定者）の時
        if( this.bind_data.fbs_info.fbs_consult_usr_uuid === this.$store.state.users_info.usr_uuid ){
          return true;
        }
      }

      return false;
    },
    // 非公開コメント表示
    isCommented: function(){
      
      // 相談中の時 // 確認済みの時
      if( this.bind_data.fbs_info.fbs_form_comp_type == "confirmpending" ){
        // 公開の時
        if( this.bind_data.fbs_info.fbs_open_comment === "off" ){
          // 自分が相談した者（依頼者）の時
          if( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ){
            if( this.bind_data.fbs_info.fbs_comment !== "" ){
              return true;
            }
          }
        }
      }
      if( this.bind_data.fbs_info.fbs_form_comp_type == "complete" ){
        // 公開の時
        if( this.bind_data.fbs_info.fbs_open_comment === "off" ){
          // 自分が相談者（確定者）の時
          if( this.bind_data.fbs_info.fbs_consult_usr_uuid === this.$store.state.users_info.usr_uuid ){
            return true;
          }
          // 自分が相談した者（依頼者）の時
          if( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ){
            return true;
          }
        }
      }
      return false;
    },

    back: function(){
      if (this.$store.state.prev_screen_name == "/Home/CaseDetailsSelect"){
        this.transition("/Home/CaseDetailsSelect");
      } else {
        this.transition("/Home/CaseDetailsViewSelect");
      }
    },

    show_before: function(){

      // 画面遷移
      this.transition("/Home/CaseDetailsDiff");
    },

    get_dpt_info(data) {
      this.bind_data.dpt_selected = data;
    },
    
    /**
     * 入力をチェックする
     */
    valid() {
      this.state_data.fbs_comment = null;

      if (!this.bind_data.fbs_comment) {
        this.state_data.fbs_comment = false;
        this.$forceUpdate();
        return false;
      }

      return true;
    },

    /**
     * コメントするを確認する
     */
    handleConfirmComment() {
      if (!this.valid()) {
        return;
      }

      this.$refs['confirmComment'].show();
    },

    /**
     * コメントする
     */
    async handleComment() {
      await this.save('comment');
    },

    /**
     * コメントして確認するを確認する
     */
    handleConfirmCommentAndConfirm() {
      if (!this.valid()) {
        return;
      }

      this.$refs['confirmCommentAndConfirm'].show();
    },

    /**
     * コメントして確認する
     */
    async handleCommentAndConfirm() {
      await this.save('commentconfirm');
    },

    /**
     * 確認済みにするを確認する
     */
    handleConfirmConfirm() {
      this.$refs['confirmConfirm'].show();
    },

    /**
     * 確認済みにする
     */
    async handleConfirm() {
      await this.save('confirm');
    },

    /**
     * 報告データ保存
     */
    async save(saveMode) {
      this.loader.is_loading = true;
      await this.$nextTick();

      try {
        const tmls = [];
        for (let i=0 ; i<this.bind_data.fbs_info.form_timeline_list.length ; i++) {
          const tml = this.bind_data.fbs_info.form_timeline_list[i];

          tmls.push({
            ftl_uuid: tml.ftl_uuid,
            tml_uuid: tml.tml_uuid,
            tml_bas_uuid: this.bind_data.fbs_info.bas_uuid,
            tml_rpt_date_time: tml.tml_rpt_date_time,
            tml_reporter: tml.tml_reporter,
            tml_status_explain: tml.tml_status_explain,
            tml_media_type: tml.tml_media_type,
          });
        }

        const data = {
          sid: this.$store.state.sid,
          save_mode: saveMode,
          fbs_uuid: this.bind_data.fbs_info.fbs_uuid,
          fbs_report_title: this.bind_data.fbs_info.fbs_report_title,
          fbs_chg_reason: this.bind_data.fbs_info.fbs_chg_reason,
          fbs_reporter: this.bind_data.fbs_info.fbs_reporter,
          bas_uuid: this.bind_data.fbs_info.bas_uuid,
          bas_rpt_type: this.bind_data.fbs_info.bas_rpt_type,
          bas_mng_uuid: this.bind_data.fbs_info.bas_mng_uuid,
          bas_org_uuid: this.bind_data.fbs_info.bas_org_uuid,
          bas_subject: this.bind_data.fbs_info.bas_subject,
          bas_occur_date_time: this.bind_data.fbs_info.bas_occur_date_time,
          bas_element: this.bind_data.fbs_info.bas_element,
          bas_dlv_list: this.bind_data.fbs_info.bas_dlv_list,
          bas_case_number: this.bind_data.fbs_info.bas_case_number,
          form_timeline_list: tmls,
        };
        if (this.bind_data.fbs_info.fbs_consult_usr_uuid) {
          data.fbs_consult_usr_uuid = this.bind_data.fbs_info.fbs_consult_usr_uuid;
        }
        const share = [];
        for (let i=0 ; i<this.bind_data.fbs_info.fbs_share_dest_list.length ; i++) {
          share.push(this.bind_data.fbs_info.fbs_share_dest_list[i].fbs_share_dest_uuid);
        }
        data.fbs_share_dest = share.join(',');
       // if (saveMode === 'comment' || saveMode === 'commentconfirm') {
          data.fbs_comment = this.bind_data.fbs_comment;
          data.fbs_comment_date_time = this.$dayjs().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss");
       // }

        const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_FORM_BASICS_INFO, data);
        if (res.data.result === 'OK') {
          this.$store.dispatch("commit_set_sid",res.data.sid);
          this.back();
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }

      }
      catch(e) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }

      this.loader.is_loading = false;
    },

    //相談のキャンセルボタン表示切り替え
    isCancelFormButton() {
      if( this.bind_data.fbs_info.fbs_form_comp_type == "confirmpending" ){
        if( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ){
          return true;
        }
      }

      return false
    },

    //相談のキャンセルを確認する
    handleCancelForm() {
      this.$refs['cancelForm'].show();
    },

    //相談をキャンセルする
    async cancelForm(){
      this.loader.is_loading = true;

      try{
        const data = {
          sid: this.$store.state.sid,
          fbs_uuid: String(this.bind_data.fbs_info.fbs_uuid),
          before_upd_date_time: String(this.bind_data.fbs_info.fbs_upd_date_time),
        }
        const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_CONSULTATION_CANCEL_FORM_BASIC, data);
        if (res.data.result === 'OK') {
          this.$store.dispatch("commit_set_sid",res.data.sid);
          this.back();
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      } 
      catch(e) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }

      this.loader.is_loading = false;
    },

	async getConsultList( {mode = 'mine', fbsUuid = '', status = ''} ) {
		let value;
		let param = {
			params: {
				sid: this.$store.state.sid,
				mode: mode,
				fbs_uuid: fbsUuid,
				status: status,
			},
		};

		console.log( import.meta.env.VITE_RESTAPI_GET_CONSULT_LIST, param );

		try {
			let res = await axios.get( import.meta.env.VITE_RESTAPI_GET_CONSULT_LIST, param );

			if ( res.data.result === 'OK' ) {
				this.set_sid( res.data.sid );
			}
	
			value = res.data;
		} catch( err ) {
			value = {
				result: 'NG',
				message: 'サーバから情報取得できませんでした。もう一度操作してください。',
			};
		}

		return value;
	},
  }
}
</script>