<template>
	<div class="PasswordManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-card class="card-shadow border-1 mt-2 ms-2">
				<b-row>
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">現在のパスワード</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form-input
							v-model="bind_data.current_password"
							type="password"
							placeholder="現在のパスワード"
							:state="state_current_password"
							maxlength="32"></b-form-input>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">新しいパスワード</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form-input
							v-model="bind_data.new_password"
							type="password"
							placeholder="半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください"
							:state="state_new_password"
							maxlength="32"></b-form-input>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col xs="12" sm="4" md="4" lg="3" xl="2">確認用パスワード</b-col>
					<b-col xs="12" sm="8" md="8" lg="9" xl="10">
						<b-form-input
							v-model="bind_data.confirm_password"
							type="password"
							placeholder="半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください"
							:state="state_confirm_password"
							maxlength="32"></b-form-input>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<b-button @click="update()" variant="outline-secondary" class="ms-2 float-end" :disabled="state_update"
							>更新</b-button
						>
					</b-col>
				</b-row>
			</b-card>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import validator from "validator";
import common from "@/lib/common.js";
import { BAlert, BButton, BCard, BFormInput, BCol, BRow } from "bootstrap-vue-next";

export default {
	name: "PasswordManage", // ココを更新
	components: {
		Loading,
		BAlert,
		BButton,
		BCol,
		BRow,
		BFormInput,
		BCard,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				current_password: "",
				new_password: "",
				confirm_password: "",
			},
			// ローカルデータ
			local_data: {},
		};
	},

	// 既定計算
	computed: {
		state_current_password() {
			return !validator.isEmpty(this.bind_data.current_password);
		},
		state_new_password() {
			/*
      const regex = /^(?=.*[A-Z])(?=.*[.?/@-])[a-zA-Z0-9.?/@-]{8,32}$/;
      return regex.test(this.bind_data.new_password);
       */
			return this.check_password(this.bind_data.new_password);
		},
		state_confirm_password() {
			/*
      const regex = /^(?=.*[A-Z])(?=.*[.?/@-])[a-zA-Z0-9.?/@-]{8,32}$/;
      return regex.test(this.bind_data.confirm_password);
       */
			return this.check_password(this.bind_data.confirm_password);
		},
		state_update() {
			if (this.state_current_password && this.state_new_password && this.state_confirm_password) {
				return false;
			} else {
				return true;
			}
		},
	},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "パスワード更新" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
	},
	// ローカル関数
	methods: {
		// 更新実行
		async update() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_PASS_CHG;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.current_password = this.bind_data.current_password;
			post_data.new_password = this.bind_data.new_password;
			post_data.confirm_password = this.bind_data.confirm_password;

			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.message.is_alert = true;
					this.message.variant = "info";
					this.message.message = "パスワードを変更しました。";
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
		/**
		 * 指定されたパスワードに対して以下をチェックします。
		 * ・長さ
		 * ・使用可能な文字だけで構成されているか
		 * ・複雑さ
		 *
		 * @param {string} password チェックするパスワード
		 * @returns 指定されたパスワードが条件を満たしている場合はtrue、そうでない場合はfalse
		 */
		check_password(password) {
			const regexs = [/^.*[a-z].*$/, /^.*[A-Z].*$/, /^.*[0-9].*$/, /^.*[.?/@-].*$/]; //複雑さをチェックする正規表現の配列
			let count = 0;
			for (let i = 0; i < regexs.length; i++) {
				if (regexs[i].test(password)) {
					count++;
				}
			}

			const regex = /^[a-zA-Z0-9.?/@-]{8,32}$/; //長さと使用可能な文字をチェックする正規表現

			return 4 <= count && regex.test(password);
		},
	},
};
</script>
