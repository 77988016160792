<template>
	<div class="Dashboard">
		<!-- ココを更新 -->
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-left">
			<b-button v-on:click="link_to('back')" variant="secondary" class="m-1">戻る</b-button>
			<h2>{{ bind_data.case_title }}</h2>
			<div class="border-top pt-2 mt-2" v-for="download_list in bind_data.download_lists" :key="download_list.id">
				<b-row>
					<b-col xs="12" sm="4" md="2" lg="1" xl="1">
						<b-badge v-if="download_list.fileflg" variant="light" class="p-3">報告書</b-badge>
						<b-badge v-else variant="light" class="p-3">画像データ</b-badge>
					</b-col>
					<b-col xs="12" sm="8" md="6" lg="7" xl="8" class="text-left">
						{{ download_list.fileNm }}
					</b-col>
					<b-col xs="12" sm="12" md="4" lg="4" xl="3" class="text-right">
						<b-button variant="secondary" class="m-1">ダウンロード</b-button>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import common from "@/lib/common.js";
import { BAlert, BButton, BCol, BRow, BBadge } from "bootstrap-vue-next";

export default {
	name: "DowmLoad", // ココを更新
	components: {
		Loading,
		BAlert,
		BButton,
		BCol,
		BRow,
		BBadge,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				ref: "",
				// 事案タイトル
				case_title: "停電作業による商品被害",
				// ダウンロードリスト
				download_lists: [
					{ id: 0, fileflg: true, fileNm: "オーナー向け報告書データダウンロード（Excel）" },
					{ id: 1, fileflg: true, fileNm: "JR東日本ビルテック報告書データダウンロード（Excel）" },
					{ id: 2, fileflg: false, fileNm: "画像データ1（廊下）" },
					{ id: 3, fileflg: false, fileNm: "画像データ2（商品棚１）" },
					{ id: 4, fileflg: false, fileNm: "画像データ3（商品棚２）" },
					{ id: 5, fileflg: false, fileNm: "画像データ4（店舗内部）" },
				],
			},
			// ローカルデータ
			local_data: {},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		//   this.$eventHub.$emit( "navbar_title", "ダウンロード" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
	},
	// ローカル関数
	methods: {
		link_to: function (to) {
			if (this.$route.path != to) {
				if (to == "back") {
					to = this.$route.query.ref;
				}
				// 画面遷移
				this.$router.push({
					path: to,
					query: {
						ref: this.$route.query.ref,
					},
				});
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>
