<template>
	<div class="Dashboard">
		<!-- ココを更新 -->
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<!-- 検索条件 -->
			<div class="mt-3">
				<b-button variant="outline-secondary" v-on:click="search_toggle()"
					>検索条件
					<BootstrapIcon icon="caret-down-fill" :rotate="bind_data.search_toggle_rotate"></BootstrapIcon>
				</b-button>
				<b-card v-show="bind_data.search_toggle" class="card-shadow border-1">
					<b-row class="mt-2">
						<b-col class="text-end" cols="2">担当組織</b-col>
						<b-col cols="10">
							<template v-if="state_data.treeselectdialogs">
								<treeselectdialogs
									:dpt_selected_array="bind_data.dpt_selected"
									:multiple="true"
									:branch_nodes="false"
									@save="get_dpt_info_add($event)"></treeselectdialogs>
							</template>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col class="text-end" cols="2">状態</b-col>
						<b-col cols="10">
							<b-form-select v-model="bind_data.state_class" :options="bind_data.state_options">
								<b-form-select-option :value="null">すべて</b-form-select-option>
							</b-form-select>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col class="text-end" cols="2">報告区分</b-col>
						<b-col cols="10">
							<b-form-select v-model="bind_data.report_class" :options="bind_data.report_options">
								<b-form-select-option :value="null">すべて</b-form-select-option>
							</b-form-select>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? 'text-end' : 'text-start'" xs="12" sm="12" md="2" lg="2" xl="2">発生日時</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- 
							<b-form-datePicker v-model="bind_data.between_edit_start_date" label-no-date-selected="検索範囲の開始日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
							class="m-0" today-button close-button ></b-form-datePicker> -->
							<VueDatePicker
								v-model="bind_data.between_edit_start_date"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の開始日を選択"
								:state="null"
								:enable-time-picker="false"
								:clearable="false"
								class="mb-2"
								week-start="0"
								required
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
								<template #action-extra="{ selectCurrentDate }">
									<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
								</template>
							</VueDatePicker>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!--
							<b-form-timepicker v-model="bind_data.between_edit_start_time" label-no-time-selected="時刻を選択" 
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="bind_data.between_edit_start_time"
								model-type="HH:mm:ss"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="null"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="false"
								class="m-0"
								auto-apply
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col class="text-start" xs="12" sm="12" md="2" lg="2" xl="2"></b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-datepicker v-model="bind_data.between_edit_end_date" locale="ja" label-no-date-selected="検索範囲の終了日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
							class="m-0" today-button close-button ></b-form-datepicker> -->
							<VueDatePicker
								v-model="bind_data.between_edit_end_date"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の終了日を選択"
								:state="null"
								:enable-time-picker="false"
								:clearable="false"
								class="mb-2"
								week-start="0"
								required
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
								<template #action-extra="{ selectCurrentDate }">
									<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
								</template>
								<!-- auto-apply すると下部のメニューが表示されなくなるので右記の指定は無意味になる :action-row="{ showSelect: true, showCancel: true, showNow: true, showPreview: true  }" now-button-label="今日" cancel-text="閉じる" -->
							</VueDatePicker>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!--
							<b-form-timepicker v-model="bind_data.between_edit_end_time" locale="ja" label-no-time-selected="時刻を選択" 
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="bind_data.between_edit_end_time"
								model-type="HH:mm:ss"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="null"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="false"
								class="m-0"
								auto-apply
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
								<!-- <template #action-extra="{ selectCurrentDate }">
								<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">現在時刻</b-button>
							</template> -->
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col class="text-end" cols="2">集計方法</b-col>
						<b-col cols="10">
							<!-- <b-form-group v-slot="{ ariaDescribedby }"> -->
							<b-form-group v-slot="ariaDescribedby">
								<b-form-radio-group
									id="radio-group-2"
									v-model="bind_data.summary_method"
									:aria-describedby="ariaDescribedby"
									name="radio-sub-component">
									<b-form-radio value="owner">オーナー毎</b-form-radio>
									<b-form-radio value="facility" v-on:click="owner_select()">施設毎</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="mt-0" v-show="state_owner_selected">
						<b-col class="text-end" cols="2">オーナー</b-col>
						<b-col cols="10">
							<b-form-select v-model="bind_data.owner_selected" :options="bind_data.owner_options">
								<b-form-select-option :value="null">すべて</b-form-select-option>
							</b-form-select>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col>
							<b-button variant="outline-secondary" size="sm" class="ms-2 float-end" v-on:click="search()">検索</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<div class="mt-4 text-end">
				発生日時 {{ search_datetime_from }} – {{ search_datetime_to }}<span v-if="local_data.state_between_init">（24時間）</span>
			</div>

			<!-- 表形式 -->
			<div class="mb-4">
				<b-table
					:bordered="true"
					:items="bind_data.lists"
					:fields="local_data.fields"
					thead-class="text-secondary text-start"
					tbody-tr-class="h1 text-secondary text-end">
					<template #cell(total)="data">
						<a href="#nogo" @click="jumpToReportData(data)">{{ data.value }}</a>
					</template>
					<template #cell(emergency)="data">
						<a href="#nogo" @click="jumpToReportData(data)">{{ data.value }}</a>
					</template>
					<template #cell(correspond)="data">
						<a href="#nogo" @click="jumpToReportData(data)">{{ data.value }}</a>
					</template>
					<template #cell(normal)="data">
						<a href="#nogo" @click="jumpToReportData(data)">{{ data.value }}</a>
					</template>
				</b-table>
			</div>
		</div>

		<b-modal ref="between_date_edit" title="発生日時を検索" @ok="between_date_ok()">
			<b-row>
				<b-col cols="2" class="text-end pr-0">開始日時</b-col>
				<b-col cols="5" class="pr-0">
					<!-- <b-form-datepicker v-model="bind_data.between_edit_start_date" locale="ja" label-no-date-selected="日付を選択"
					:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
					class="m-0"></b-form-datepicker> -->
					<VueDatePicker
						v-model="bind_data.between_edit_start_date"
						model-type="yyyy-MM-dd"
						locale="ja"
						:format="date_format"
						placeholder="日付を選択"
						:state="null"
						:enable-time-picker="false"
						:clearable="false"
						class="mb-2"
						week-start="0"
						required
						auto-apply>
						<template #input-icon>
							<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
						</template>
					</VueDatePicker>
				</b-col>
				<b-col cols="5" class="pl-0">
					<!-- <b-form-timepicker v-model="bind_data.between_edit_start_time" locale="ja" label-no-time-selected="時刻を選択"
					class="m-0"></b-form-timepicker> -->
					<VueDatePicker
						v-model="bind_data.between_edit_start_time"
						model-type="HH:mm"
						locale="ja"
						format="HH:mm"
						placeholder="時刻を選択"
						:state="null"
						time-picker
						:config="{ modeHeight: 150 }"
						:clearable="false"
						class="m-0"
						auto-apply>
						<template #input-icon>
							<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
						</template>
					</VueDatePicker>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2" class="text-end pr-0">終了日時</b-col>
				<b-col cols="5" class="pr-0">
					<!-- <b-form-datepicker v-model="bind_data.between_edit_end_date" locale="ja" label-no-date-selected="終了日を選択してください"
					:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
					class="m-0"></b-form-datepicker> -->
					<VueDatePicker
						v-model="bind_data.between_edit_end_date"
						model-type="yyyy-MM-dd"
						locale="ja"
						:format="date_format"
						placeholder="検索範囲の終了日を選択"
						:state="null"
						:enable-time-picker="false"
						:clearable="false"
						class="mb-2"
						week-start="0"
						required
						auto-apply>
						<template #input-icon>
							<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
						</template>
					</VueDatePicker>
				</b-col>
				<b-col cols="5" class="pl-0">
					<!-- <b-form-timepicker v-model="bind_data.between_edit_end_time" locale="ja" label-no-time-selected="時刻を選択"
					class="m-0"></b-form-timepicker> -->
					<VueDatePicker
						v-model="bind_data.between_edit_end_time"
						model-type="HH:mm"
						locale="ja"
						format="HH:mm"
						placeholder="時刻を選択"
						:state="null"
						time-picker
						:config="{ modeHeight: 150 }"
						:clearable="false"
						class="m-0"
						auto-apply>
						<template #input-icon>
							<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
						</template>
					</VueDatePicker>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import treeselectdialogs from "@/components/TreeSelectDialogs.vue";
import {
	BAlert,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
} from "bootstrap-vue-next";

export default {
	name: "Dashboard", // ココを更新
	components: {
		Loading,
		treeselectdialogs,
		BAlert,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// 検索条件表示トグル
				search_toggle: false,
				search_toggle_rotate: "0",
				state_class: null,
				state_options: [],
				report_class: null,
				report_options: [],
				summary_method: "owner",
				owner_selected: null,
				owner_options: [],
				// dpt_options: [],
				dpt_selected: [],
				//現在日付
				between_date_from: this.$dayjs().add(-1, "day").format("YYYY/MM/DD HH:mm:ss"),
				between_date_to: this.$dayjs().format("YYYY/MM/DD HH:mm:ss"),
				between_init: true,
				between_edit_start_date: this.$dayjs().add(-1, "day").format("YYYY-MM-DD"),
				between_edit_start_time: this.$dayjs().add(-1, "day").format("HH:mm:00"),
				between_edit_end_date: this.$dayjs().format("YYYY-MM-DD"),
				between_edit_end_time: this.$dayjs().format("HH:mm:59"),
				// 事案件数
				lists: [],
			},
			// ローカルデータ
			local_data: {
				fields: [
					{ key: "cmp_name", label: "オーナー", class: "text-nowrap text-start" },
					{ key: "total", label: "総報告件数", class: "text-nowrap" },
					{ key: "emergency", label: "緊急報告数", class: "text-nowrap" },
					{ key: "correspond", label: "緊急未完了数", class: "text-nowrap" },
					{ key: "normal", label: "通常報告数", class: "text-nowrap" },
				],
				state_between_date_init: true,
			},
			state_data: {
				treeselectdialogs: true,
			},
		};
	},

	// 既定計算
	computed: {
		state_owner_selected() {
			return this.bind_data.summary_method == "owner" ? false : true;
		},

		search_datetime_from() {
			return this.bind_data.between_date_from.substr(0, 16).replace(/-/g, "/");
		},

		search_datetime_to() {
			return this.bind_data.between_date_to.substr(0, 16).replace(/-/g, "/");
		},
	},

	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "ダッシュボード" );
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;

		await this.data_set(this.bind_data.between_date_from, this.bind_data.between_date_to, this.bind_data.summary_method);

		try {
			const config = {
				params: {
					sid: this.$store.state.sid,
					cmp_type: "owner",
				},
			};
			const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST, config);

			if (res.data.result === "OK") {
				for (let i = 0; i < res.data.company_list.length; i++) {
					this.bind_data.owner_options.push({
						text: res.data.company_list[i].cmp_name,
						value: res.data.company_list[i].cmp_id,
					});
				}

				this.$store.dispatch("commit_set_sid", res.data.sid);
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		} catch (e) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify(e);
		}

		this.state_data.treeselectdialogs = false;
		await this.set_org_map("allslf", null, null);
		this.state_data.treeselectdialogs = true;

		// const ret = await localStorage.getItem('organization_info');
		// const orgs = JSON.parse(ret);
		// for (let i=0 ; i<orgs.length ; i++) {
		//	this.bind_data.dpt_options.push({
		//		id: orgs[i].org_uuid,
		//		value: orgs[i].org_uuid,
		//		label: orgs[i].org_name,
		//	});
		// }

		const states = await this.get_item_list("dashboardstate");

		for (let i = 0; i < states.length; i++) {
			this.bind_data.state_options.push({
				text: states[i].itm_name,
				value: states[i].itm_item_key,
			});
		}

		const reports = await this.get_item_list("dashboardreport");

		for (let i = 0; i < reports.length; i++) {
			this.bind_data.report_options.push({
				text: reports[i].itm_name,
				value: reports[i].itm_item_key,
			});
		}

		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		// 検索条件トグル
		search_toggle() {
			if (this.bind_data.search_toggle) {
				this.bind_data.search_toggle_rotate = "0";
			} else {
				this.bind_data.search_toggle_rotate = "180";
			}

			this.bind_data.search_toggle = !this.bind_data.search_toggle;
		},

		toggle_between_date_init() {
			this.$refs["between_date_edit"].show();
		},

		async between_date_ok() {
			this.loader.is_loading = true;

			const startDatetime = `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`;
			const endDatetime = `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`;

			await this.data_set(startDatetime, endDatetime);

			this.loader.is_loading = false;
		},

		// APIで取得したデータをセットする部分（仮）
		async data_set(
			startDatetime = null,
			endDatetime = null,
			aggregationMethod = "owner",
			compType = null,
			rptType = null,
			orgUuid = null,
			ownerUuid = null
		) {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				if (startDatetime) {
					this.bind_data.between_date_from = startDatetime;
					config.params.bas_occur_start_date_time = startDatetime;
				}

				if (endDatetime) {
					this.bind_data.between_date_to = endDatetime;
					config.params.bas_occur_end_date_time = endDatetime;
				}

				if (aggregationMethod) {
					config.params.aggregation_method = aggregationMethod;
				}

				if (compType) {
					config.params.bas_comp_type = compType;
				}

				if (rptType) {
					config.params.bas_rpt_type = rptType;
				}

				if (orgUuid) {
					config.params.bas_org_uuid = orgUuid;
				}

				if (ownerUuid) {
					config.params.cmp_id = ownerUuid;
				}

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_DASHBOARD_LIST, config);

				if (res.data.result === "OK") {
					if (aggregationMethod == "owner") {
						this.local_data.fields[0].key = "cmp_name";
						this.local_data.fields[0].label = "オーナー";
					} else {
						this.local_data.fields[0].key = "mng_name";
						this.local_data.fields[0].label = "施設名";
					}

					this.bind_data.lists = res.data.dashboard_list;
					console.log(JSON.stringify(this.bind_data.lists));
					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		get_dpt_info_add(selected) {
			this.bind_data.dpt_selected = selected;
		},

		async search() {
			this.loader.is_loading = true;

			//const startDatetime = `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`;
			//const endDatetime = `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`;

			//開始日時，終了日時が入力されていないは、デフォルト値を代入する
			let startYYYYMMDD = `${this.bind_data.between_edit_start_date}`;
			let startHHmmss = `${this.bind_data.between_edit_start_time}`;
			let endYYYYMMDD = `${this.bind_data.between_edit_end_date}`;
			let endHHmmss = `${this.bind_data.between_edit_end_time}`;
			if (!startHHmmss || startHHmmss.length < 8) {
				startHHmmss = "00:00:00";
			}
			if (!endHHmmss || endHHmmss.length < 8) {
				endHHmmss = "23:59:59";
			}
			if (!startYYYYMMDD || startYYYYMMDD.length < 10) {
				startYYYYMMDD = "1970-01-01";
			}
			if (!endYYYYMMDD || endYYYYMMDD.length < 10) {
				endYYYYMMDD = "2999-12-31";
			}
			let startDatetime = startYYYYMMDD + " " + startHHmmss;
			let endDatetime = endYYYYMMDD + " " + endHHmmss;

			//開始日時よりも終了日時が古い場合は、開始日時と終了日時を入れ替える
			let startYYYYMMDDHHmm = startDatetime.substring(0, 16);
			let endYYYYMMDDHHmm = endDatetime.substring(0, 16);
			if (endYYYYMMDDHHmm < startYYYYMMDDHHmm) {
				startDatetime = endYYYYMMDDHHmm + ":00";
				endDatetime = startYYYYMMDDHHmm + ":59";
			}

			const orgs = this.bind_data.dpt_selected.join(",");

			await this.data_set(
				startDatetime,
				endDatetime,
				this.bind_data.summary_method,
				this.bind_data.state_class,
				this.bind_data.report_class,
				orgs,
				this.bind_data.owner_selected
			);

			this.loader.is_loading = false;
		},

		jumpToReportData(data) {
			console.log("data=" + JSON.stringify(data));

			let param = {};
			param.dashboard_state = this.bind_data.state_class; //状態
			param.dashboard_start_date_time = this.bind_data.between_edit_start_date + " " + this.bind_data.between_edit_start_time; //発生日時
			param.dashboard_end_date_time = this.bind_data.between_edit_end_date + " " + this.bind_data.between_edit_end_time; //発生日時
			param.dashboard_status = data.field.key; //総報告件数:total //緊急報告数:emergency //緊急未完了数:correspond //通常報告数:normal
			param.dashboard_cmp_uuid = data.item.cmp_id;

			if (data.item.mng_uuid === undefined) {
				param.dashboard_mng_uuid = "";
			} else {
				param.dashboard_mng_uuid = data.item.mng_uuid;
			}

			console.log("param=" + JSON.stringify(param));
			this.$store.dispatch("commit_dashboard_to_report_data", param);
			this.transition("/Home/ReportingData");

			// this.trans_forward('/Home/ReportingData', {
			//		state: this.bind_data.state_class,
			//		startDatetime: `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`,
			//		endDatetime: `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`,
			// });
		},
	},
};
</script>
