<template>
	<div class="Reportingdata text-start">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<!-- 検索条件 -->
			<div class="mt-3">
				<b-button variant="outline-secondary" v-on:click="search_toggle()"
					>検索条件
					<BootstrapIcon icon="caret-down-fill" :rotate="bind_data.search_toggle_rotate"></BootstrapIcon>
				</b-button>
				<b-card v-show="bind_data.search_toggle" class="card-shadow border-1">
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>状態</b-col
						>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<treeselect
								v-model="local_data.search_state"
								:multiple="true"
								:disable-branch-nodes="true"
								:options="bind_data.search_state_options"
								placeholder="選択してください" />
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>管理対象施設</b-col
						>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<treeselect
								v-model="local_data.search_facility"
								:multiple="true"
								:disable-branch-nodes="true"
								:options="bind_data.search_facility_options"
								placeholder="選択してください"
								noOptionsText="選択するものがありません"
								noResultsText="結果は見つかりませんでした" />
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>発生日時</b-col
						>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-datepicker v-model="local_data.search_date" label-no-date-selected="検索範囲の開始日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
							class="m-0" :state="state_data.search_date" today-button reset-button close-button ></b-form-datepicker> -->
							<VueDatePicker
								v-model="local_data.search_date"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の開始日を選択"
								:state="state_data.search_date"
								:enable-time-picker="false"
								:clearable="true"
								class="mb-2"
								week-start="0"
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
								<template #action-extra="{ selectCurrentDate }">
									<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
								</template>
							</VueDatePicker>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-timepicker v-model="local_data.between_edit_start_time" label-no-time-selected="時刻を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" :state="state_data.between_edit_start_time" reset-button close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="local_data.between_edit_start_time"
								model-type="HH:mm"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="state_data.between_edit_start_time"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="true"
								class="m-0"
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col class="text-start" xs="12" sm="12" md="2" lg="2" xl="2"></b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-datepicker v-model="local_data.search_date_end" label-no-date-selected="検索範囲の終了日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
							class="m-0" :state="state_data.search_date_end" today-button reset-button close-button ></b-form-datepicker> -->
							<VueDatePicker
								v-model="local_data.search_date_end"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の終了日を選択"
								:state="state_data.search_date_end"
								:enable-time-picker="false"
								:clearable="true"
								class="mb-2"
								week-start="0"
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
								<template #action-extra="{ selectCurrentDate }">
									<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
								</template>
							</VueDatePicker>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-timepicker v-model="local_data.between_edit_end_time" label-no-time-selected="時刻を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" :state="state_data.between_edit_end_time" reset-button close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="local_data.between_edit_end_time"
								model-type="HH:mm"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="state_data.between_edit_end_time"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="true"
								class="m-0"
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>件名</b-col
						>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<b-form-input
								v-model="local_data.search_text"
								placeholder="件名を入力してください。（部分一致検索）"
								maxlength="100"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col>
							<b-button
								variant="outline-secondary"
								size="sm"
								class="ms-2 float-end"
								v-on:click="csv_output()"
								:disabled="state_search_button"
								>CSV出力</b-button
							>
							<b-button
								variant="outline-secondary"
								size="sm"
								class="ms-2 float-end"
								v-on:click="search()"
								:disabled="state_search_button"
								>検索</b-button
							>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<div>
				<b-row class="mt-2">
					<b-col>
						<h5 class="float-end me-2">{{ local_data.data_count_numerator }}件</h5>
						<!-- <h5 class="float-end me-2">{{ local_data.data_count_numerator }}/{{ local_data.data_count_denominator }}件</h5> -->
					</b-col>
				</b-row>
			</div>

			<div v-for="bas_item in bind_data.bas_lists" :key="bas_item.bas_uuid" class="mb-3">
				<b-card>
					<b-row>
						<b-col xs="12" sm="4" md="2" lg="2" xl="2">
							<h4>
								<template v-if="bas_item.bas_comp_type === 'complete'">
									<b-badge variant="secondary" class="p-2 badge-width">{{ bas_item.bas_comp_type_name }}</b-badge>
								</template>
								<template v-if="bas_item.bas_comp_type === 'correspond'">
									<b-badge variant="primary" class="p-2 badge-width">{{ bas_item.bas_comp_type_name }}</b-badge>
								</template>
								<template v-if="bas_item.bas_comp_type === 'lock'">
									<b-badge variant="success" class="p-2 badge-width">{{ bas_item.bas_comp_type_name }}</b-badge>
								</template>
							</h4>
						</b-col>
						<b-col xs="12" sm="8" md="6" lg="6" xl="6" class="text-start">
							<b-card-title>
								<b-badge v-if="bas_item.bas_rpt_type === 'emergency'" variant="danger" class="p-2">{{
									bas_item.bas_rpt_type_name
								}}</b-badge>
								{{ bas_item.bas_subject }}
							</b-card-title>
							<b-card-text>
								<span class="me-2"> 事案番号 : {{ bas_item.bas_case_number }} <br /> </span>
								<span class="me-2"> 発生日時 : {{ bas_item.bas_occur_date_time }} <br /> </span>
								<span v-for="bas_mng_info in bas_item.bas_mng_list" :key="bas_mng_info.bas_mng_uuid" class="me-2">
									{{ bas_mng_info.bas_mng_uuid_name }}
								</span>
								<template v-if="bas_item.bas_comp_type === 'lock'">
									<br />処置完了日時 : {{ bas_item.bas_lock_date_time }}
								</template>
								<template v-if="bas_item.bas_comp_type === 'complete'">
									<br />処置完了日時 : {{ bas_item.bas_lock_date_time }} <br />報告完了日時 :
									{{ bas_item.bas_complete_date_time }}
								</template>
							</b-card-text>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4" class="text-end">
							<template v-if="hasReportEditPerm">
								<b-button
									v-if="bas_item.bas_comp_type !== 'complete'"
									v-on:click="edit(bas_item)"
									variant="outline-secondary"
									class="m-1"
									size="sm"
									>編集</b-button
								>
							</template>
							<template v-if="hasReportBrowsingPerm">
								<b-button v-on:click="details(bas_item)" variant="outline-secondary" class="m-1" size="sm">閲覧</b-button>
							</template>
							<template v-if="hasArchiveBrowsingPerm">
								<b-button v-on:click="cabinet(bas_item)" variant="outline-secondary" class="m-1" size="sm">書庫</b-button>
							</template>
							<template v-if="hasReportDownloadPerm">
								<b-button v-on:click="download(bas_item)" variant="outline-secondary" class="m-1" size="sm"
									>ダウンロード</b-button
								>
							</template>
							<template v-if="hasReportEditPerm">
								<b-button
									v-if="bas_item.bas_comp_type !== 'complete' && bas_item.bas_comp_type !== 'lock'"
									v-on:click="handleConfirmBreakingNews(bas_item)"
									variant="outline-primary"
									class="m-1"
									size="sm"
									>速報データ作成</b-button
								>
							</template>
							<template v-if="hasReportEditCompletedPerm">
								<b-button
									v-if="bas_item.bas_comp_type === 'complete'"
									variant="outline-danger"
									class="m-1"
									size="sm"
									@click="handleConfirmRelease(bas_item)"
									>完了解除</b-button
								>
							</template>
							<template v-if="hasReportEditCompletedPerm">
								<b-button
									v-if="bas_item.bas_comp_type === 'lock'"
									variant="outline-danger"
									class="m-1"
									size="sm"
									@click="handleConfirmCompelete(bas_item)"
									>報告完了</b-button
								>
							</template>
							<template v-if="hasAllSysPerm">
								<br /><b-button variant="danger" class="m-1" size="sm" @click="handleConfirmDelete(bas_item)">削除</b-button>
							</template>
							<template v-if="bas_item.consult_state.indexOf('consult_receive') > -1">
								<div class="text-primary small">
									相談依頼された報告データがあります。<br />［編集］で次画面に遷移し確認してください。
								</div>
							</template>
							<template v-if="bas_item.consult_state.indexOf('consult_send') > -1">
								<div class="text-primary small">相談中の報告データがあります。</div>
							</template>
							<template v-if="bas_item.consult_state.indexOf('answer_receive') > -1">
								<div class="text-primary small">
									回答あり（未配信）の報告データがあります。<br />［編集］で次画面に遷移し確認してください。
								</div>
							</template>
							<template v-if="bas_item.consult_state.indexOf('answer_send') > -1">
								<div class="text-primary small">回答済み（未配信）の報告データがあります。</div>
							</template>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</div>
		<b-modal ref="confirmBreakingNews" title="速報データ作成" @ok="handleBreakingNews()"
			>速報データを作成します。よろしいですか？</b-modal
		>
		<b-modal ref="confirmRelease" title="報告完了解除" @ok="handleRelease()">報告書の完了を解除します。よろしいですか？</b-modal>
		<b-modal ref="confirmComplete" title="報告編集完了" @ok="handleComplete()"
			>報告書の編集を完了にします。よろしいですか？</b-modal
		>
		<b-modal size="lg" ref="confirmFormSelect" title="フォーム選択" :ok-disabled="!isFormSelected" @ok="handleSelectForm()">
			出力対象の報告データに対して複数のフォームが存在します。<br />
			一覧より出力するフォームを選択してから出力してください。
			<div class="mt-3 font-weight-bold">フォーム一覧</div>
			<b-table hover :items="local_data.form_info" :fields="local_data.form_info_fields">
				<!-- ラジオボタン -->
				<template #cell(app_cmd)="row">
					<b-form-radio v-model="bind_data.selected_form" name="select_form" :value="row.item.frm_id"></b-form-radio>
				</template>

				<template #table-colgroup="scope">
					<col
						v-for="field in scope.fields"
						:key="field.key"
						:style="{ width: field.key == 'app_cmd' ? '50px' : field.key == 'frm_upd_date_time' ? '190px' : '' }" />
					<!-- <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key == 'frm_upd_date_time' ? '180px' : '' }"> -->
				</template>
			</b-table>
		</b-modal>
		<b-modal ref="confirmDelete" title="事案削除" @ok="handleConfirmDelete2()" ok-variant="danger" ok-title="削除">
			事案を削除します。よろしいですか？<br />
			以下の情報が一緒に削除されます。<br />
			・タイムライン<br />
			・トークルーム<br />
			・報告データ<br />
			・書庫
		</b-modal>
		<b-modal ref="confirmDelete2" title="事案削除" @ok="handleDelete()" ok-variant="danger" ok-title="削除"
			>削除したデータは復元できません。よろしいですか？</b-modal
		>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import perm from "@/lib/perm.js";
import {
	BAlert,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BRow,
	BCol,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
	BFormRadio,
} from "bootstrap-vue-next";

export default {
	name: "ReportingData", // ココを更新
	components: {
		Loading,
		//		Treeselect,
		BAlert,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BRow,
		BCol,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
		BFormRadio,
	},

	props: {
		startDatetime: String,
		endDatetime: String,
		state: String,
	},

	// 共通関数
	mixins: [common, perm],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// 検索条件表示トグル
				search_toggle: false,
				search_toggle_rotate: "0",
				// 検索条件:状態
				search_state_options: [
					{ label: "対応中", value: "correspond", id: "correspond" },
					{ label: "処置完了", value: "lock", id: "lock" },
					{ label: "報告完了", value: "complete", id: "complete" },
				],
				// 検索条件:管理対象施設
				search_facility_options: [],
				// 事案リスト
				bas_lists: [],

				// 表示件数select
				disp_selected: 10,
				disp_options: [
					{ value: 5, text: "5" },
					{ value: 10, text: "10" },
					{ value: 50, text: "50" },
					{ value: 100, text: "100" },
				],
				// ボタン種類
				btm_type: "",
				// 現在件数
				Page: "1-10",
				// 総数
				Count: "235",
				selected_form: "",
			},
			// ローカルデータ
			local_data: {
				// 検索条件：状態
				search_state: [],
				// 検索条件：管理対象施設
				search_facility: [],
				// 検索条件：日付
				search_date: "",
				between_edit_start_time: null,
				search_date_end: null,
				between_edit_end_time: null,
				// 検索テキスト
				search_text: "",
				releaseBas: null,
				//報告データ件数の分子・分母
				data_count_numerator: 0,
				data_count_denominator: 0,
				//完了解除する報告データのuuid
				bas_uuid: "",
				form_info_fields: [
					{ key: "app_cmd", label: "" },
					{ key: "frm_explanation", label: "付記情報" },
					{ key: "frm_upd_date_time", label: "更新日時", class: "text-nowrap" },
				],
				search_info: null,
			},
			state_data: {
				search_date: null,
				between_edit_start_time: null,
				search_date_end: null,
				between_edit_end_time: null,
			},
			side_menu: null,
		};
	},

	// 既定計算
	computed: {
		state_search_button() {
			return !this.validSearch();
		},

		isFormSelected() {
			return this.bind_data.selected_form.length == 0 ? false : true;
		},
	},

	// インスタンス初期化後
	created() {

		// state からパラメータ取得（サイドメニューからの遷移時に"sidemenu"が設定される）
		this.side_menu = history.state.sidemenu;

		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "報告データ管理" );
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		await this.$nextTick();

		if (this.state) {
			this.local_data.search_state.push(this.state);
		}

		if (this.startDatetime) {
			const sdt = this.startDatetime.split(" ");

			this.local_data.search_date = sdt[0];
			this.local_data.between_edit_start_time = sdt[1];
		}

		if (this.endDatetime) {
			const edt = this.endDatetime.split(" ");

			this.local_data.search_date_end = edt[0];
			this.local_data.between_edit_end_time = edt[1];
		}

		await this.date_set("complete,correspond,lock");

		this.loader.is_loading = false;

		await this.getFacility();
	},

	// ローカル関数
	methods: {
		// 検索条件トグル
		search_toggle() {
			if (this.bind_data.search_toggle) {
				this.bind_data.search_toggle_rotate = "0";
			} else {
				this.bind_data.search_toggle_rotate = "180";
			}

			this.bind_data.search_toggle = !this.bind_data.search_toggle;
		},

		async date_set(state = null, facility = null, startDatetime = null, endDatetime = null, subject = null) {
			try {
				const param = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				if (this.$store.state.dashboard_to_report_data == null) {
					// ダッシュボードからの連携出でない時は、画面から検索条件を取得する

					if (state) {
						param.params.bas_comp_type = state;
					}

					if (facility) {
						param.params.bas_mng_uuid = facility;
					}

					if (startDatetime) {
						param.params.bas_occur_start_date_time = startDatetime;
					}

					if (endDatetime) {
						param.params.bas_occur_end_date_time = endDatetime;
					}

					if (subject) {
						param.params.bas_subject = subject;
					}
				} else {
					// ダッシュボードからの連携の時は、画面から検索条件を取得する

					//検索条件
					param.params.bas_occur_start_date_time = this.$store.state.dashboard_to_report_data.dashboard_start_date_time;
					param.params.bas_occur_end_date_time = this.$store.state.dashboard_to_report_data.dashboard_end_date_time;
					param.params.dashboard_state = this.$store.state.dashboard_to_report_data.dashboard_state; //状態
					//dashboard_status 総報告数:all /緊急報告数:emergency /緊急未完了数:correspond /通常報告数:normal
					param.params.dashboard_status = this.$store.state.dashboard_to_report_data.dashboard_status;
					//dashboard_cmp_uuid 管理対象施設のオーナーの会社コード
					param.params.dashboard_cmp_uuid = this.$store.state.dashboard_to_report_data.dashboard_cmp_uuid;
					//dashboard_mng_uuid 管理対象施設のコード
					param.params.dashboard_mng_uuid = this.$store.state.dashboard_to_report_data.dashboard_mng_uuid;
				}

				// サイドメニューからの遷移
				if (this.side_menu) {
					// 検索の初回を示す
					param.params.is_first_search = "on";
					// 検索ボタン押下時もサイドメニューからの遷移と認識するのでクリアする
					this.side_menu = null;
				}

				const res = await axios.get(import.meta.env.VITE_RESTAPI_TOP_GET_WEB_BASICS_LIST, param);

				if (res.data.result === "OK") {
					this.bind_data.bas_lists = res.data.basics_list;
					this.$store.dispatch("commit_set_sid", res.data.sid);
					this.$store.dispatch("commit_dashboard_to_report_data", null);

					if (res.data.message != "" && res.data.message != null) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}

					this.local_data.data_count_numerator = res.data.basics_list.length;
					this.local_data.data_count_denominator = res.data.basics_list_count;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		download(bas_item) {
			// 選択された報告をストアに保管する
			this.$store.dispatch("commit_set_selected_bas_info", bas_item); // グローバル変数（store）に格納

			//画面遷移
			this.transition("/Home/DownLoadSelect");
		},

		edit(bas_item) {
			// 選択された報告をストアに保管する
			this.$store.dispatch("commit_set_selected_bas_info", bas_item); // グローバル変数（store）に格納

			//画面遷移
			this.transition("/Home/CaseDetailsSelect");
		},

		details(bas_item) {
			// 選択された報告をストアに保管する
			this.$store.dispatch("commit_set_selected_bas_info", bas_item); // グローバル変数（store）に格納

			//画面遷移
			this.transition("/Home/CaseDetailsViewSelect");
		},

		cabinet(bas_item) {
			console.log(JSON.stringify(bas_item));

			// 選択された報告をストアに保管する
			this.$store.dispatch("commit_set_selected_bas_info", bas_item); // グローバル変数（store）に格納

			//画面遷移（遷移元をセットして画面遷移する）
			this.trans_forward("/Home/CabinetManage");
		},

		/**
		 * 検索条件チェック
		 * 発生日時はセットで指定されないとエラーにする
		 */
		validSearch() {
			this.state_data.between_edit_start_time = null;
			this.state_data.search_date = null;
			this.state_data.between_edit_end_time = null;
			this.state_data.search_date_end = null;

			let result = true;

			if (this.local_data.search_date && !this.local_data.between_edit_start_time) {
				/* 開始日付が入力されていても、開始時刻の入力を必須としないように変更
				result = false;
				this.state_data.between_edit_start_time = false;
				 */
			} else if (!this.local_data.search_date && this.local_data.between_edit_start_time) {
				result = false;
				this.state_data.search_date = false;
			}

			if (this.local_data.search_date_end && !this.local_data.between_edit_end_time) {
				/* 終了日付が入力されていても、終了時刻の入力を必須としないように変更
				result = false;
				this.state_data.between_edit_end_time = false;
				 */
			} else if (!this.local_data.search_date_end && this.local_data.between_edit_end_time) {
				result = false;
				this.state_data.search_date_end = false;
			}

			return result;
		},

		/**
		 * 検索
		 */
		async search() {
			//エラーメッセージ削除
			this.message.is_alert = false;
			this.message.variant = "";
			this.message.message = "";

			if (!this.validSearch()) {
				return;
			}

			this.loader.is_loading = true;
			await this.$nextTick();

			let startDatetime = null;

			if (this.local_data.search_date && this.local_data.between_edit_start_time) {
				startDatetime = `${this.local_data.search_date} ${this.local_data.between_edit_start_time}`;
			} else if (this.local_data.search_date && !this.local_data.between_edit_start_time) {
				//開始日が入力されていて、開始時刻が未入力のとき
				if (this.local_data.search_date_end && this.local_data.search_date_end < this.local_data.search_date) {
					//終了日 ＜ 開始日のとき
					startDatetime = `${this.local_data.search_date} 23:59:59`;
				} else {
					startDatetime = `${this.local_data.search_date} 00:00:00`;
				}
			}

			let endDatetime = null;

			if (this.local_data.search_date_end && this.local_data.between_edit_end_time) {
				endDatetime = `${this.local_data.search_date_end} ${this.local_data.between_edit_end_time}`;
			} else if (this.local_data.search_date_end && !this.local_data.between_edit_end_time) {
				//終了日が入力されていて、終了時刻が未入力のとき
				if (this.local_data.search_date_end && this.local_data.search_date_end < this.local_data.search_date) {
					//終了日 ＜ 開始日のとき
					endDatetime = `${this.local_data.search_date_end} 00:00:00`;
				} else {
					endDatetime = `${this.local_data.search_date_end} 23:59:59`;
				}
			}

			if (startDatetime && 16 <= startDatetime.length && endDatetime && 16 <= endDatetime.length) {
				//開始日時よりも終了日時が古い場合は、開始日時と終了日時を入れ替える
				let startYYYYMMDDHHmm = startDatetime.substring(0, 16);
				let endYYYYMMDDHHmm = endDatetime.substring(0, 16);
				if (endYYYYMMDDHHmm < startYYYYMMDDHHmm) {
					startDatetime = endYYYYMMDDHHmm + ":00";
					endDatetime = startYYYYMMDDHHmm + ":59";
				}
			}

			let state = "complete,correspond,lock";

			if (this.local_data.search_state.length !== 0) {
				state = this.local_data.search_state.join(",");
			}

			let facility = "";

			if (this.local_data.search_facility.length !== 0) {
				facility = this.local_data.search_facility.join(",");
			}

			this.loader.is_loading = true;

			await this.date_set(state, facility, startDatetime, endDatetime, this.local_data.search_text);

			this.loader.is_loading = false;
		},

		/**
		 * 報告書の完了を解除するのを確認する
		 */
		handleConfirmRelease(bas) {
			this.local_data.releaseBas = bas;
			this.$refs["confirmRelease"].show();
		},

		/**
		 * 報告書の完了を解除する
		 */
		async handleRelease() {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				const data = {
					sid: this.$store.state.sid,
					bas_uuid: this.local_data.releaseBas.bas_uuid,
					fbs_form_comp_type: "release",
				};

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_FORM_BASICS_MODE, data);

				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);
					await this.search();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		/**
		 * 報告書の完了を確認する
		 */
		handleConfirmCompelete(bas) {
			console.log(bas);

			this.local_data.bas_uuid = bas.bas_uuid;
			this.$refs["confirmComplete"].show();
		},

		/**
		 * 報告書を完了にする
		 */
		async handleComplete() {
			try {
				const data = {
					sid: this.$store.state.sid,
					bas_uuid: this.local_data.bas_uuid,
					fbs_form_comp_type: "complete",
				};

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_FORM_BASICS_MODE, data);

				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);
					await this.search();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * 速報データ作成
		 */
		handleConfirmBreakingNews(bas) {
			this.local_data.releaseBas = bas;
			this.$refs["confirmBreakingNews"].show();
		},

		async handleBreakingNews() {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				const data = {
					sid: this.$store.state.sid,
					bas_uuid: this.local_data.releaseBas.bas_uuid,
					breaking_news: "yes",
				};

				console.log(import.meta.env.VITE_RESTAPI_SET_TIMELINE_COMPLETION2, data);

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_TIMELINE_COMPLETION2, data);

				console.log(res);

				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);
					await this.search();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		/**
		 * CSVs出力
		 */
		async csv_output(formId = null) {
			this.loader.is_loading = true;
			await this.$nextTick();

			let startDatetime = null;

			if (this.local_data.search_date && this.local_data.between_edit_start_time) {
				startDatetime = `${this.local_data.search_date} ${this.local_data.between_edit_start_time}`;
			}

			let endDatetime = null;

			if (this.local_data.search_date_end && this.local_data.between_edit_end_time) {
				endDatetime = `${this.local_data.search_date_end} ${this.local_data.between_edit_end_time}`;
			}

			let state = "complete,correspond,lock";

			if (this.local_data.search_state.length !== 0) {
				state = this.local_data.search_state.join(",");
			}

			let facility = "";

			if (this.local_data.search_facility.length !== 0) {
				facility = this.local_data.search_facility.join(",");
			}

			let subject = "";

			if (this.local_data.search_text.length !== 0) {
				subject = this.local_data.search_text;
			}

			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			try {
				const param = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				if (state) {
					param.params.bas_comp_type = state;
				}

				if (facility) {
					param.params.bas_mng_uuid = facility;
				}

				if (startDatetime) {
					param.params.bas_occur_start_date_time = startDatetime;
				}

				if (endDatetime) {
					param.params.bas_occur_end_date_time = endDatetime;
				}

				if (subject) {
					param.params.bas_subject = subject;
				}

				if (formId) {
					param.params.bas_frm_uuid = formId;
				}

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_BASICS_LIST_CSV, param);

				if (res.data.result === "OK") {
					if (res.data.form_info) {
						this.local_data.form_info = res.data.form_info;
						this.local_data.search_info = res.data.search_info;
						this.bind_data.selected_form = "";
						this.$refs["confirmFormSelect"].show();
					} else {
						let rows = [];

						for (let i = 0; i < res.data.basics_list.length; i++) {
							let row = res.data.basics_list[i];
							let cols = [];

							cols.push(row.bas_case_number); // 事案番号
							cols.push(row.bas_rpt_type_name); // 報告種別
							cols.push(row.bas_subject); // 件名
							cols.push(row.bas_occur_date_time); // 発生日時
							cols.push(row.bas_org_uuid_name); // 組織
							cols.push(row.bas_mng_uuid_name); // 管理対象

							// 入力要素
							for (let j = 1; j < 1000; j++) {
								if (row["bas_item_no_" + String(j)] == undefined) {
									break;
								}

								cols.push(row["bas_item_no_" + String(j)]);
							}

							cols.push(row.tml_rpt_date_time); // タイムライン報告日時
							cols.push(row.tml_status_explain); // タイムライン状況（説明）
							cols.push(row.usr_name); // タイムライン報告者
							cols.push(row.bas_comp_type_name); // 完了種別
							cols.push(row.bas_lock_date_time); // 処置完了日時
							cols.push(row.bas_complete_date_time); // 報告完了日時
							cols.push(row.bas_upd_date_time); // 基本情報最新更新日時
							cols.push(row.tml_upd_date_time); // タイムライン最新更新日時

							let _archiveList = row.archive_list; // 書庫リスト

							for (let _idx = 0; _idx < _archiveList.length; _idx++) {
								let _acvInfo = _archiveList[_idx];

								cols.push(_acvInfo.url);
								cols.push(_acvInfo.regist_datetime);
							}

							rows.push('"' + cols.join('","') + '"');
						}

						let link = document.createElement("a");

						link.download = "報告データ管理.csv";

						let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });

						link.href = window.URL.createObjectURL(blob);
						document.body.appendChild(link);
						link.click();
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		//管理対象施設のリストを取得
		async getFacility() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_MANAGED_PULLDOWN_LIST, config);

				if (res.data.result === "OK") {
					const managed = res.data.managed_list;
					this.bind_data.search_facility_options = [];

					for (let i = 0; i < managed.length; i++) {
						this.bind_data.search_facility_options.push({
							label: managed[i].mng_name,
							value: managed[i].mng_uuid,
							id: managed[i].mng_uuid,
						});
					}

					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		handleSelectForm() {
			this.csv_output(this.bind_data.selected_form);
		},

		/**
		 * 事案の削除を確認する
		 */
		handleConfirmDelete(bas) {
			console.log(bas);

			this.local_data.bas_uuid = bas.bas_uuid;
			this.$refs["confirmDelete"].show();
		},

		/**
		 * 事案の削除を再確認する
		 */
		handleConfirmDelete2() {
			this.$refs["confirmDelete2"].show();
		},

		/**
		 * 事案を削除する
		 */
		async handleDelete() {
			this.loader.is_loading = true;

			try {
				const data = {
					sid: this.$store.state.sid,
					bas_uuid: this.local_data.bas_uuid,
				};

				const res = await axios.post(import.meta.env.VITE_RESTAPI_DEL_BASICS, data);

				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);
					await this.search();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			} finally {
				this.loader.is_loading = false;
			}
		},
	},
};
</script>
