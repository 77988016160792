// ----------------------------------------------------------------
// 共通関数
// dataset.js Ver 1.0.1
// 共通的な情報をAPIから取得し、ストアする
// ----------------------------------------------------------------
export default {
	methods: {
		data_get_dpt_info() {
			let dpt_info_json = localStorage.getItem("dpt_info_json");
			return JSON.parse(dpt_info_json);
		},
	},
};
