<template>
	<div class="CompanysManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div v-if="bind_data.access_ok">
				<div class="mt-3 ms-2">
					<b-row class="mt-2">
						<b-col xs="12" sm="2" md="2" lg="2" xl="2">
							<b-button variant="outline-secondary" v-on:click="add_row()">新規</b-button>
						</b-col>
						<b-col xs="12" sm="10" md="10" lg="10" xl="10" class="text-end">{{ local_data.user_sum }} </b-col>
					</b-row>
				</div>
				<!--新規会社-->
				<div v-if="local_data.add_data.selected">
					<b-card class="card-shadow border-1 mt-2 ms-2">
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社ID</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.add_data.cmp_id"
									type="text"
									maxlength="12"
									placeholder="会社IDを半角英小文字12文字以下で入力してください"
									:state="state_add_cmp_id"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.add_data.cmp_name"
									maxlength="32"
									placeholder="会社名を32文字以下で入力してください"
									:state="state_add_cmp_name"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社種別</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-radio-group
									v-model="local_data.add_data.cmp_type"
									:options="local_data.type_options"
									:state="state_add_cmp_type">
								</b-form-radio-group>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">ユーザー上限数</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.add_data.cmp_max_users"
									type="number"
									placeholder="割当可能数以下で入力してください"
									:state="state_add_cmp_max_users"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">表示順位</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.add_data.cmp_sort_order"
									maxlength="8"
									placeholder="表示順位を8文字以下で入力してください"
									:state="state_add_cmp_sort_order"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col>
								<b-button @click="add_new()" variant="outline-secondary" class="ms-2 float-end" :disabled="state_add_new_button"
									>登録</b-button
								>
								<b-button variant="outline-secondary" class="float-end" v-on:click="add_cancel()">キャンセル</b-button>
							</b-col>
						</b-row>
					</b-card>
				</div>

				<!-- 検索フィールド -->
				<b-row class="mt-3">
					<b-col sm="4" lg="4">
						<b-form-select v-model="bind_data.search_cmp_id" :options="bind_data.cmp_id_options" placeholder="会社ID">
							<template #first>
								<b-form-select-option :value="null">すべて（会社）</b-form-select-option>
							</template>
						</b-form-select>
					</b-col>
					<b-col sm="3" lg="3">
						<b-form-select v-model="bind_data.search_cmp_type" :options="bind_data.cmp_type_options" placeholder="会社種別">
							<template #first>
								<b-form-select-option :value="null">すべて（会社種別）</b-form-select-option>
							</template>
						</b-form-select>
					</b-col>
					<b-col sm="3" lg="3">
						<b-input-group class="mb-2">
							<b-input-group-text>
								<BootstrapIcon icon="search"></BootstrapIcon>
							</b-input-group-text>
							<b-form-input v-model="bind_data.search_name" placeholder="会社名（部分一致）" maxlength="100"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col sm="2" lg="2">
						<div class="d-grid">
							<b-button
								block
								variant="outline-secondary"
								v-on:click="
									search_companys_list();
									users_sum();
								"
								:disabled="false"
								>検索</b-button
							>
						</div>
					</b-col>
				</b-row>

				<!-- 一覧 -->
				<b-table class="mt-3" striped hover :items="bind_data.result.list" :fields="local_data.fields">
					<template #cell(app_cmd)="row">
						<b-button class="float-end" variant="outline-secondary" size="sm" v-on:click="edit_row(row)">編集</b-button>
					</template>
					<template v-slot:row-details="row">
						<b-card class="border-1 mt-2 ms-2">
							<b-row class="mt-2">
								<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社ID</b-col>
								<b-col xs="12" sm="8" md="8" lg="9" xl="10">
									<b-form-input v-model="local_data.edit_data.cmp_id" placeholder="会社ID" disabled></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
								<b-col xs="12" sm="8" md="8" lg="9" xl="10">
									<b-form-input
										v-model="local_data.edit_data.cmp_name"
										maxlength="32"
										placeholder="会社名を32文字以下で入力してください"
										:state="state_edit_cmp_name"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">会社種別</b-col>
								<b-col xs="12" sm="8" md="8" lg="9" xl="10">
									<b-form-radio-group v-model="local_data.edit_data.cmp_type" :options="local_data.type_options">
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">ユーザー上限数</b-col>
								<b-col xs="12" sm="8" md="8" lg="9" xl="10">
									<b-form-input
										v-model="local_data.edit_data.cmp_max_users"
										type="number"
										placeholder="割当可能数以下で入力してください"
										:state="state_edit_cmp_max_users"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">表示順位</b-col>
								<b-col xs="12" sm="8" md="8" lg="9" xl="10">
									<b-form-input
										v-model="local_data.edit_data.cmp_sort_order"
										maxlength="8"
										placeholder="表示順位を8文字以下で入力してください"
										:state="state_edit_cmp_sort_order"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col>
									<b-button @click="delete_confirm()" variant="danger" class="float-start">削除</b-button>
									<b-button
										@click="update_row()"
										variant="outline-secondary"
										:disabled="state_edit_update_button"
										class="ms-2 float-end"
										>更新</b-button
									>
									<b-button variant="outline-secondary" class="float-end" v-on:click="cancel_row(row)">キャンセル</b-button>
								</b-col>
							</b-row>
						</b-card>
					</template>
				</b-table>
			</div>
		</div>

		<!-- モーダルダイアログ -->
		<b-modal
			ref="confirm-modal"
			title="削除"
			ok-title="削除"
			ok-variant="danger"
			cancel-title="キャンセル"
			cancel-variant="outline-secondary"
			@ok="delete_row()"
			@cancel="delete_cancel()">
			<p>会社ID：{{ local_data.edit_data.cmp_id }} を削除します。よろしいですか?</p>
			<!-- <template #modal-footer>
		      <b-button v-on:click="delete_cancel()" variant="outline-secondary" class="float-end">キャンセル</b-button>
		      <b-button v-on:click="delete_row()" variant="danger" class="float-end">削除</b-button>
		    </template> -->
		</b-modal>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import validator from "validator";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import {
	BAlert,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
	BFormTextarea,
} from "bootstrap-vue-next";

export default {
	name: "CompanysManage", // ココを更新
	components: {
		Loading,
		BAlert,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
		BFormTextarea,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},

			// バインドデータ
			bind_data: {
				access_ok: false,
				// 検索条件
				search_name: "",
				search_cmp_id: "",
				search_cmp_type: "",
				// 検索結果
				result: {
					list: [],
				},
				// 会社ID
				cmp_id_options: [],
				// 会社種別
				cmp_type_options: [],
			},

			// ローカルデータ
			local_data: {
				//ユーザー数上限合計
				user_sum: "",
				//割当可能数
				assign_possible: "",
				// 選択行
				row: null,
				// 既存データ選択状態
				selected: false,
				add_data: {
					// 新規ボタン押下状態
					selected: false,
					cmp_id: "",
					cmp_name: "",
					cmp_type: "",
					cmp_max_users: "",
					cmp_sort_order: "",
				},
				edit_data: {
					app_cmd: "",
					cmp_id: "",
					cmp_name: "",
					cmp_type: "",
					cmp_max_users: "",
					cmp_sort_order: "",
				},
				fields: [
					{ key: "cmp_id", label: "会社ID", sortable: true },
					{ key: "cmp_name", label: "会社名", sortable: true },
					{ key: "cmp_type_name", label: "会社種別", sortable: true },
					{ key: "cmp_max_users", label: "ユーザー数上限", sortable: true },
					{ key: "cmp_sort_order", label: "表示順位", sortable: true },
					{ key: "app_cmd", label: "", class: "text-nowrap" },
				],
				//会社種別
				type_options: [],
			},
		};
	},

	// 既定計算
	computed: {
		state_search_button() {
			if (!validator.isEmpty(this.local_data.cmp_name)) {
				return false;
			} else {
				return true;
			}
		},

		state_add_cmp_id() {
			let regex = RegExp(/^[a-z]{1,12}$/);
			return regex.test(this.local_data.add_data.cmp_id);
		},

		state_add_cmp_name() {
			return !validator.isEmpty(this.local_data.add_data.cmp_name);
		},

		state_add_cmp_type() {
			return this.local_data.add_data.cmp_type != "" && this.local_data.add_data.cmp_type != null;
		},

		state_add_cmp_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return regex.test(this.local_data.add_data.cmp_sort_order);
		},

		state_add_cmp_max_users() {
			if (
				this.local_data.add_data.cmp_max_users < 0 ||
				this.local_data.add_data.cmp_max_users > this.local_data.assign_possible
			) {
				return false;
			} else {
				return true;
			}
		},

		state_add_new_button() {
			if (
				this.state_add_cmp_id &&
				this.state_add_cmp_name &&
				this.local_data.add_data.cmp_type &&
				this.state_add_cmp_sort_order &&
				this.state_add_cmp_max_users
			) {
				return false;
			} else {
				return true;
			}
		},

		state_edit_cmp_name() {
			return !validator.isEmpty(this.local_data.edit_data.cmp_name);
		},
		state_edit_cmp_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return regex.test(this.local_data.edit_data.cmp_sort_order);
		},

		state_edit_cmp_max_users() {
			if (
				this.local_data.edit_data.cmp_max_users < 0 ||
				this.local_data.edit_data.cmp_max_users > this.local_data.assign_possible
			) {
				return false;
			} else {
				return true;
			}
		},
		state_edit_update_button() {
			if (
				this.state_edit_cmp_name &&
				this.local_data.edit_data.cmp_type != "" &&
				this.state_edit_cmp_sort_order &&
				this.state_edit_cmp_max_users
			) {
				return false;
			} else {
				return true;
			}
		},
	},
	// インスタンス初期化後
	async created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "会社設定" );

		//追加、編集時の会社種別選択肢のセット
		let list = await this.get_item_list("cmptype");
		console.log(this.$route.path + ":list=" + JSON.stringify(list));
		for (let i = 0; i < list.length; i++) {
			let item = {};
			item.text = list[i].itm_name;
			item.value = list[i].itm_item_key;
			this.local_data.type_options.push(item);
		}
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
		await this.companys_pulldown_list();
		await this.companys_type_pulldown_list();
		this.bind_data.search_cmp_id = this.$store.state.users_info.usr_cmp_id; //初回はログインユーザーの会社で表示
		await this.users_sum();
		await this.search_companys_list();
	},
	// ローカル関数
	methods: {
		// 設定済ユーザー数上限合計取得
		async users_sum() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_GET_MAX_USERS_SUMMARY_INFO + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response.data));

				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.local_data.user_sum = response.data.users_number_sum;
					this.local_data.assign_possible = response.data.assign_possible;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		// 会社プルダウンリスト用一覧取得
		async companys_pulldown_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.cmp_id_options = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let options = {};
							options.text = response.data.company_list[i].cmp_name;
							options.value = response.data.company_list[i].cmp_id;
							this.bind_data.cmp_id_options.push(options);

							if (i == 0) {
								// 初期値セット
								this.bind_data.search_cmp_id = response.data.company_list[i].cmp_id;
							}
						}
					}
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 会社種別プルダウンリスト用一覧取得
		async companys_type_pulldown_list() {
			let list = await this.get_item_list("cmptype");
			console.log(this.$route.path + ":list=" + JSON.stringify(list));
			for (let i = 0; i < list.length; i++) {
				let item = {};
				item.text = list[i].itm_name;
				item.value = list[i].itm_item_key;
				this.bind_data.cmp_type_options.push(item);
			}
		},

		// 検索
		async search_companys_list() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;
			if (this.bind_data.search_cmp_id == null) {
				this.bind_data.search_cmp_id = "";
			}
			if (this.bind_data.search_cmp_type == null) {
				this.bind_data.search_cmp_type = "";
			}
			let url =
				import.meta.env.VITE_RESTAPI_GET_COMPANY_MANAGE_SEARCH_LIST +
				"?sid=" +
				this.$store.state.sid +
				"&cmp_name=" +
				this.bind_data.search_name +
				"&cmp_id=" +
				this.bind_data.search_cmp_id +
				"&cmp_type=" +
				this.bind_data.search_cmp_type;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let row = response.data.company_list[i];
							row.cmp_type_name = await this.get_item_name("cmptype", row.cmp_type);
							console.log("row=" + JSON.stringify(row));
							this.bind_data.result.list.push(row);
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "登録済の会社が存在しません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				if (this.bind_data.search_cmp_id == "") {
					this.bind_data.search_cmp_id = null;
				}
				if (this.bind_data.search_cmp_type == "") {
					this.bind_data.search_cmp_type = null;
				}

				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		// 新規登録表示
		add_row() {
			//画面初期化
			this.local_data.add_data.cmp_id = "";
			this.local_data.add_data.cmp_name = "";
			this.local_data.add_data.cmp_type = "";
			this.local_data.add_data.cmp_max_users = "";
			this.local_data.add_data.cmp_sort_order = "";

			this.local_data.add_data.selected = true;
			if (this.local_data.selected) {
				this.local_data.row.toggleDetails();
				this.local_data.selected = false;
			}
		},
		add_cancel() {
			this.local_data.add_data.selected = false;
		},

		//編集ボタン押下
		edit_row(row) {
			//他の編集が開いていたら閉じる
			if (this.local_data.row != null) {
				if (this.local_data.selected) {
					this.local_data.row.toggleDetails();
				}
			}
			//編集用に行データからコピーする
			this.local_data.edit_data.cmp_id = row.item.cmp_id;
			this.local_data.edit_data.cmp_name = row.item.cmp_name;
			this.local_data.edit_data.cmp_type = row.item.cmp_type;
			this.local_data.edit_data.cmp_max_users = row.item.cmp_max_users;
			this.local_data.edit_data.cmp_sort_order = row.item.cmp_sort_order;
			//編集が開いているフラグをON
			this.local_data.selected = true;
			row.toggleDetails();
			//行情報保管
			this.local_data.row = row;
		},

		//編集キャンセルボタン押下
		cancel_row(row) {
			//編集が開いているフラグをOFF
			this.local_data.selected = false;
			row.toggleDetails();
		},

		// 登録実行
		async add_new() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_COMPANY_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.cmp_id = this.local_data.add_data.cmp_id;
			post_data.cmp_name = this.local_data.add_data.cmp_name;
			post_data.cmp_type = this.local_data.add_data.cmp_type;
			post_data.cmp_max_users = String(this.local_data.add_data.cmp_max_users);
			post_data.cmp_sort_order = String(this.local_data.add_data.cmp_sort_order);
			try {
				let response = await axios.post(url, post_data);
				console.log(this.$route.path + ":add_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.add_data.selected = false;

					// リロード
					await this.companys_pulldown_list();
					await this.users_sum();
					this.bind_data.search_cmp_id = null;
					this.bind_data.search_cmp_type = null;
					await this.search_companys_list();

					//ローディング終了
					this.message.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 更新実行
		async update_row() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_COMPANY_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.cmp_id = this.local_data.edit_data.cmp_id;
			post_data.cmp_name = this.local_data.edit_data.cmp_name;
			post_data.cmp_type = this.local_data.edit_data.cmp_type;
			post_data.cmp_max_users = String(this.local_data.edit_data.cmp_max_users);
			post_data.cmp_sort_order = String(this.local_data.edit_data.cmp_sort_order);
			console.log(this.$route.path + ":post_data=" + JSON.stringify(post_data));
			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();

					// リロード
					await this.companys_pulldown_list();
					await this.users_sum();
					this.bind_data.search_cmp_id = null;
					this.bind_data.search_cmp_type = null;
					await this.search_companys_list();

					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 削除実行
		delete_confirm() {
			this.$refs["confirm-modal"].show();
		},
		delete_cancel() {
			this.$refs["confirm-modal"].hide();
		},
		async delete_row() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_COMPANY_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.cmp_id = this.local_data.edit_data.cmp_id;

			console.log(this.$route.path + ":del post_data=" + JSON.stringify(post_data));
			try {
				let response = await axios.delete(url, { data: post_data });
				console.log(this.$route.path + ":del_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();

					// リロード
					await this.companys_pulldown_list();
					await this.users_sum();
					this.bind_data.search_cmp_id = null;
					this.bind_data.search_cmp_type = null;
					await this.search_companys_list();

					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				this.$refs["confirm-modal"].hide();
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				this.$refs["confirm-modal"].hide();
			}
		},
	},
};
</script>
