// ----------------------------------------------------------------
// 共通関数
// auth.js Ver 1.0.1
// ----------------------------------------------------------------
export default {
	methods: {
		//
		// ath_uuid:権限を記載（1個）
		// 権限を持っているか確認する/持っている：true/持っていない：false
		//
		is_auth(ath_uuid) {
			if (this.$store.state.users_info === undefined || this.$store.state.users_info == null) {
				return false;
			}
			if (
				this.$store.state.users_info.organization_list === undefined ||
				this.$store.state.users_info.organization_list == null
			) {
				return false;
			}

			for (let i = 0; i < this.$store.state.users_info.organization_list.length; i++) {
				let item = this.$store.state.users_info.organization_list[i];
				let org_ath_uuids = item.org_ath_uuids.split(",");
				for (let j = 0; j < org_ath_uuids.length; j++) {
					if (ath_uuid == org_ath_uuids[j]) {
						return true;
					}
				}
			}
			return false;
		},
		//
		// ath_uuids:権限を記載（カンマ区切りで複数指定）
		// 権限を持っているか確認する/いずれか一つを持っている：true/持っていない：false
		//
		is_or_auth(ath_uuids) {
			let ath_uuid = ath_uuids.split(",");
			if (this.$store.state.users_info === undefined || this.$store.state.users_info == null) {
				return false;
			}
			if (
				this.$store.state.users_info.organization_list === undefined ||
				this.$store.state.users_info.organization_list == null
			) {
				return false;
			}

			for (let i = 0; i < this.$store.state.users_info.organization_list.length; i++) {
				let item = this.$store.state.users_info.organization_list[i];
				let org_ath_uuids = item.org_ath_uuids.split(",");
				if (org_ath_uuids.length == 1) {
					if (org_ath_uuids[0] == "") {
						return false;
					}
				}
				for (let j = 0; j < org_ath_uuids.length; j++) {
					for (let k = 0; k < ath_uuid.length; k++) {
						if (ath_uuid[k] == org_ath_uuids[j]) {
							return true;
						}
					}
				}
			}
			return false;
		},
	},
};
