export default {
	namespaced: true,

	state: {
		/**
		 * お知らせ
		 */
		newsInfo: {
			list: [],
			count: 0,
			latest_update: "",
			lastCheckDateTime: "",
			newArrival: false,
		},
	},

	getters: {
		isNewArrivalNews: (state) => {
			return state.newsInfo.newArrival;
		},
	},

	mutations: {
		/**
		 * お知らせ
		 */
		setNewsInfo(state, newsInfo) {
			state.newsInfo = newsInfo;
		},
		setIsNewArrivalNews(state, flg) {
			state.newsInfo.newArrival = flg;
		},
		setNewsLastCheckDateTime(state, dateTime) {
			state.newsInfo.lastCheckDateTime = dateTime;
		},
	},

	actions: {
		/**
		 * フォーム情報取得
		 */
		async getForm({ rootState }, { _this, basUuid }) {
			let params = {
				sid: rootState.sid,
			};

			if (basUuid != null && basUuid != "") {
				params.bas_uuid = basUuid;
			}

			const param = {
				params: params,
			};

			let value = {};

			try {
				const res = await _this.$http.get(import.meta.env.VITE_RESTAPI_GET_FORM_JSON_DATA, param);

				if (res.data.result === "OK") {
					value = res.data;
				} else {
					value = res.data;
				}
			} catch (err) {
				console.log(err);

				value.result = "ERR";
				value.message = "エラーが発生しました。" + JSON.stringify(err);
			}

			return value;
		},

		async getNewsList({ commit, state, rootState }, { _this }) {
			let value;
			let param = {
				params: {
					sid: rootState.sid,
					org_uuid: rootState.users_info.usr_org_uuid,
				},
			};

			try {
				let newArrival = false;
				let res = await _this.$http.get(import.meta.env.VITE_RESTAPI_GET_NEWS_LIST, param);

				value = {
					result: "OK",
				};

				let lastCheckDateTime = state.newsInfo.lastCheckDateTime;

				console.log("lastCheckDateTime = ", lastCheckDateTime);

				if (res.data.news_count > 0) {
					if (!lastCheckDateTime || (lastCheckDateTime && res.data.latest_news_update > lastCheckDateTime)) {
						newArrival = true;
					}
				}

				commit("setNewsInfo", {
					list: res.data.news_list,
					count: res.data.news_count,
					latest_update: res.data.latest_news_update,
					newArrival: newArrival,
					lastCheckDateTime: lastCheckDateTime,
				});
			} catch (err) {
				value = {
					result: "NG",
					message: "サーバから情報取得できませんでした。もう一度操作してください。",
				};
			}

			return value;
		},
	},
};
