<template>
	<div class="AuthManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-row class="mt-3">
				<b-col xs="12" sm="4" md="4" lg="3" xl="2" class="text-end">会社名</b-col>
				<b-col xs="12" sm="8" md="8" lg="9" xl="10">
					<b-form-select
						v-model="local_data.cmp_uuid"
						:options="bind_data.cmp_uuid_options"
						@change="set_checkbox"
						class="mb-3"></b-form-select>
				</b-col>
			</b-row>

			<template v-if="state_data.auth_table">
				<table class="table table-striped table-bordered mt-3">
					<thead>
						<tr>
							<th></th>
							<th>権限ID</th>
							<th>権限名</th>
							<th>説明</th>
						</tr>
					</thead>
					<!-- <tbody v-model="bind_data.list" tag="tbody"> -->
					<tbody tag="tbody">
						<tr v-for="item in bind_data.list" :key="item.ath_id">
							<td>
								<b-form-checkbox v-model="item.status" value="accepted" unchecked-value="not_accepted"></b-form-checkbox>
							</td>
							<td nowrap>{{ item.ath_id }}</td>
							<td nowrap>{{ item.ath_name }}</td>
							<td>{{ item.ath_desc }}</td>
						</tr>
					</tbody>
				</table>
			</template>

			<div class="float-end">
				<b-button variant="outline-secondary" v-on:click="regist()">登録</b-button>
			</div>
			<br /><br /><br />
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import {
	BAlert,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
} from "bootstrap-vue-next";

export default {
	name: "AuthManage", // ココを更新
	components: {
		Loading,
		BAlert,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				cmp_uuid_options: [],
				list: [],
			},
			// ローカルデータ
			local_data: {
				cmp_uuid: "",
				fields: [
					{ key: "app_cmd", label: "" },
					{ key: "ath_id", label: "権限コード", class: "text-nowrap" },
					{ key: "ath_name", label: "権限名", class: "text-nowrap" },
					{ key: "ath_desc", label: "説明" },
				],
			},
			state_data: {
				auth_table: false,
			},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "権限設定" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		await this.companys_list();

		this.local_data.cmp_uuid = this.$store.state.users_info.usr_cmp_id; //初回はログインユーザーの会社で表示
		await this.get_auth_list();

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		// 会社リスト用一覧取得
		async companys_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				//console.log("response="+JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.cmp_uuid_options = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let options = {};
							options.text = response.data.company_list[i].cmp_name;
							options.value = response.data.company_list[i].cmp_id;
							this.bind_data.cmp_uuid_options.push(options);
							if (i == 0) {
								// 初期値セット
								this.local_data.cmp_uuid = response.data.company_list[i].cmp_id;
							}
						}
					}
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 権限情報を取得
		async get_auth_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_AUTH_LIST + "?sid=" + this.$store.state.sid + "&cmp_id=all";
			// let url = import.meta.env.VITE_RESTAPI_GET_AUTH_LIST+"?sid="+this.$store.state.sid+"&cmp_id="+this.local_data.cmp_uuid;
			console.log(this.$route.path + ":regist:url=" + url);

			try {
				let response = await axios.get(url);
				//console.log(this.$route.path+":response="+JSON.stringify(response));

				if (response.data.result == "OK") {
					this.bind_data.list = [];
					for (let i = 0; i < response.data.auth_list.length; i++) {
						let row = response.data.auth_list[i];
						//console.log("row="+JSON.stringify(row) );
						row.status = "not_accepted";
						this.bind_data.list.push(row);
					}

					this.set_checkbox();

					this.state_data.auth_table = true;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		set_checkbox() {
			for (let i = 0; i < this.bind_data.list.length; i++) {
				this.bind_data.list[i].status = "not_accepted";
				let cmp_uuid = this.bind_data.list[i].ath_cmp_uuids.split(",");
				for (let j = 0; j < cmp_uuid.length; j++) {
					if (cmp_uuid[j] == this.local_data.cmp_uuid) {
						this.bind_data.list[i].status = "accepted";
					}
				}
			}
		},

		async regist() {
			// ローディング開始
			this.loader.is_loading = true;

			let ath_ids = [];
			for (let i = 0; i < this.bind_data.list.length; i++) {
				if (this.bind_data.list[i].status == "accepted") {
					ath_ids.push(this.bind_data.list[i].ath_id);
				}
			}

			let ath_id = ath_ids.join(",");
			console.log("ath_id=" + ath_id);

			let url = import.meta.env.VITE_RESTAPI_SET_AUTH_COMPANY;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.cmp_id = this.local_data.cmp_uuid;
			post_data.ath_id = ath_id;

			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":add_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					await this.get_auth_list();

					this.message.is_alert = true;
					this.message.variant = "info";
					this.message.message = "成功しました。";

					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
	},
};
</script>
