<template>
	<div ref="container">
		<video ref="videoPlayer" class="video-js" preload="auto" width="640" height="380"></video>
	</div>
</template>

<style></style>

<script>
import videojs from "video.js";

export default {
	name: "VideoPlayer",
	props: {
		options: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			player: null,
		};
	},
	mounted() {
		this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
			console.log("onPlayerReady", this);
		});
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
};
</script>
