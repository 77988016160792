<template>
	<div class="BlueprintManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div class="mt-3">
				<b-button variant="outline-secondary" v-on:click="add()">新規</b-button>
			</div>
			<!-- 新規 -->
			<div v-if="local_data.add_data.selected">
				<b-card class="card-shadow border-1 mt-2">
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">図面名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.drw_name"
								placeholder="図面名"
								maxlength="100"
								state="state_add_drw_name"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2"> 管理対象</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-select v-model="local_data.add_data.mng_selected" :options="bind_data.mng_options"></b-form-select>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">図面</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-file
								v-model="local_data.add_data.drw_file"
								placeholder="画像ファイル（PNG形式もしくはJPEG形式）を選択するか、ここにファイルをドロップしてください。"
								drop-placeholder="ここにファイルをドロップしてください。"
								accept=".png,.jpg,.jpeg"
								browse-text="参照"
								state="state_add_drw_file">
							</b-form-file>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">検索用キーワード</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.drw_search_keyword"
								placeholder="検索用キーワード"
								maxlength="100"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col>
							<b-button variant="outline-secondary" class="ms-2 float-end" v-on:click="add_new()" :disabled="state_add_new_button"
								>登録</b-button
							>
							<b-button variant="outline-secondary" class="float-end" v-on:click="add_cancel()">キャンセル</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<!-- 検索フィールド -->
			<b-row class="mt-3">
				<b-col sm="5" lg="5">
					<b-form-select v-model="bind_data.search_mng_selected" :options="bind_data.mng_options"></b-form-select>
				</b-col>
				<b-col sm="5" lg="5">
					<b-input-group class="mb-2">
						<b-input-group-text>
							<BootstrapIcon icon="search"></BootstrapIcon>
						</b-input-group-text>
						<b-form-input
							v-model="bind_data.search_name"
							placeholder="図面名（部分一致）、キーワードを入力して検索してください"
							maxlength="100"></b-form-input>
					</b-input-group>
				</b-col>
				<b-col sm="2" lg="2">
					<div class="d-grid">
						<b-button block variant="outline-secondary" v-on:click="seach_list()" :disabled="false">検索</b-button>
					</div>
				</b-col>
			</b-row>

			<!-- 図面一覧 -->
			<b-table class="mt-3" striped hover :items="bind_data.list" :fields="local_data.fields">
				<!-- 編集ボタン -->
				<template #cell(app_cmd)="row">
					<b-button class="float-end" variant="outline-secondary" size="sm" v-on:click="edit_row(row)">編集</b-button>
				</template>
				<!-- 編集カード -->
				<template v-slot:row-details="row">
					<b-card class="card-shadow border-1 mt-2">
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">図面名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input v-model="local_data.edit_data.drw_name" placeholder="図面名" maxlength="100"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2"> 管理対象</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-select v-model="local_data.edit_data.mng_selected" :options="bind_data.mng_options"></b-form-select>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">図面</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-button variant="outline-secondary" size="sm" v-on:click="download()">ダウンロード</b-button
								><span class="ms-2">ファイル名：{{ local_data.edit_data.drw_file_info.file_name }} </span>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">検索用キーワード</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.drw_search_keyword"
									placeholder="検索用キーワード"
									maxlength="100"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col>
								<b-button variant="danger" class="float-start" v-on:click="delete_confirm()">削除</b-button>
								<b-button variant="outline-secondary" class="ms-2 float-end" v-on:click="update_row(row)">更新</b-button>
								<b-button variant="outline-secondary" class="float-end" v-on:click="cancel_row(row)">キャンセル</b-button>
							</b-col>
						</b-row>
					</b-card>
				</template>
			</b-table>
		</div>

		<!-- モーダルダイアログ -->
		<b-modal
			ref="confirm-modal"
			title="削除"
			ok-title="削除"
			ok-variant="danger"
			cancel-title="キャンセル"
			cancel-variant="outline-secondary"
			@ok="delete_row()"
			@cancel="delete_cancel()">
			<p>図面：{{ local_data.delete_data.drw_name }} を削除します。よろしいですか?</p>
			<!-- <template #modal-footer>
        <b-button v-on:click="delete_cancel()" variant="outline-secondary" class="float-end">キャンセル</b-button>
        <b-button v-on:click="delete_row()" variant="danger" class="float-end">削除</b-button>
      </template> -->
		</b-modal>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import validator from "validator";
import common from "@/lib/common.js";
import b64toblob from "b64-to-blob";
import {
	BAlert,
	BFormFile,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
} from "bootstrap-vue-next";

export default {
	name: "BlueprintManage", // ココを更新
	components: {
		Loading,
		BAlert,
		BFormFile,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				//図面リスト
				list: [],
				search_mng_selected: "",
				//管理対象選択肢
				mng_options: [],
				//検索キーワード
				search_name: "",
			},
			// ローカルデータ
			local_data: {
				// 図面情報/新規
				add_data: {
					selected: false,
					mng_selected: "",
					drw_name: "",
					drw_file: null,
					drw_search_keyword: "",
				},
				// 図面情報/編集
				edit_data: {
					selected: false,
					drw_uuid: "",
					mng_selected: "",
					drw_name: "",
					drw_search_keyword: "",
				},
				// 図面情報/削除
				delete_data: {
					drw_name: "",
				},
				fields: [
					{ key: "drw_name", label: "図面名", sortable: true, class: "text-nowrap" },
					{ key: "mng_name", label: "管理対象名", sortable: true, class: "text-nowrap" },
					{ key: "drw_search_keyword", label: "検索キーワード", sortable: true, class: "text-nowrap" },
					{ key: "app_cmd", label: "" },
				],

				row: null,
				selected: false,
			},
		};
	},

	// 既定計算
	computed: {
		state_add_drw_name() {
			return !validator.isEmpty(this.local_data.add_data.drw_name);
		},
		state_add_drw_file() {
			return this.local_data.add_data.drw_name != null;
		},

		state_add_new_button() {
			if (this.state_add_drw_name && this.state_add_drw_file) {
				return false;
			} else {
				return true;
			}
		},
	},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "図面設定" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		await this.get_managed_list();

		await this.get_drawing_list();

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		//管理対象のプルダウン
		async get_managed_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_MANAGED_PULLDOWN_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.mng_options = [];
					if (response.data.managed_list.length > 0) {
						for (let i = 0; i < response.data.managed_list.length; i++) {
							let item = {};
							item.text = response.data.managed_list[i].mng_name;
							item.value = response.data.managed_list[i].mng_uuid;
							this.bind_data.mng_options.push(item);
							if (i == 0) {
								this.local_data.add_data.mng_selected = response.data.managed_list[i].mng_uuid;
								this.bind_data.search_mng_selected = response.data.managed_list[i].mng_uuid;
							}
						}
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//図面一覧
		async get_drawing_list() {
			let url =
				import.meta.env.VITE_RESTAPI_GET_DRAWING_LIST +
				"?sid=" +
				this.$store.state.sid +
				"&mng_uuid=" +
				this.bind_data.search_mng_selected;
			if (this.bind_data.search_name != "") {
				url = url + "&search_keyword=" + encodeURIComponent(this.bind_data.search_name);
			}
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.list = response.data.drawing_list;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//検索ボタン
		async seach_list() {
			// ローディング開始
			this.loader.is_loading = true;

			await this.get_drawing_list();

			// ローディング終了
			this.loader.is_loading = false;
		},

		//新規ボタン
		add() {
			// 入力欄の初期化処理
			this.local_data.add_data.mng_selected = "";
			this.local_data.add_data.drw_name = "";
			this.local_data.add_data.drw_file = null;
			this.local_data.add_data.drw_search_keyword = "";

			this.local_data.add_data.selected = true;
			if (this.local_data.selected) {
				this.local_data.row.toggleDetails();
				this.local_data.selected = false;
			}
		},
		//新規/キャンセルボタン
		add_cancel() {
			this.local_data.add_data.selected = false;
		},
		//新規/登録ボタン
		async add_new() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_DRAWING_MASTER_INFO;
			let post_data = new FormData();
			post_data.append("sid", this.$store.state.sid);
			post_data.append("drw_name", this.local_data.add_data.drw_name);
			post_data.append("drw_mng_uuid", this.local_data.add_data.mng_selected);
			post_data.append("drw_search_keyword", this.local_data.add_data.drw_search_keyword);
			post_data.append("drawing_file", this.local_data.add_data.drw_file);
			console.log("add_data=" + JSON.stringify(this.local_data.add_data));
			try {
				let response = await axios.post(url, post_data);
				console.log(this.$route.path + ":add_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.add_data.selected = false;

					//初期化
					this.local_data.add_data.drw_file = null;

					// リロード
					await this.get_drawing_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//編集ボタン
		edit_row(row) {
			if (this.local_data.row != null) {
				if (this.local_data.selected) {
					this.local_data.row.toggleDetails();
				}
			}

			this.local_data.edit_data.drw_uuid = row.item.drw_uuid;
			this.local_data.edit_data.mng_selected = row.item.drw_mng_uuid;
			this.local_data.edit_data.drw_name = row.item.drw_name;
			this.local_data.edit_data.drw_search_keyword = row.item.drw_search_keyword;
			this.local_data.edit_data.drw_file_info = JSON.parse(row.item.drw_file_info);

			this.local_data.delete_data.drw_name = row.item.drw_name;

			this.local_data.selected = true;
			row.toggleDetails();
			this.local_data.row = row;
		},
		cancel_row(row) {
			this.local_data.selected = false;
			row.toggleDetails();
		},
		async update_row(row) {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_DRAWING_MASTER_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.drw_uuid = this.local_data.edit_data.drw_uuid;
			post_data.drw_name = this.local_data.edit_data.drw_name;
			post_data.drw_mng_uuid = this.local_data.edit_data.mng_selected;
			post_data.drw_search_keyword = this.local_data.edit_data.drw_search_keyword;
			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					row.toggleDetails();
					// リロード
					await this.get_drawing_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 削除実行
		delete_confirm() {
			this.$refs["confirm-modal"].show();
		},
		delete_cancel() {
			this.$refs["confirm-modal"].hide();
		},
		async delete_row() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_DRAWING_MASTER_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.drw_uuid = this.local_data.edit_data.drw_uuid;

			console.log(this.$route.path + ":del post_data=" + JSON.stringify(post_data));
			try {
				let response = await axios.delete(url, { data: post_data });
				console.log(this.$route.path + ":del_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					// リロード
					await this.get_drawing_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				this.$refs["confirm-modal"].hide();
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				this.$refs["confirm-modal"].hide();
			}
		},

		async download() {
			let url =
				import.meta.env.VITE_RESTAPI_GET_S3_MEDIA_FILE +
				"?sid=" +
				this.$store.state.sid +
				"&bucket_name=" +
				this.local_data.edit_data.drw_file_info.bucket_name +
				"&bucket_key=" +
				this.local_data.edit_data.drw_file_info.bucket_key +
				"&file_name=" +
				this.local_data.edit_data.drw_file_info.file_name;
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let contentType = this.local_data.edit_data.drw_file_info.content_type;
					let blob = b64toblob(response.data.media_file, contentType);
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = this.local_data.edit_data.drw_file_info.file_name;
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
	},
};
</script>
