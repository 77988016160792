<template>
	<div class="Login">
		<!-- ココを更新 -->
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>

		<!-- ヘッダー -->
		<Header></Header>
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>

		<!-- メインコンテンツ -->
		<div class="container" align="center">
			<!-- ログイン -->
			<b-card
				border-variant="primary"
				class="mt-3"
				header-text-variant="white"
				header-bg-variant="primary"
				header-tag="h3"
				style="max-width: 30rem">
				<div class="text-start">
					<label class="mb-0 fw-bold">会社ID</label>
				</div>
				<b-form-input
					v-model="bind_data.login_cmp_id"
					type="text"
					maxlength="32"
					placeholder="会社IDを入力してください"></b-form-input>
				<div class="text-start mt-3">
					<label class="mb-0 fw-bold">ログインID</label>
				</div>
				<b-form-input
					v-model="bind_data.login_usr_id"
					type="text"
					maxlength="32"
					placeholder="ログインIDを入力してください"></b-form-input>
				<div class="text-start mt-3">
					<label class="mb-0 fw-bold">パスワード</label>
				</div>
				<b-input-group>
					<template #append>
						<b-input-group-text v-on:click="togglePasswordVisible()">
							<font-awesome-icon v-if="state_data.password_type == 'text'" :icon="['fas', 'eye']" />
							<font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
						</b-input-group-text>
					</template>
					<b-form-input
						v-model="bind_data.login_password"
						:type="state_data.password_type"
						maxlength="32"
						required
						placeholder="パスワードを入力してください"></b-form-input>
				</b-input-group>
				<div class="d-grid"><b-button variant="primary" block v-on:click="login()" class="mt-3">ログイン</b-button></div>
			</b-card>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import dataset from "@/lib/dataset.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import Header from "@/components/Header.vue";
import { BAlert, BButton, BCard, BFormCheckbox, BInputGroup, BInputGroupText, BFormInput, BModal } from "bootstrap-vue-next";

export default {
	name: "Login", // ココを更新
	components: {
		Loading,
		Header,
		BAlert,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
	},

	// 共通関数
	mixins: [common, CommonDataSet, dataset],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				login_cmp_id: "",
				login_usr_id: "",
				login_password: "",
			},
			// ローカルデータ
			local_data: {},
			// 状態ストレージ
			state_data: {
				password_type: "password",
			},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		let login_cmp_id = this.$cookies.get("login_cmp_id");
		let login_usr_id = this.$cookies.get("login_usr_id");

		console.log(login_cmp_id, login_usr_id);

		if (login_cmp_id != null && login_cmp_id != "") {
			this.bind_data.login_cmp_id = login_cmp_id;
		}
		if (login_usr_id != null && login_usr_id != "") {
			this.bind_data.login_usr_id = login_usr_id;
		}
	},
	// ローカル関数
	methods: {
		// ログイン
		async login() {
			// ローディング開始
			this.loader.is_loading = true;

			// ログインを行い、ユーザー情報を取得（権限を含む）
			let url = import.meta.env.VITE_RESTAPI_LOGIN;
			let post_data = {};
			post_data.login_cmp_id = this.bind_data.login_cmp_id;
			post_data.login_usr_id = this.bind_data.login_usr_id;
			post_data.login_password = this.bind_data.login_password;
			console.log(this.$route.path + ":regist:url=" + url);

			try {
				let response = await axios.post(url, post_data);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					if (response.data.multi_factor_auth && response.data.multi_factor_auth == "on") {
						// 多要素認証が有効の場合

						// クッキーに保存(仮)
						this.$cookies.config("72h");
						this.$cookies.set("login_cmp_id", this.bind_data.login_cmp_id);
						this.$cookies.set("login_usr_id", this.bind_data.login_usr_id);

						//画面遷移
						this.transition("Auth", {
							login_cmp_id: this.bind_data.login_cmp_id,
							login_usr_id: this.bind_data.login_usr_id,
							login_password: this.bind_data.login_password,
						});
					} else {
						// 多要素認証が無効の場合

						// グローバル変数（store）に格納
						this.$store.dispatch("commit_set_sid", response.data.sid);

						// ユーザー情報をグローバル変数に格納
						let user_info = response.data.user_info;
						user_info.organization_list = response.data.organization_list;
						this.$store.dispatch("commit_set_users_info", user_info);
						this.$store.dispatch("commit_set_system_info", response.data.system_param);

						// システムパラメータを取得しグローバル変数に格納
						// let result1 = await this.set_system_info();
						// if( !result1 ){
						//   // ローディング終了
						//   this.loader.is_loading = false;
						//   return;
						// }

						// 区分情報を取得しグローバル変数に格納
						let result2 = await this.set_class_list();
						if (!result2) {
							// ローディング終了
							this.loader.is_loading = false;
							return;
						}

						// 組織情報を取得しローカルストレージに格納
						let result3 = await this.set_org_map();
						if (!result3) {
							// ローディング終了
							this.loader.is_loading = false;
							return;
						}

						// クッキーに保存(仮)
						this.$cookies.config("72h");
						this.$cookies.set("sid", response.data.sid);
						this.$cookies.set("login_cmp_id", this.bind_data.login_cmp_id);
						this.$cookies.set("login_usr_id", this.bind_data.login_usr_id);

						//画面遷移
						this.transition("Home");
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;

					// ローディング終了
					this.loader.is_loading = false;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
		togglePasswordVisible() {
			if ( this.state_data.password_type == 'text' ) {
				this.state_data.password_type = 'password';
			} else {
				this.state_data.password_type = 'text';
			}
		},
	},
};
</script>
