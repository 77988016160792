<template>
	<div ref="container">
		<template v-if="bind_data.form_info && bind_data.form_data">
			<div v-for="item in bind_data.form_info.edit_item_data" :key="item.edit_item_no" class="mb-4 text-left">
				<b-card header-bg-variant="light" class="mx-2">
					<!--項目名-->
					<template #header>
						<h4 class="mb-0 font-weight-bold">{{ item.edit_item_name }}</h4>
					</template>
					<!--短文テキスト（50文字）-->
					<!--長文テキスト（500文字）-->
					<!--数値-->
					<!--日付-->
					<!--時刻-->
					<!--日時-->
					<!--現在日時-->
					<!--メールアドレス-->
					<!--単一選択（ラジオボタン）-->
					<!--単一選択（プルダウン）-->
					<!--チェックボックス-->
					<template
						v-if="is_edit_item_type(item, 'text,texts,name,numeric,date,time,datetime,now,mail_address,radio,pulldown,toggle')">
						{{ get_content_text(item.edit_item_no) }}
					</template>
				</b-card>
			</div>
		</template>
	</div>
</template>

<style></style>

<script>
import { BCard } from "bootstrap-vue-next";

export default {
	name: "FormDataUtils",
	components: { BCard },
	props: {
		form_info: {
			type: Object,
			default() {
				return {};
			},
		},
		form_data: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			bind_data: {
				form_info: null,
				form_data: null,
			},
			state_data: {},
		};
	},
	mounted() {
		this.bind_data.form_info = this.form_info;
		this.bind_data.form_data = this.form_data;
	},

	// ローカル関数
	methods: {
		is_edit_item_type(item, types) {
			let type = types.split(",");
			let item_type = item.edit_item_type;
			for (let i = 0; i < type.length; i++) {
				if (item_type == type[i]) {
					return true;
				}
			}
			return false;
		},

		get_content_text(item_no) {
			for (let i = 0; i < this.bind_data.form_data.item_data.length; i++) {
				if (this.bind_data.form_data.item_data[i].item_no == item_no) {
					return this.bind_data.form_data.item_data[i].item_content;
				}
			}
			return item_no;
		},
	},
};
</script>
