<template>
	<div class="ProfileChange">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-card class="border-1 mt-2 ms-2">
				<b-row class="mt-2">
					<b-col class="text-end" cols="3">ユーザー名</b-col>
					<b-col>{{ bind_data.usr_name }}</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col class="text-end" cols="3">会社名</b-col>
					<b-col>{{ bind_data.cmp_name }}</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col class="text-end" cols="3">所属部署</b-col>
					<b-col>{{ bind_data.long_name }} </b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col class="text-end" cols="3">メールアドレス</b-col>
					<b-col>{{ bind_data.usr_mail_address }}</b-col>
				</b-row>
			</b-card>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import validator from "validator";
import axios from "axios";
import common from "@/lib/common.js";
import { BAlert, BCard, BRow, BCol } from "bootstrap-vue-next";

export default {
	name: "ProfileChange", // ココを更新
	components: {
		Loading,
		BCard,
		BRow,
		BCol,
		BAlert,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// ユーザー情報
				usr_uuid: "",
				usr_name: "",
				usr_login_id: "",
				usr_cmp_id: "",
				usr_org_uuid: "",
				usr_applying_org_uuid: "",
				usr_apply_status: "",
				usr_apply_date: "",
				usr_approval_date: "",
				usr_transfer_date: "",
				usr_mail_address: "",
				usr_password: "",
				usr_photo: "",
				usr_profile_img: null,
				usr_notice_id: "",
				cmp_name: "",
				long_name: "",
				// 会社リスト
				cmp_id_options: [
					{ label: "会社A", value: "1234" },
					{ label: "会社B", value: "2234" },
					{ label: "JR_EBT001", value: "3234" },
					{ label: "JR_EBT002", value: "4234" },
				],
			},

			// ローカルデータ
			local_data: {
				state_usr_name: true,
				state_usr_cmp: true,
			},
		};
	},

	// 既定計算
	computed: {
		state_usr_name() {
			return !validator.isEmpty(this.bind_data.usr_name);
		},
	},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "プロフィール" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		await this.set_user_data();
		await this.organization_long_name_info();
	},
	// ローカル関数
	methods: {
		link_to: function (to) {
			//console.log("link_to="+this.$route.path);
			if (this.$route.path != to) {
				// 画面遷移
				this.$router.push({ path: to });
			}
		},
		// ユーザー情報セット
		set_user_data() {
			this.bind_data.usr_uuid = this.$store.state.users_info.usr_uuid;
			this.bind_data.usr_name = this.$store.state.users_info.usr_name;
			this.bind_data.usr_login_id = this.$store.state.users_info.usr_login_id;
			this.bind_data.usr_cmp_id = this.$store.state.users_info.usr_cmp_id;
			this.bind_data.usr_org_uuid = this.$store.state.users_info.usr_org_uuid;
			this.bind_data.usr_applying_org_uuid = this.$store.state.users_info.usr_applying_org_uuid;
			this.bind_data.usr_apply_status = this.$store.state.users_info.usr_apply_status;
			this.bind_data.usr_apply_date = this.$store.state.users_info.usr_apply_date;
			this.bind_data.usr_approval_date = this.$store.state.users_info.usr_approval_date;
			this.bind_data.usr_transfer_date = this.$store.state.users_info.usr_transfer_date;
			this.bind_data.usr_mail_address = this.$store.state.users_info.usr_mail_address;
			this.bind_data.usr_password = this.$store.state.users_info.usr_password;
			this.bind_data.usr_photo = this.$store.state.users_info.usr_photo;
			this.bind_data.usr_notice_id = this.$store.state.users_info.usr_notice_id;
			this.bind_data.cmp_name = this.$store.state.users_info.cmp_name;
		},
		cmp_state_change() {
			this.local_data.state_usr_cmp = !this.local_data.state_usr_cmp;
		},
		usr_name_state_change() {
			this.local_data.state_usr_name = !this.local_data.state_usr_name;
		},

		async organization_long_name_info() {
			let url =
				import.meta.env.VITE_RESTAPI_GET_ORGANIZATION_LONG_NAME_INFO +
				"?sid=" +
				this.$store.state.sid +
				"&org_uuid=" +
				this.bind_data.usr_org_uuid.split(",")[0];
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.long_name = response.data.long_name;
					console.log(this.$route.path + ":OK=" + response.data.long_name);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
	},
};
</script>
