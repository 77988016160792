<template>
	<div ref="container">
		<template v-if="bind_data.form_info && bind_data.form_data">
			<div v-for="item in bind_data.form_info.edit_item_data" :key="item.edit_item_no" class="mb-4 text-left">
				<b-card header-bg-variant="light" class="mx-2">
					<!--項目名-->
					<template #header>
						<template v-if="item.edit_item_must == 'on'">
							<b-badge pill variant="danger" class="float-start me-2">必須</b-badge>
						</template>
						<h4 class="mb-0 font-weight-bold">{{ item.edit_item_name }}</h4>
					</template>
					<!--説明-->
					<template>
						<div class="mb-2">{{ item.edit_item_desc }}</div>
					</template>
					<!--短文テキスト（50文字）-->
					<template v-if="item.edit_item_type == 'text'">
						<b-form-input
							v-model="item.value[0]"
							:placeholder="get_text_placeholder(item)"
							@change="input_form_data"
							:state="item.state_info"
							class="m-0"
							maxlength="50"></b-form-input>
					</template>
					<!--長文テキスト（500文字）-->
					<template v-if="item.edit_item_type == 'texts'">
						<b-form-textarea
							v-model="item.value[0]"
							:placeholder="get_text_placeholder(item)"
							@change="input_form_data"
							:state="item.state_info"
							class="m-0"
							maxlength="500"></b-form-textarea>
					</template>
					<!--数値-->
					<template v-if="item.edit_item_type == 'numeric'">
						<b-form-input
							v-model="item.value[0]"
							:placeholder="get_numeric_placeholder(item)"
							@change="input_form_data"
							:state="item.state_info"
							class="m-0"></b-form-input>
					</template>
					<!--日付-->
					<template v-if="item.edit_item_type == 'date'">
						<b-row
							><b-col>
								<!-- <b-form-datepicker v-model="item.value[0]" locale="ja" label-no-date-selected="日付を選択してください"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
                @change="input_form_data" :state="item.state_info" class="m-0"></b-form-datepicker> -->
								<VueDatePicker
									v-model="item.value[0]"
									model-type="yyyy-MM-dd"
									locale="ja"
									:format="date_format"
									placeholder="日付を選択してください"
									@change="input_form_data"
									:state="item.state_info"
									:enable-time-picker="false"
									:clearable="false"
									class="mb-2"
									week-start="0"
									required>
									<template #input-icon>
										<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
									</template>
								</VueDatePicker> </b-col
						></b-row>
					</template>
					<!--時刻-->
					<template v-if="item.edit_item_type == 'time'">
						<b-row
							><b-col>
								<!-- <b-form-timepicker v-model="item.value[0]" locale="ja" label-no-time-selected="時刻を選択してください" 
            @change="input_form_data" :state="item.state_info" class="m-0"></b-form-timepicker> -->
								<VueDatePicker
									v-model="item.value[0]"
									model-type="HH:mm"
									locale="ja"
									format="HH:mm"
									placeholder="時刻を選択してください"
									@change="input_form_data"
									:state="item.state_info"
									time-picker
									:config="{ modeHeight: 150 }"
									:clearable="false"
									class="m-0">
									<template #input-icon>
										<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
									</template>
								</VueDatePicker> </b-col
						></b-row>
					</template>
					<!--日時-->
					<template v-if="item.edit_item_type == 'datetime'">
						<b-row
							><b-col>
								<!-- <b-form-datepicker v-model="item.value[0]" locale="ja" label-no-date-selected="日付を選択してください"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }"
                @change="input_form_data" :state="item.state_info" class="m-0" ></b-form-datepicker> -->
								<VueDatePicker
									v-model="item.value[0]"
									model-type="yyyy-MM-dd"
									locale="ja"
									:format="date_format"
									placeholder="日付を選択してください"
									@change="input_form_data"
									:state="item.state_info"
									:enable-time-picker="false"
									:clearable="false"
									class="mb-2"
									week-start="0"
									required>
									<template #input-icon>
										<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
									</template>
								</VueDatePicker> </b-col
							><b-col>
								<!-- <b-form-timepicker v-model="item.value[1]" locale="ja" label-no-time-selected="時刻を選択してください" 
                @change="input_form_data" :state="item.state_info" class="m-0"></b-form-timepicker> -->
								<VueDatePicker
									v-model="item.value[1]"
									model-type="HH:mm"
									locale="ja"
									format="HH:mm"
									placeholder="時刻を選択してください"
									@change="input_form_data"
									:state="item.state_info"
									time-picker
									:config="{ modeHeight: 150 }"
									:clearable="false"
									class="m-0">
									<template #input-icon>
										<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
									</template>
								</VueDatePicker> </b-col
						></b-row>
					</template>
					<!--現在日時-->
					<template v-if="item.edit_item_type == 'now'">
						{{ item.value[0] + " " + item.value[1] }}
					</template>
					<!--単一選択（ラジオボタン）-->
					<template v-if="item.edit_item_type == 'radio'">
						<b-form-group class="m-0">
							<b-form-radio-group
								v-model="item.value[0]"
								:options="item.edit_item_option.select_option"
								@change="input_form_data"
								:state="item.state_info"></b-form-radio-group>
						</b-form-group>
					</template>
					<!--単一選択（プルダウン）-->
					<template v-if="item.edit_item_type == 'pulldown'">
						<b-form-select
							v-model="item.value[0]"
							:options="item.edit_item_option.select_option"
							@change="input_form_data"
							:state="item.state_info"
							class="m-0"></b-form-select>
					</template>
					<!--チェックボックス-->
					<template v-if="item.edit_item_type == 'toggle'">
						<b-form-checkbox v-model="item.value[0]" value="該当" unchecked-value="" class="m-0" @change="input_form_data">{{
							item.edit_item_name
						}}</b-form-checkbox>
					</template>
					<!--メールアドレス-->
					<template v-if="item.edit_item_type == 'mail_address'">
						<b-form-input
							v-model="item.value[0]"
							placeholder="例）xxxxxxx@xxxxxx.co.jp"
							@change="input_form_data"
							:state="item.state_info"
							class="m-0"></b-form-input>
					</template>
				</b-card>
			</div>
		</template>
	</div>
</template>

<style></style>

<script>
// import moment from 'moment-timezone';
import validator from "validator";
import {
	BAlert,
	BButton,
	BCard,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
	vBToggle,
	BModal,
	BForm,
	BFormRadio,
	BFormRadioGroup,
	BFormCheckbox,
	BFormTextarea,
	BCol,
	BRow,
	BCollapse,
	BImg,
	BCardGroup,
	BFormFile,
	BFormInput,
	BFormGroup,
	BFormSelect,
} from "bootstrap-vue-next";
import common from "@/lib/common.js";

export default {
	name: "FormEditUtils",

	components: {
		BAlert,
		BButton,
		BCard,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
		vBToggle,
		BModal,
		BForm,
		BFormRadio,
		BFormRadioGroup,
		BFormCheckbox,
		BFormTextarea,
		BCol,
		BRow,
		BCollapse,
		BImg,
		BCardGroup,
		BFormFile,
		BFormInput,
		BFormGroup,
		BFormSelect,
	},

	mixins: [common],

	props: {
		form_info: {
			type: Object,
			default() {
				return {};
			},
		},
		form_data: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			bind_data: {
				form_info: null,
				form_data: null,
				output_data: null,
			},
			state_data: {},
		};
	},
	mounted: function () {
		this.bind_data.form_info = this.form_info;
		this.bind_data.form_data = this.form_data;
		for (let i = 0; i < this.bind_data.form_info.edit_item_data.length; i++) {
			this.bind_data.form_info.edit_item_data[i].value = ["", "", "", ""]; // <-- ここに入力されるデータを格納する
			this.bind_data.form_info.edit_item_data[i].state_info = null; // <-- ここに入力されるデータを格納する
			if (this.bind_data.form_info.edit_item_data[i].edit_item_type == "now") {
				this.bind_data.form_info.edit_item_data[i].value[0] = this.$dayjs().tz("Azia/Tokyo").format("YYYY-MM-DD");
				this.bind_data.form_info.edit_item_data[i].value[1] = this.$dayjs().tz("Azia/Tokyo").format("HH:mm");
			} else if (this.bind_data.form_info.edit_item_data[i].edit_item_type == "datetime") {
				let datetime = this.get_content_text(this.bind_data.form_info.edit_item_data[i].edit_item_no).split(" ");
				this.bind_data.form_info.edit_item_data[i].value[0] = datetime[0];
				this.bind_data.form_info.edit_item_data[i].value[1] = datetime[1];
			} else {
				this.bind_data.form_info.edit_item_data[i].value[0] = this.get_content_text(
					this.bind_data.form_info.edit_item_data[i].edit_item_no
				);
			}
		}
	},
	methods: {
		get_refs(item) {
			return "item" + item.edit_item_no;
		},

		get_numeric_placeholder(item) {
			return (
				"単位：" +
				item.edit_item_option.unit +
				"（" +
				"上限値：" +
				item.edit_item_option.upper_limit +
				"　下限値：" +
				item.edit_item_option.lower_limit +
				"）"
			);
		},

		get_text_placeholder(item) {
			return "最大" + String(item.edit_item_option.max_length) + "文字";
		},

		input_form_data() {
			this.bind_data.output_data = {};
			this.bind_data.output_data.version = this.bind_data.form_info.version;
			this.bind_data.output_data.form_name = this.bind_data.form_info.edit_form_name;
			this.bind_data.output_data.item_data = [];
			for (let i = 0; i < this.bind_data.form_info.edit_item_data.length; i++) {
				let item_data = {};
				item_data.item_no = this.bind_data.form_info.edit_item_data[i].edit_item_no;
				item_data.item_type = this.bind_data.form_info.edit_item_data[i].edit_item_type;

				if (
					item_data.item_type == "text" ||
					item_data.item_type == "texts" ||
					item_data.item_type == "date" ||
					item_data.item_type == "time" ||
					item_data.item_type == "radio" ||
					item_data.item_type == "pulldown" ||
					item_data.item_type == "toggle" ||
					item_data.item_type == "mail_address" ||
					item_data.item_type == "numeric"
				) {
					item_data.item_content = this.bind_data.form_info.edit_item_data[i].value[0];
				} else if (item_data.item_type == "datetime") {
					if (
						this.bind_data.form_info.edit_item_data[i].value[0] != "" &&
						this.bind_data.form_info.edit_item_data[i].value[1] != ""
					) {
						item_data.item_content =
							this.bind_data.form_info.edit_item_data[i].value[0] + " " + this.bind_data.form_info.edit_item_data[i].value[1];
					} else {
						item_data.item_content = "";
					}
				} else {
					item_data.item_content = "";
				}

				// 必須チェック
				if (this.bind_data.form_info.edit_item_data[i].edit_item_must == "on") {
					if (item_data.item_content === "") {
						this.bind_data.form_info.edit_item_data[i].state_info = false;
					} else {
						this.bind_data.form_info.edit_item_data[i].state_info = true;
					}
				}

				//数値の範囲チェック
				if (item_data.item_type == "numeric") {
					if (
						this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit !== undefined &&
						this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit != "" &&
						validator.isNumeric(this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit) &&
						item_data.item_content != ""
					) {
						if (
							Number(item_data.item_content) > Number(this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit)
						) {
							this.bind_data.form_info.edit_item_data[i].state_info = false;
						}
					}
					if (
						this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit !== undefined &&
						this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit != "" &&
						validator.isNumeric(this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit) &&
						item_data.item_content != ""
					) {
						if (
							Number(item_data.item_content) < Number(this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit)
						) {
							this.bind_data.form_info.edit_item_data[i].state_info = false;
						}
					}
				}

				//メールアドレスチェック
				if (item_data.item_type == "mail_address") {
					if (item_data.item_content != "") {
						if (!validator.isEmail(item_data.item_content)) {
							this.bind_data.form_info.edit_item_data[i].state_info = false;
						}
					}
				}

				this.bind_data.output_data.item_data.push(item_data);
			}

			this.state_data.form_info = false;
			this.$emit("save", this.bind_data.output_data);
			this.$nextTick(function () {
				this.state_data.form_info = true;
			});
		},

		is_edit_item_type(item, types) {
			let type = types.split(",");
			let item_type = item.edit_item_type;
			for (let i = 0; i < type.length; i++) {
				if (item_type == type[i]) {
					return true;
				}
			}
			return false;
		},

		get_content_text(item_no) {
			for (let i = 0; i < this.bind_data.form_data.item_data.length; i++) {
				if (this.bind_data.form_data.item_data[i].item_no == item_no) {
					return this.bind_data.form_data.item_data[i].item_content;
				}
			}
			return item_no;
		},
	},
};
</script>
