<template>
	<div class="ClassManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-button v-on:click="back()" variant="outline-secondary" class="mt-2 mb-2">戻る</b-button>

			<template v-if="hasArchiveRegistPerm">
				<b-card class="mt-3" header="ドキュメント登録">
					<b-row>
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>ドキュメント名</b-col
						>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<b-form-file
								v-model="local_data.document_data"
								placeholder="ファイルを選択するか、ここにファイルをドロップしてください。"
								drop-placeholder="ここにファイルをドロップしてください。"
								browse-text="参照"
								ref="selectDocument"
								@input="chsck_document"></b-form-file>
						</b-col>
					</b-row>
					<b-row>
						<b-col
							:class="isNotMobilePhone ? text_align.text_right : text_align.text_left"
							xs="12"
							sm="12"
							md="2"
							lg="2"
							xl="2"></b-col>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<div class="small">{{ bind_data.document_placeholder }}</div>
						</b-col>
					</b-row>
					<b-row class="mt-1">
						<b-col :class="isNotMobilePhone ? text_align.text_right : text_align.text_left" xs="12" sm="12" md="2" lg="2" xl="2"
							>共有先</b-col
						>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<template v-if="state_data.treeselectdialogs">
								<treeselectdialogs
									:dpt_selected_array="bind_data.dpt_selected"
									:multiple="true"
									:branch_nodes="false"
									@save="get_dpt_info($event)"></treeselectdialogs>
							</template>
						</b-col>
					</b-row>
					<b-row class="mt-1">
						<b-col
							:class="isNotMobilePhone ? text_align.text_right : text_align.text_left"
							xs="12"
							sm="12"
							md="2"
							lg="2"
							xl="2"></b-col>
						<b-col xs="12" sm="12" md="8" lg="8" xl="8">
							<div class="small">登録後、共有先に設定された組織に所属するユーザーに通知されます。</div>
							<div class="small">共有先変更時も同様に通知されます。</div>
						</b-col>
						<b-col xs="12" sm="12" md="2" lg="2" xl="2">
							<b-button variant="outline-secondary" class="mt-2 float-end" :disabled="registState" @click="handleRegist()"
								>登録</b-button
							>
						</b-col>
					</b-row>
				</b-card>
			</template>

			<!-- ドキュメント一覧 -->
			<b-table class="mt-4" striped hover :items="local_data.result.list" :fields="local_data.result.fields" responsive="sm">
				<!-- 削除ボタン -->
				<template v-if="hasArchiveRegistPerm" #cell(app_cmd)="row">
					<b-button variant="danger" size="sm" @click="handleConfirmDeleteArchive(row.item)">削除</b-button>
				</template>
				<template #cell(share_cmd)="row">
					<b-button variant="outline-secondary float-end" size="sm" class="ms-2" @click="handleShare(row.item)">シェア</b-button>
					<b-button variant="outline-secondary float-end" size="sm" class="ms-2" @click="handleDownload(row.item)"
						>ダウンロード</b-button
					>
					<template v-if="hasArchiveRegistPerm">
						<b-button variant="outline-secondary float-end" size="sm" class="ms-2" @click="handleChangeShare(row.item)"
							>共有先変更</b-button
						>
					</template>
				</template>
			</b-table>
		</div>
		<b-modal ref="deleteArchive" title="ドキュメント削除" @ok="handleDeleteArchive()"
			>選択したドキュメントを削除します。よろしいですか？</b-modal
		>
		<b-modal scrollable ref="treeselectDialog" title="組織を選択" @ok="get_dpt_info_document()">
			<treeselect
				v-model="bind_data.dpt_selected_document"
				multiple
				always-open
				disable-branch-nodes
				searchable
				:options="bind_data.dpt_options"
				placeholder="選択してください" />
			<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
		</b-modal>

		<b-modal ref="share" title="シェア" ok-only>
			<div class="ms-2 me-2">{{ local_data.share_data.text }}</div>
			<b-form-textarea id="textarea" v-model="local_data.share_data.url" rows="6" max-rows="12"></b-form-textarea>
		</b-modal>

		<a ref="documentDownload"></a>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import image from "@/lib/image.js";
import perm from "@/lib/perm.js";
import dataset from "@/lib/dataset.js";
import treeselectdialogs from "@/components/TreeSelectDialogs.vue";
import treeselectdialogs2 from "@/components/TreeSelectDialogs2.vue";
import {
	BAlert,
	BCol,
	BRow,
	BFormFile,
	BModal,
	BFormTextarea,
	BButton,
	BCard,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
} from "bootstrap-vue-next";

export default {
	name: "CabinetManage", // ココを更新
	components: {
		Loading,
		treeselectdialogs,
		treeselectdialogs2,
		BAlert,
		BCol,
		BRow,
		BFormFile,
		BModal,
		BFormTextarea,
		BButton,
		BCard,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
	},

	// 共通関数
	mixins: [common, dataset, CommonDataSet, perm, image],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				dpt_selected: [],
				dpt_options: null,
				dpt_selected_document: [],

				document_placeholder: "選択可能なファイルの容量は" + import.meta.env.VITE_CABINET_UPLOAD_FILE_SIZE_LIMIT + "MB以内です。",
			},
			// ローカルデータ
			local_data: {
				document_data: null,
				bas_info: null,
				mng_uuid_set: "",
				result: {
					list: [],
					fields: [
						{ key: "app_cmd", label: "", class: "text-nowrap" },
						{ key: "acv_document_name", label: "ドキュメント名" },
						{ key: "acv_regist_date_time_show", label: "登録日時", class: "text-nowrap" },
						{ key: "share_cmd", label: "", class: "text-nowrap" },
					],
				},
				deleteArchive: null,
				targetArchive: null,
				share_data: {
					text: "",
					url: "",
				},
			},
			state_data: {
				treeselectdialogs: true,
				treeselectdialogs2: true,
			},
		};
	},

	// 既定計算
	computed: {
		registState: function () {
			if (this.local_data.document_data && this.bind_data.dpt_selected.length !== 0) {
				return false;
			}
			return true;
		},
	},

	watch: {
		"local_data.document_data"() {
			this.chsck_document();
		},
	},

	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "書庫" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		await this.$nextTick();

		this.local_data.bas_info = this.$store.state.selected_bas_info;

		await this.get_dpt();

		this.bind_data.dpt_options = JSON.parse(localStorage.getItem("organization_info"));

		await this.getDocumentList();

		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		back() {
			//画面遷移
			this.transition("/Home/ReportingData");
		},

		get_dpt_info(data) {
			this.bind_data.dpt_selected = data;
		},

		/**
		 * 共有先の組織を取得する
		 */
		async get_dpt() {
			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;

			this.local_data.bas_info.bas_mng_list;
			console.log(this.local_data.bas_info.bas_mng_list[0].bas_mng_uuid);

			for (let i = 0; i < this.local_data.bas_info.bas_mng_list.length; i++) {
				if (i == 0) {
					this.local_data.mng_uuid_set = this.local_data.bas_info.bas_mng_list[i].bas_mng_uuid;
				} else {
					this.local_data.mng_uuid_set =
						this.local_data.mng_uuid_set + "," + this.local_data.bas_info.bas_mng_list[i].bas_mng_uuid;
				}
			}

			await this.set_org_map("allmng", null, this.local_data.mng_uuid_set);

			this.state_data.treeselectdialogs = true;

			// ローディング終了
			this.loader.is_loading = false;
		},

		/**
		 * 資料情報を取得する
		 */
		async getDocumentList() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
						bas_uuid: this.local_data.bas_info.bas_uuid,
					},
				};

				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_CABINET_DOCUMENT_LIST, config);
				if (res.data.result === "OK") {
					this.local_data.result.list = res.data.cabinet_document_list;

					for (let i = 0; i < this.local_data.result.list.length; i++) {
						const document = this.local_data.result.list[i];
						document.acv_regist_date_time_show = document.acv_regist_date_time.substr(0, 16);
						document.dpt_selected = [];
						if (document.acv_share_dest_array) {
							document.dpt_selected = document.acv_share_dest_array.split(",");
						}
					}

					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * 資料を登録する
		 */
		async handleRegist() {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				const formData = new FormData();
				formData.append("sid", this.$store.state.sid);
				formData.append("acv_bas_uuid", this.local_data.bas_info.bas_uuid);
				formData.append("acv_document_name", this.local_data.document_data.name);
				formData.append("acv_share_dest", this.bind_data.dpt_selected.join(","));
				formData.append("acv_cabinet_file", this.local_data.document_data, this.local_data.document_data.name);

				const config = {
					headers: {
						"content-type": "multipart/form-data",
					},
					timeout: 0,
				};

				const res = await axios.post(import.meta.env.VITE_RESTAPI_SET_CABINET_DOCUMENT_DATA, formData, config);
				if (res.data.result === "OK") {
					this.$refs["selectDocument"].reset();
					this.bind_data.dpt_selected = [];
					await this.getDocumentList();
					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				console.error(e);
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		/**
		 * ドキュメントの削除を確認する
		 */
		handleConfirmDeleteArchive(item) {
			this.local_data.deleteArchive = item;

			this.$refs["deleteArchive"].show();
		},

		/**
		 * ドキュメントを削除する
		 */
		async handleDeleteArchive() {
			if (!this.local_data.deleteArchive) {
				return;
			}

			try {
				const data = {
					sid: this.$store.state.sid,
					acv_uuid: this.local_data.deleteArchive.acv_uuid,
				};

				const res = await axios.put(import.meta.env.VITE_RESTAPI_DELETE_CABINET_DOCUMENT, data);
				if (res.data.result === "OK") {
					await this.getDocumentList();
					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				console.error(e);
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * ドキュメントをダウンロードする
		 */
		async handleDownload(item) {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
						acv_uuid: item.acv_uuid,
					},
				};

				const res = await axios.get(import.meta.env.VITE_RESTAPI_DOWNLOAD_CABINET_DOCUMENT_DATA, config);
				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);

					// const blob = this.base64ToBlobImage(res.data.media_file, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
					const blob = this.base64ToBlobImage(res.data.media_file, null);
					const url = window.URL.createObjectURL(blob);

					const link = this.$refs.documentDownload;
					link.href = url;
					link.download = `${item.acv_document_name}`;
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				console.error(e);
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * 共有先を変更する
		 * @param {*} data
		 * @param {*} item
		 */
		async get_dpt_info_document() {
			this.local_data.targetArchive.dpt_selected = this.bind_data.dpt_selected_document;

			try {
				const data = {
					sid: this.$store.state.sid,
					acv_uuid: this.local_data.targetArchive.acv_uuid,
					acv_share_dest: this.local_data.targetArchive.dpt_selected.join(","),
				};

				const res = await axios.put(import.meta.env.VITE_RESTAPI_SET_CABINET_UPDATE_SHARE, data);
				if (res.data.result === "OK") {
					await this.getDocumentList();
					this.$store.dispatch("commit_set_sid", res.data.sid);
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch (e) {
				console.error(e);
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		/**
		 * 共有先を変更する
		 */
		handleChangeShare(item) {
			console.log("item");
			console.log(item);
			this.local_data.targetArchive = item;
			if (item.acv_share_dest_array) {
				this.bind_data.dpt_selected_document = item.acv_share_dest_array.split(",");
			} else {
				this.bind_data.dpt_selected_document = [];
			}
			this.$refs["treeselectDialog"].show();
		},

		async handleShare(item) {
			const config = {
				params: {
					sid: this.$store.state.sid,
					prm_key: "webview_download_url",
				},
			};
			const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_SYSTEM_PARM_LIST, config);
			if (res.data.result === "OK") {
				let url = res.data.params_list[0].prm_value + res.data.sid;
				url = url.replace("%mode%", "cabinet").replace("%acv_uuid%", item.acv_uuid);
				let text1 = "URLをクリックし、ドキュメントをダウンロードしてください。";
				let text2 = "URLをコピーして、配信してください。";

				console.log(url);

				if (navigator.share) {
					const shareData = {
						title: item.acv_document_name,
						text: text1,
						url: url,
					};
					try {
						await navigator.share(shareData);
					} catch (err) {
						console.log(err);
					}
				} else {
					this.local_data.share_data.url = url;
					this.local_data.share_data.text = text2;
					this.$refs["share"].show();
				}
			}
		},
		//選択したファイル容量が上限を超えていないかチェックする
		chsck_document() {
			if (this.local_data.document_data != null) {
				let limitSizeByte = Number(import.meta.env.VITE_CABINET_UPLOAD_FILE_SIZE_LIMIT) * 1048576;
				if (this.local_data.document_data.size > limitSizeByte) {
					this.local_data.document_data = null;
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = this.bind_data.document_placeholder;
				}
			}
		},
	},
};
</script>
