<template>
	<div class="OrganizationView">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div v-if="state_data.tree">
				<div style="height: 100%">
					<vue3-tree-org
						:data="bind_data.dpt_info_list"
						:scalable="false"
						:draggable="false"
						:node-draggable="false"
						:collapsable="false"
						:horizontal="true"
						:default-expand-level="1"
						:label-style="labelStyle"
						:toolbar="{ scale: false, restore: false, expand: false, zoom: false, fullscreen: false }"
						@on-expand="onExpand"
						@on-node-click="onNodeClick"
						@on-node-mouseover="onNodeMouseOver"
						@on-node-mouseout="onNodeMouseOut" />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.zm-tree-org .zoom-container {
	/* position: relative; */
	/* width: 100%; */
	/* height: 100%; */
	overflow: visible !important;
	/* text-align: center; */
	/* transform-origin: left top; */
}
/* ツールバー表示しない */
.zm-tree-handle {
	display: none;
}
</style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import { BAlert } from "bootstrap-vue-next";

export default {
	//  name: 'OrganizationRegist', // ココを更新
	name: "OrganizationView", // ココを更新
	components: {
		Loading,
		BAlert,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				dpt_info_list: null,
			},
			// ローカルデータ
			local_data: {},
			state_data: {
				tree: false,
			},
			horizontal: true,
			collapsable: true,
			onlyOneNode: false,
			cloneNodeDrag: false,
			expandAll: true,
			scalable: false,
			toolbar: false,
			labelStyle: {
				width: "180px",
			},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "組織表示" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		await this.set_org_map("allslf", null, null);

		// 組織データ設定（コンポーネントの仕様に合わせてTOPレベルの組織一覧を追加して表示する）
		const data = '{ "id":1, "label":"組織一覧", "children":' + localStorage.getItem("organization_info") + "}";
		this.bind_data.dpt_info_list = JSON.parse(data);
		// TOPレベル組織の個数によって「組織一覧」を出したり出さなかったりする実装（一個なら出さない。複数なら出す）
		// const jsonData = JSON.parse(localStorage.getItem('organization_info'));
		// if (jsonData.length == 1) {
		//   this.bind_data.dpt_info_list = jsonData[0];
		// } else {
		//   const org_top = JSON.parse('{ "id":1, "label":"組織一覧", "children": null}');
		//   org_top.children = jsonData;
		//   this.bind_data.dpt_info_list = org_top;
		// }

		this.state_data.tree = true;

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		labelClass() {
			return "bg_node";
		},
		onExpand(e, data) {
			console.log("on-expand", e, data);
			if ("expand" in data) {
				data.expand = !data.expand;
				if (!data.expand && data.children) {
					this.collapse(data.children);
				}
			} else {
				// this.$set(data, "expand", true);
				data.expand = true;
			}
		},
		collapse(nodes) {
			nodes.forEach((node) => {
				if (node.expand) {
					node.expand = false;
				}

				node.children && this.collapse(node.children);
			});
		},
		onNodeClick(e, data) {
			console.log("CLICK", e, data);
			// this.$set(data, 'selectedKey', !data['selectedKey']);
		},
		onNodeMouseOver(e, data) {
			console.log("MOUSE OVER", e, data);
		},
		onNodeMouseOut(e, data) {
			console.log("MOUSE OUT", e, data);
		},
	},
};
</script>
