<template>
	<div class="AffiliationApproval">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-left">
			<!-- 所属申請一覧 -->
			<b-table class="mt-4" striped hover :items="bind_data.result.list" :fields="local_data.result.fields">
				<!-- 承認/差し戻しボタン -->
				<template #cell(approval_cmd)="row">
					<b-button v-on:click="normal(row)" variant="outline-secondary float-end" size="sm" class="ms-2">承認</b-button>
					<b-button v-on:click="reject(row)" variant="outline-secondary float-end" size="sm">差し戻し</b-button>
				</template>
			</b-table>
		</div>
	</div>
</template>
<style></style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import { BAlert, BButton, BTable } from "bootstrap-vue-next";

export default {
	name: "AffiliationApproval", // ココを更新
	components: {
		Loading,
		BAlert,
		BButton,
		BTable,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				usr_name: "",
				src_dpt_name: "",
				dst_dpt_name: "",
				transfer_date: "2021/04/01",

				result: {
					list: [],
				},
				request_state: "",
			},
			// ローカルデータ
			local_data: {
				row: null,
				result: {
					fields: [
						{ key: "usr_name", label: "異動者", class: "text-nowrap" },
						{ key: "usr_org_name", label: "異動元部署", class: "text-nowrap" },
						{ key: "appry_org_name", label: "異動先部署", class: "text-nowrap" },
						{ key: "usr_transfer_date", label: "異動適用日", class: "text-nowrap" },
						{ key: "approval_cmd", label: "" },
					],
				},
			},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "所属承認" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
		await this.affiliation_list();
	},
	// ローカル関数
	methods: {
		// 所属申請情報取得
		async affiliation_list() {
			this.loader.is_loading = true;
			let url = import.meta.env.VITE_RESTAPI_GET_AFFI_APPROVE + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.usr_list.length > 0) {
						console.log(this.$route.path + "length > 0");
						for (let i = 0; i < response.data.usr_list.length; i++) {
							this.bind_data.result.list.push(response.data.usr_list[i]);
						}
					} else {
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "所属申請はありません";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				this.loader.is_loading = false;
			} catch (ex) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
		// 承認押下
		async normal(row) {
			this.local_data.request_state = "moving";
			await this.approval(row);
		},
		// 差し戻し押下
		async reject(row) {
			this.local_data.request_state = "reject";
			await this.approval(row);
		},

		// 承認/差し戻し実行
		async approval(row) {
			this.loader.is_loading = true;
			this.message.is_alert = false;
			let url = import.meta.env.VITE_RESTAPI_SET_AFFI_REQ;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.usr_uuid = row.item.usr_uuid;
			post_data.usr_applying_org_uuid = row.item.usr_applying_org_uuid;
			post_data.usr_apply_status = this.local_data.request_state;
			post_data.usr_transfer_date = row.item.usr_transfer_date;
			console.log(this.$route.path + ":usr_uuid" + JSON.stringify(row.item.usr_uuid));
			console.log(this.$route.path + ":usr_apply_status=" + JSON.stringify(this.local_data.request_state));
			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					// リロード
					await this.affiliation_list();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
					// ローディング終了
					this.loader.is_loading = false;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
	},
};
</script>
