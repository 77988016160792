<template>
	<div class="CompanysAccessControlManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<!-- アクセス制御切替 -->
			<div class="mt-4">
				<b-card class="border-1 mt-2">
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">IPアドレス制限機能</b-col>
						<b-col xs="12" sm="6" md="6" lg="7" xl="8">
							<b-form-group>
								<b-form-radio-group v-model="bind_data.switch_selected" :options="bind_data.switch_options"></b-form-radio-group>
							</b-form-group>
						</b-col>
						<b-col xs="12" sm="2" md="2" lg="2" xl="2">
							<b-button @click="confirmHandleUpdateIpControl()" variant="outline-secondary" class="ms-2 float-end">更新</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>
			<b-modal ref="confirmUpdateIpControl" title="IPアドレス制限" @ok="handleUpdateIpControl()" cancel-title="キャンセル"
				>有効・無効の設定を変更します。よろしいですか？</b-modal
			>

			<!-- 一覧 -->
			<b-table class="mt-3" striped hover :items="bind_data.result.list" :fields="local_data.fields">
				<template #cell(app_cmd)="row">
					<b-button class="float-end" variant="outline-secondary" size="sm" v-on:click="edit_row(row)">編集</b-button>
				</template>
				<template v-slot:row-details="row">
					<b-card class="border-1 mt-2 ms-2">
						<b-row class="mt-2">
							<b-col class="text-end" xs="12" sm="4" md="4" lg="3" xl="2">アクセス制御</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-textarea
									v-model="local_data.edit_data.cmp_access_control"
									placeholder="1行ごとにIPアドレスを入力してください。例：192.168.1.1"
									state="state_edit_cmp_access_control"
									rows="3"
									max-rows="20">
								</b-form-textarea>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col>
								<!-- <b-button @click="update_row()" variant="outline-secondary" :disabled="state_edit_update_button" class="ms-2 float-end">更新</b-button> -->
								<b-button
									@click="update_row()"
									variant="outline-secondary"
									class="ms-2 float-end"
									:disabled="state_update_row_button"
									>更新</b-button
								>
								<b-button variant="outline-secondary" class="float-end" v-on:click="cancel_row(row)">キャンセル</b-button>
							</b-col>
						</b-row>
					</b-card>
				</template>
			</b-table>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import validator from "validator";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import {
	BAlert,
	BRow,
	BCol,
	BBadge,
	BTable,
	BFormTextarea,
	BFormSelect,
	BFormGroup,
	BFormRadioGroup,
	BButton,
	BCard,
	BModal,
} from "bootstrap-vue-next";

export default {
	name: "CompanysAccessControlManage", // ココを更新
	components: {
		Loading,
		BAlert,
		BRow,
		BCol,
		BBadge,
		BTable,
		BFormTextarea,
		BFormSelect,
		BFormGroup,
		BFormRadioGroup,
		BButton,
		BCard,
		BModal,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				access_ok: false,
				switch_selected: "",
				switch_options: [
					{ text: "ＩＰアドレス制限を有効にする", value: "on" },
					{ text: "ＩＰアドレス制限を無効にする", value: "off" },
				],

				// 検索結果
				result: {
					list: [],
				},
			},
			// ローカルデータ
			local_data: {
				// 選択行
				row: null,
				// 既存データ選択状態
				selected: false,

				edit_data: {
					app_cmd: "",
					cmp_id: "",
					cmp_name: "",
					cmp_access_control: "",
				},
				fields: [
					{ key: "cmp_id", label: "会社ID", sortable: true },
					{ key: "cmp_name", label: "会社名", sortable: true },
					{ key: "cmp_type_name", label: "会社種別", sortable: true },
					{ key: "cmp_max_users", label: "ユーザー数上限", sortable: true },
					{ key: "cmp_sort_order", label: "表示順位", sortable: true },
					{ key: "app_cmd", label: "", class: "text-nowrap" },
				],
				//会社種別
				type_options: [],
			},
		};
	},

	// 既定計算
	computed: {
		state_search_button() {
			if (!validator.isEmpty(this.local_data.cmp_name)) {
				return false;
			} else {
				return true;
			}
		},

		state_edit_cmp_access_control() {
			let regex = RegExp(/^[0-9\\.\\\n]*$/);
			return regex.test(this.local_data.edit_data.cmp_access_control);
		},

		state_update_row_button() {
			if (this.state_edit_cmp_access_control) {
				return false;
			} else {
				return true;
			}
		},

		// state_edit_cmp_name(){
		//   return !validator.isEmpty(this.local_data.edit_data.cmp_name);
		// },
		// state_edit_update_button(){
		//   if(this.state_edit_cmp_name && this.local_data.edit_data.cmp_type != "" ){
		//     return false;
		//   }else {
		//     return true;
		//   }
		// },
	},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "会社アクセス制限設定" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
		await this.get_ip_control();
		await this.companys_list();
	},
	// ローカル関数
	methods: {
		// 会社一覧
		async companys_list() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let row = response.data.company_list[i];
							row.cmp_type_name = await this.get_item_name("cmptype", row.cmp_type);
							console.log("row=" + JSON.stringify(row));
							this.bind_data.result.list.push(row);
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "登録済の会社が存在しません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		//編集ボタン押下
		edit_row(row) {
			//他の編集が開いていたら閉じる
			if (this.local_data.row != null) {
				if (this.local_data.selected) {
					this.local_data.row.toggleDetails();
				}
			}
			//編集用に行データからコピーする
			this.local_data.edit_data.cmp_id = row.item.cmp_id;
			this.local_data.edit_data.cmp_access_control = row.item.cmp_access_control;
			if (this.local_data.edit_data.cmp_access_control != null) {
				this.local_data.edit_data.cmp_access_control = row.item.cmp_access_control.replaceAll(",", "\n");
			}
			//編集が開いているフラグをON
			this.local_data.selected = true;
			row.toggleDetails();
			//行情報保管
			this.local_data.row = row;
		},

		//編集キャンセルボタン押下
		cancel_row(row) {
			//編集が開いているフラグをOFF
			this.local_data.selected = false;
			row.toggleDetails();
		},

		// 更新実行
		async update_row() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_COMPANY_ACCESS_CONTROL_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.cmp_id = this.local_data.edit_data.cmp_id;
			if (this.local_data.edit_data.cmp_access_control != null) {
				post_data.cmp_access_control = this.local_data.edit_data.cmp_access_control.replaceAll("\n", ",");
			}
			if (
				this.local_data.edit_data.cmp_access_control.length -
					this.local_data.edit_data.cmp_access_control.replaceAll("\n", "").length >
				19
			) {
				// ローディング終了
				this.loader.is_loading = false;
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "アクセス制御が上限を超えています。";
			} else {
				console.log(this.$route.path + ":post_data=" + JSON.stringify(post_data));
				try {
					let response = await axios.put(url, post_data);
					console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
					if (response.data.result == "OK") {
						//選択状態解除
						this.local_data.selected = false;
						this.local_data.row.toggleDetails();
						// リロード
						await this.companys_list();
						//ローディング終了
						this.loader.is_loading = false;
					} else {
						// ローディング終了
						this.loader.is_loading = false;

						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = response.data.message;
					}
				} catch (ex) {
					// ローディング終了
					this.loader.is_loading = false;

					console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = JSON.stringify(ex);
				}
			}
			// ローディング終了
			this.loader.is_loading = false;
		},

		// ＩＰアクセス制御取得
		async get_ip_control() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url =
				import.meta.env.VITE_RESTAPI_GET_SYSTEM_PARM_LIST + "?sid=" + this.$store.state.sid + "&prm_key=" + "ip_control_switch";

			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.params_list.length > 0) {
						for (let i = 0; i < response.data.params_list.length; i++) {
							let param = response.data.params_list[i];
							this.bind_data.switch_selected = param.prm_value;
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "パラメータが存在しません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		// ＩＰ制御更新実行
		async update_ip_control() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_ACCESS_CONTROL_SWITCH;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.prm_key = "ip_control_switch";
			post_data.prm_value = this.bind_data.switch_selected;
			console.log(this.$route.path + ":post_data=" + JSON.stringify(post_data));
			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		/**
		 * ＩＰ制御更新を確認する
		 */
		confirmHandleUpdateIpControl() {
			// if (!this.valid()) {
			//   return;
			// }

			this.$refs["confirmUpdateIpControl"].show();
		},

		/**
		 * ＩＰ制御を更新する
		 */
		async handleUpdateIpControl() {
			await this.update_ip_control();
		},
	},
};
</script>
