<template>
	<div class="UserRegist">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>

		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div class="mt-3">
				<b-button
					variant="outline-secondary"
					v-on:click="
						add_user();
						add_cmp_change();
					"
					>新規</b-button
				>
			</div>
			<!-- 新規ユーザー -->
			<div v-if="local_data.add_data.selected">
				<b-card class="card-shadow border-1 mt-2">
					<b-row class="mt-2">
						<b-col class="text-end">{{ local_data.user_count }} </b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-select
								v-model="local_data.add_data.usr_cmp_id"
								:options="bind_data.org_cmp_uuid_options"
								placeholder="選択してください"
								@change="add_cmp_change()" />
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">ログインID</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_login_id"
								placeholder="半角英小文字,数字で32文字以下で入力してください"
								:state="state_usr_login_id"
								type="text"
								maxlength="32"
								@input="change_usr_login_id"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">パスワード</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_password"
								placeholder="半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください"
								:state="state_usr_password"
								type="text"
								maxlength="32"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">ユーザー名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_name"
								placeholder="ユーザー名を32文字以下で入力してください"
								:state="state_usr_name"
								type="text"
								maxlength="32"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">プロフィール画像</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-input-group-text>
								<b-form-file
									v-model="local_data.add_data.usr_profile_img_file"
									placeholder="ファイルを選択するか、ここにファイルをドロップしてください。"
									drop-placeholder="ここにファイルをドロップしてください。"
									accept=".png,.jpg,.JPG,.PNG"
									browse-text="参照">
									<!-- @input="addFormatNames"> addFormatNamesが呼ばれるタイミングではv-modelの内容がnullのため、watchから呼び出す -->
								</b-form-file>
								<template v-if="!state_add_usr_profile_img">
									<b-button variant="outline-secondary" @click="img_delete_add()"
										><!-- 2023_07 local_data.add_data.usr_profile_img_file = null -->
										<BootstrapIcon icon="trash"></BootstrapIcon>
									</b-button>
									<b-button id="add_usr_profile_img" variant="outline-secondary">
										<BootstrapIcon icon="image"></BootstrapIcon>
									</b-button>
									<b-popover
										target="add_usr_profile_img"
										placement="top"
										:click="true"
										:close-on-hide="true"
										:delay="{ show: 0, hide: 0 }">
										<b-img :src="local_data.add_data.usr_profile_img_src"></b-img>
									</b-popover>
								</template>
							</b-input-group-text>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2"> 所属部署</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<template v-if="state_data.treeselectdialogs">
								<treeselectdialogs
									:dpt_info="bind_data.dpt_options"
									:dpt_selected_array="bind_data.dummy_array"
									:dpt_selected_string="local_data.add_data.usr_org_uuid"
									:multiple="false"
									:branch_nodes="false"
									@save="get_dpt_info_add"></treeselectdialogs>
							</template>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">役職名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_post"
								maxlength="32"
								placeholder="役職名を32文字以下で入力してください"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">メールアドレス</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_mail_address"
								type="text"
								placeholder="メールアドレスを入力してください（カンマ区切りで複数登録可）"
								:state="state_usr_mail_address"
								maxlength="256"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">表示順位</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.usr_sort_order"
								:state="state_add_usr_sort_order"
								maxlength="8"
								placeholder="表示順位を8文字以下で入力してください"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">多要素認証</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-checkbox v-model="local_data.add_data.usr_multi_auth_invalid" value="on" unchecked-value="off"
								>無効化する</b-form-checkbox
							>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col>
							<b-button variant="outline-secondary" class="ms-2 float-end" v-on:click="add_new()" :disabled="state_add_new_button"
								>登録</b-button
							>
							<b-button variant="outline-secondary" class="float-end" v-on:click="add_cancel()">キャンセル</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<!-- 検索フィールド -->
			<b-row class="mt-3">
				<b-col sm="4" lg="4">
					<b-form-select
						v-model="bind_data.search_cmp_id"
						:options="bind_data.org_cmp_uuid_options"
						placeholder="選択してください" />
				</b-col>
				<b-col sm="6" lg="6">
					<b-input-group class="mb-2">
						<b-input-group-text>
							<BootstrapIcon icon="search"></BootstrapIcon>
						</b-input-group-text>
						<b-form-input
							v-model="bind_data.search_name"
							placeholder="ユーザー名（部分一致）を入力して検索してください"
							maxlength="32"></b-form-input>
					</b-input-group>
				</b-col>
				<b-col sm="2" lg="2">
					<div class="d-grid">
						<b-button
							block
							variant="outline-secondary"
							v-on:click="
								seach_users_list();
								users_count();
							"
							:disabled="false"
							>検索</b-button
						>
					</div>
				</b-col>
			</b-row>
			<!-- ダウンロード -->
			<b-row class="mt-3">
				<b-col offset-sm="10" sm="2" offset-lg="10" lg="2">
					<div class="d-grid">
						<b-button v-if="bind_data.dl_flg" block class="float-end" variant="outline-secondary" v-on:click="download()"
							>ダウンロード</b-button
						>
					</div>
				</b-col>
			</b-row>
			<!-- アップロード -->
			<b-row class="mt-3">
				<b-col sm="10" lg="10">
					<b-form-file
						v-model="bind_data.csv_file"
						placeholder="ファイルを選択するか、ここにファイルをドロップしてください。"
						accept=".csv"
						browse-text="参照">
					</b-form-file>
				</b-col>
				<b-col sm="2" lg="2">
					<div class="d-grid">
						<b-button block class="float-end" variant="outline-secondary" v-on:click="csv_upload()" :disabled="state_upload"
							>アップロード</b-button
						>
					</div>
				</b-col>
			</b-row>

			<!-- 登録済ユーザー数合計 -->
			<div class="mt-3 ms-2">
				<b-row class="mt-2">
					<b-col class="text-end">{{ local_data.user_count }}</b-col>
				</b-row>
			</div>

			<!-- 既存ユーザー一覧 -->
			<b-table class="mt-1" fixed responsive striped hover :items="bind_data.result.list" :fields="local_data.fields">
				<template #table-colgroup="scope">
					<col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
				</template>
				<!-- 編集ボタン -->
				<template #cell(app_cmd)="row">
					<b-button
						class="float-end"
						variant="outline-secondary"
						size="sm"
						v-on:click="
							edit_row(row);
							edit_cmp_change();
						"
						>編集</b-button
					>
				</template>
				<!-- 編集カード -->
				<template v-slot:row-details="row">
					<b-card class="card-shadow border-1 mt-2 ms-2">
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">{{ local_data.edit_data.cmp_name }}</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">ログインID</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_login_id"
									placeholder="半角英小文字,数字でを32文字以下で入力してください"
									:state="state_edit_usr_login_id"
									maxlength="32"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">パスワード</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_password"
									placeholder="空白の場合は更新されません。半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください"
									:state="state_edit_usr_password"
									maxlength="32"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">ユーザー名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_name"
									placeholder="ユーザー名を32文字以下で入力してください"
									:state="state_edit_usr_name"
									maxlength="32"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">プロフィール画像</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<template v-if="!state_edit_usr_profile_img">
									<div>
										<img v-bind:src="local_data.edit_data.usr_profile_img_src" width="100" height="100" />
									</div>
								</template>
								<b-input-group-text>
									<b-form-file
										v-model="local_data.edit_data.usr_profile_img_file"
										placeholder="ファイルを選択するか、ここにファイルをドロップしてください。"
										drop-placeholder="ここにファイルをドロップしてください。"
										accept=".png,.jpg,.JPG,.PNG"
										browse-text="参照">
										<!-- @input="editFormatNames"> editFormatNamesが呼ばれるタイミングではv-modelの内容がnullのため、watchから呼び出す-->
									</b-form-file>
									<template v-if="!state_edit_usr_profile_img">
										<b-button variant="outline-secondary" @click="img_delete_edit()"
											><!-- 2023_07 local_data.edit_data.usr_profile_img_file = null -->
											<BootstrapIcon icon="trash"></BootstrapIcon>
										</b-button>
										<b-button id="edit_usr_profile_img" variant="outline-secondary">
											<BootstrapIcon icon="image"></BootstrapIcon>
										</b-button>
										<b-popover
											target="edit_usr_profile_img"
											placement="top"
											:click="true"
											:close-on-hide="true"
											:delay="{ show: 0, hide: 0 }">
											<b-img :src="local_data.edit_data.usr_profile_img_src"></b-img>
										</b-popover>
									</template>
								</b-input-group-text>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2"> 所属部署</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<template v-if="state_data.treeselectdialogs">
									<treeselectdialogs
										:dpt_info="bind_data.dpt_options"
										:dpt_selected_array="bind_data.dummy_array"
										:dpt_selected_string="local_data.edit_data.usr_org_uuid"
										:multiple="false"
										:branch_nodes="false"
										@save="get_dpt_info_edit"></treeselectdialogs>
								</template>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">役職名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_post"
									placeholder="役職名を32文字以下で入力してください"
									maxlength="32"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">メールアドレス</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_mail_address"
									placeholder="メールアドレスを入力してください（カンマ区切りで複数登録可）"
									:state="state_edit_usr_mail_address"
									maxlength="256"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">表示順位</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input
									v-model="local_data.edit_data.usr_sort_order"
									:state="state_edit_usr_sort_order"
									maxlength="8"
									placeholder="表示順位を8文字以下で入力してください"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">多要素認証</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-checkbox v-model="local_data.edit_data.usr_multi_auth_invalid" value="on" unchecked-value="off"
									>無効化する</b-form-checkbox
								>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col>
								<b-button @click="delete_confirm()" variant="danger" class="float-start">削除</b-button>
								<b-button
									@click="update_row()"
									variant="outline-secondary"
									class="ms-2 float-end"
									:disabled="state_edit_update_button"
									>更新</b-button
								>
								<b-button variant="outline-secondary" class="float-end" v-on:click="cancel_row(row)">キャンセル</b-button>
							</b-col>
						</b-row>
					</b-card>
				</template>
			</b-table>
		</div>
		<!-- メインコンテンツ -->

		<!-- モーダルダイアログ -->
		<b-modal
			ref="confirm-modal"
			title="削除"
			ok-title="削除"
			ok-variant="danger"
			cancel-title="キャンセル"
			cancel-variant="outline-secondary"
			@ok="delete_row()"
			@cancel="delete_cancel()">
			<p>ユーザ名：{{ local_data.edit_data.usr_name }} を削除します。よろしいですか?</p>
			<!-- <template #modal-footer>
			<b-button v-on:click="delete_cancel()" variant="outline-secondary" class="float-end">キャンセル</b-button>
			<b-button v-on:click="delete_row()" variant="danger" class="float-end">削除</b-button>
		</template> -->
		</b-modal>
	</div>
	<!-- UserRegist -->
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { Jimp, JimpMime } from "jimp";
import validator from "validator";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import treeselectdialogs from "@/components/TreeSelectDialogs.vue";
import {
	BAlert,
	BPopover,
	BFormFile,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
	BImg,
} from "bootstrap-vue-next";

export default {
	name: "UserRegist", // ココを更新
	components: {
		Loading,
		treeselectdialogs,
		BAlert,
		BPopover,
		BFormFile,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
		BImg,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				dpt_selected: "",
				dummy_array: [],
				dl_flg: false,
				csv_file: null,
				// 検索条件
				search_name: "",
				search_cmp_id: "",

				// 検索結果
				result: {
					list: [],
				},

				// 会社UUID
				org_cmp_uuid_options: [],
				options: {
					label: "",
					value: "",
					id: "",
				},
				selected_dept_uuid: "",
				dept_selected: 0,

				state_usr_deliverable_flg: false,
			},
			// ローカルデータ
			local_data: {
				//登録済ユーザー数上限チェック結果
				reach_upper_limit: "",

				//登録済ユーザー数
				user_count: "",

				// ユーザー情報(新規)
				add_data: {
					selected: false,
					usr_name: "", // ユーザー名
					usr_login_id: "", // ログインID
					usr_cmp_id: "", // 会社ID
					usr_org_uuid: "", // 組織UUID
					usr_post: "", // 役職名
					usr_mail_address: "", // メールアドレス
					usr_password: "", // パスワード
					usr_profile_img_file: null, // 写真
					usr_profile_img_src: "",
					usr_sort_order: "",
					usr_multi_auth_invalid: "off", // 多要素認証無効化
				},
				// ユーザー情報(編集用)
				edit_data: {
					usr_uuid: "", // ユーザーUUID
					usr_name: "", // ユーザー名
					usr_login_id: "", // ログインID
					usr_cmp_id: "", // 会社ID
					usr_org_uuid: "", // 組織UUID
					usr_org_name: "", // 所属部署名
					usr_post: "", // 役職名
					usr_mail_address: "", // メールアドレス
					usr_password: "",
					usr_profile_img_file: null, // 写真
					usr_profile_img_src: "",
					cmp_name: "", // 会社名
					usr_sort_order: "",
					usr_multi_auth_invalid: "off", // 多要素認証無効化
				},
				fields: [
					{ key: "cmp_name", label: "会社名", sortable: true, class: "text-break", width: "16%" },
					{ key: "usr_login_id", label: "ログインID", sortable: true, class: "text-break", width: "11%" },
					{ key: "usr_name", label: "ユーザー名", sortable: true, class: "text-break", width: "auto" },
					{ key: "usr_org_name", label: "所属部署名", sortable: true, class: "text-break", width: "11%" },
					{ key: "usr_mail_address", label: "メールアドレス", sortable: true, class: "text-break", width: "14%" },
					{ key: "usr_sort_order", label: "表示順位", sortable: true, class: "text-break", width: "9%" },
					{ key: "usr_multi_auth_invalid_str", label: "多要素認証", sortable: true, class: "text-break", width: "11%" },
					{ key: "app_cmd", label: "", width: "70px" },
				],
				row: null,
				selected: false,
			},
			state_data: {
				treeselectdialogs: true,
			},
		};
	},

	// 既定計算
	computed: {
		state_reach_regist_limit() {
			if (this.local_data.reach_upper_limit == "yes") {
				return false;
			}
			return true;
		},
		state_usr_login_id() {
			if (!validator.isEmpty(this.local_data.add_data.usr_login_id)) {
				return validator.isAlphanumeric(this.local_data.add_data.usr_login_id);
			}
			return false;
		},
		state_usr_password() {
			const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[.?/@-])[a-zA-Z0-9.?/@-]{8,32}$/;
			return regex.test(this.local_data.add_data.usr_password);
		},
		state_usr_name() {
			return !validator.isEmpty(this.local_data.add_data.usr_name);
		},
		state_usr_mail_address() {
			if (validator.isEmpty(this.local_data.add_data.usr_mail_address)) {
				return false;
			}
			let usr_mail_address = this.local_data.add_data.usr_mail_address.split(",");
			for (let i = 0; i < usr_mail_address.length; i++) {
				if (!validator.isEmail(usr_mail_address[i])) {
					return false;
				}
			}
			return true;
		},
		state_add_usr_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return regex.test(this.local_data.add_data.usr_sort_order);
		},
		state_usr_department() {
			if (this.local_data.add_data.usr_org_uuid == null || this.local_data.add_data.usr_org_uuid == "") {
				return false;
			} else {
				return true;
			}
		},

		state_add_new_button() {
			if (
				this.state_usr_login_id &&
				this.state_usr_name &&
				this.state_usr_mail_address &&
				this.state_usr_password &&
				this.state_reach_regist_limit &&
				this.state_add_usr_sort_order &&
				this.state_usr_department
			) {
				return false;
			} else {
				return true;
			}
		},

		state_edit_usr_login_id() {
			if (!validator.isEmpty(this.local_data.edit_data.usr_login_id)) {
				return validator.isAlphanumeric(this.local_data.edit_data.usr_login_id);
			}
			return false;
		},
		state_edit_usr_password() {
			if (validator.isEmpty(this.local_data.edit_data.usr_password)) {
				return true;
			}
			const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[.?/@-])[a-zA-Z0-9.?/@-]{8,32}$/;
			return regex.test(this.local_data.edit_data.usr_password);
		},
		state_edit_usr_name() {
			return !validator.isEmpty(this.local_data.edit_data.usr_name);
		},
		state_edit_usr_mail_address() {
			if (validator.isEmpty(this.local_data.edit_data.usr_mail_address)) {
				return false;
			}
			let usr_mail_address = this.local_data.edit_data.usr_mail_address.split(",");
			for (let i = 0; i < usr_mail_address.length; i++) {
				if (!validator.isEmail(usr_mail_address[i])) {
					return false;
				}
			}
			return true;
		},
		state_edit_usr_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return regex.test(this.local_data.edit_data.usr_sort_order);
		},
		state_edit_usr_department() {
			if (this.local_data.edit_data.usr_org_uuid == null || this.local_data.edit_data.usr_org_uuid == "") {
				return false;
			} else {
				return true;
			}
		},
		state_edit_update_button() {
			if (
				this.state_edit_usr_login_id &&
				this.state_edit_usr_name &&
				this.state_edit_usr_mail_address &&
				this.state_edit_usr_sort_order &&
				this.state_edit_usr_department
			) {
				return false;
			} else {
				return true;
			}
		},
		state_upload() {
			return this.bind_data.csv_file == null;
		},

		state_add_usr_profile_img() {
			if (this.local_data.add_data.usr_profile_img_file == null) {
				return true;
			} else {
				return false;
			}
		},
		//2023_07 画像があれば削除・プレビュー操作可能に
		state_edit_usr_profile_img() {
			if (this.local_data.edit_data.usr_profile_img_src == "" || this.local_data.edit_data.usr_profile_img_src == " ") {
				//			if( this.local_data.edit_data.usr_profile_img_file == null ){
				return true;
			} else {
				return false;
			}
		},
	},

	watch: {
		"local_data.add_data.usr_profile_img_file"() {
			this.addFormatNames();
		},
		"local_data.edit_data.usr_profile_img_file"() {
			this.editFormatNames();
		},
	},

	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "ユーザー設定" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		//console.log(this.$route.path+".vue Start");
		await this.companys_list();
		this.bind_data.search_cmp_id = this.$store.state.users_info.usr_cmp_id; //初回はログインユーザーの会社で表示
		await this.users_count();
		await this.user_list();
	},
	// ローカル関数
	methods: {
		change_usr_login_id() {
			this.local_data.add_data.usr_login_id = this.local_data.add_data.usr_login_id.toLowerCase();
		},
		get_dpt_info_add(data) {
			//			console.log("get_dpt_info_add="+JSON.stringify(data));
			this.local_data.add_data.usr_org_uuid = data;
		},
		get_dpt_info_edit(data) {
			//			console.log("get_dpt_info_edit="+JSON.stringify(data));
			this.local_data.edit_data.usr_org_uuid = data;
		},
		// 会社リスト用会社一覧取得
		async companys_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.org_cmp_uuid_options = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let options = {};
							options.text = response.data.company_list[i].cmp_name;
							options.value = response.data.company_list[i].cmp_id;
							this.bind_data.org_cmp_uuid_options.push(options);

							if (i == 0) {
								// 初期値セット
								this.local_data.add_data.usr_cmp_id = response.data.company_list[i].cmp_id;
								this.bind_data.search_cmp_id = response.data.company_list[i].cmp_id;
							}
						}
					}
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 登録済ユーザー数取得
		async users_count() {
			this.message.is_alert = false;
			// ローディング開始
			this.loader.is_loading = true;

			let url =
				import.meta.env.VITE_RESTAPI_GET_COMPANY_USERS_REGISTERED_INFO +
				"?sid=" +
				this.$store.state.sid +
				"&cmp_id=" +
				this.bind_data.search_cmp_id;
			try {
				let response = await axios.get(url);
				//console.log(this.$route.path+":response="+JSON.stringify(response.data) );

				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.local_data.user_count = response.data.registered_number;
					this.local_data.reach_upper_limit = response.data.reach_upper_limit;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		// 検索
		async seach_users_list() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;
			let url =
				import.meta.env.VITE_RESTAPI_GET_USER_LIST +
				"?sid=" +
				this.$store.state.sid +
				"&usr_name=" +
				encodeURIComponent(this.bind_data.search_name) +
				"&cmp_id=" +
				this.bind_data.search_cmp_id;
			try {
				this.local_data.add_data.usr_cmp_id = this.bind_data.search_cmp_id; //新規の会社プルダウンに選択値セットしておく

				let response = await axios.get(url);
				console.log("seach_users_list:response=" + JSON.stringify(response.data));
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.user_list.length > 0) {
						for (let i = 0; i < response.data.user_list.length; i++) {
							let row = response.data.user_list[i];
							if (row.usr_org_info.length > 0) {
								row.usr_org_name = row.usr_org_info[0].org_name;
							}
							//console.log("row="+JSON.stringify(row) );
							this.bind_data.result.list.push(row);
							// ダウンロードボタン表示
							this.bind_data.dl_flg = true;
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "登録済のユーザーが存在しません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
		// 一覧取得
		async user_list() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;
			let url =
				import.meta.env.VITE_RESTAPI_GET_USER_LIST + "?sid=" + this.$store.state.sid + "&cmp_id=" + this.bind_data.search_cmp_id;
			try {
				let response = await axios.get(url);
				//console.log("user_list:response="+JSON.stringify(response.data) );
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.result.list = [];
					if (response.data.user_list.length > 0) {
						for (let i = 0; i < response.data.user_list.length; i++) {
							let row = response.data.user_list[i];
							if (row.usr_org_info.length > 0) {
								row.usr_org_name = row.usr_org_info[0].org_name;
							}
							this.bind_data.result.list.push(row);
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "登録済のユーザーが存在しません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ダウンロードボタン非表示
				this.bind_data.dl_flg = false;
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				// ローディング終了
				this.loader.is_loading = false;
			}
		},
		// 新規ボタン 要初期化？
		add_user() {
			// 入力欄の初期化処理
			this.local_data.add_data.usr_name = "";
			this.local_data.add_data.usr_login_id = "";
			this.local_data.add_data.usr_org_uuid = "";
			this.local_data.add_data.usr_post = "";
			this.local_data.add_data.usr_mail_address = "";
			this.local_data.add_data.usr_password = "";
			this.local_data.add_data.usr_profile_img_file = null;
			this.local_data.add_data.usr_profile_img_src = "";
			this.local_data.add_data.usr_sort_order = "";
			this.local_data.add_data.usr_multi_auth_invalid = "off";

			this.local_data.add_data.selected = true;
			this.local_data.add_data.usr_cmp_id = this.$store.state.users_info.usr_cmp_id;
			if (this.local_data.selected) {
				this.local_data.row.toggleDetails();
				this.local_data.selected = false;
			}
		},
		add_cancel() {
			this.local_data.add_data.selected = false;
		},
		// 登録実行
		async add_new() {
			this.local_data.add_data.selected = false;
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = import.meta.env.VITE_RESTAPI_SET_USER_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.usr_name = this.local_data.add_data.usr_name;
			post_data.usr_login_id = this.local_data.add_data.usr_login_id;
			post_data.usr_cmp_id = this.local_data.add_data.usr_cmp_id;
			post_data.usr_org_uuid = this.local_data.add_data.usr_org_uuid;
			post_data.usr_post = this.local_data.add_data.usr_post;
			post_data.usr_mail_address = this.local_data.add_data.usr_mail_address;
			post_data.usr_password = this.local_data.add_data.usr_password;
			post_data.usr_photo = this.local_data.add_data.usr_profile_img_src;
			post_data.usr_sort_order = String(this.local_data.add_data.usr_sort_order);
			post_data.usr_multi_auth_invalid = this.local_data.add_data.usr_multi_auth_invalid;

			try {
				let response = await axios.post(url, post_data);
				//console.log(this.$route.path+":add_response="+JSON.stringify(response.data) );
				if (response.data.result == "OK") {
					// 初期化
					this.local_data.add_data.usr_name = "";
					this.local_data.add_data.usr_login_id = "";
					this.local_data.add_data.usr_org_uuid = "";
					this.local_data.add_data.usr_post = "";
					this.local_data.add_data.usr_mail_address = "";
					this.local_data.add_data.usr_password = "";
					this.local_data.add_data.usr_profile_img_file = null;
					this.local_data.add_data.usr_profile_img_src = "";
					this.local_data.add_data.usr_sort_order = "";
					this.local_data.add_data.usr_multi_auth_invalid = "off";
					//選択状態解除
					this.local_data.add_data.selected = false;
					// リロード
					await this.users_count();
					await this.user_list();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
					this.local_data.add_data.usr_org_uuid = ""; //画面が空白になるので、データもクリアする
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				//console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				this.local_data.add_data.usr_org_uuid = ""; //画面が空白になるので、データもクリアする
			}
			// if(this.local_data.add_data.usr_dpt_uuid == 0) {
			// 	this.message.is_alert = true;
			// 	this.message.variant = "danger";
			// 	this.message.message = "登録ユーザーの部署が未選択です。";
			// } else {
			// 	this.message.is_alert = false;
			// 	this.message.variant = "";
			// 	this.message.message = "";
			// }
		},

		//2023_07↓
		//新規時画像削除ボタン
		img_delete_add() {
			this.local_data.add_data.usr_profile_img_file = null;
			this.local_data.add_data.usr_profile_img_src = "";
		},
		//編集時画像削除ボタン
		img_delete_edit() {
			this.local_data.edit_data.usr_profile_img_file = null;
			this.local_data.edit_data.usr_profile_img_src = "";
		},
		//2023_07↑
		// 編集ボタン押下
		edit_row(row) {
			if (this.local_data.row != null) {
				if (this.local_data.selected) {
					this.local_data.row.toggleDetails();
				}
			}
			this.local_data.add_data.selected = false; // 新規画面閉じる

			this.local_data.edit_data.usr_uuid = row.item.usr_uuid;
			this.local_data.edit_data.usr_name = row.item.usr_name;
			this.local_data.edit_data.usr_login_id = row.item.usr_login_id;
			this.local_data.edit_data.usr_cmp_id = row.item.usr_cmp_id;
			this.local_data.edit_data.usr_org_uuid = row.item.usr_org_uuids;
			this.local_data.edit_data.usr_org_name = row.item.usr_org_name;
			this.local_data.edit_data.usr_post = row.item.usr_post;
			this.local_data.edit_data.usr_mail_address = row.item.usr_mail_address;
			this.local_data.edit_data.usr_password = "";
			this.local_data.edit_data.usr_profile_img_file = null; //usr_photo
			//2023_07 画像があればファイル選択扱いに設定 ""," "は画像無し状態
			if (row.item.usr_photo != "" && row.item.usr_photo != " ") {
				this.local_data.edit_data.usr_profile_img_src = row.item.usr_photo;
			} else {
				this.local_data.edit_data.usr_profile_img_src = ""; //usr_photo
			}
			//			this.local_data.edit_data.usr_profile_img_src = ""; //usr_photo
			this.local_data.edit_data.cmp_name = row.item.cmp_name;
			this.local_data.edit_data.usr_sort_order = row.item.usr_sort_order;
			this.local_data.edit_data.usr_multi_auth_invalid = row.item.usr_multi_auth_invalid;

			this.local_data.selected = true;
			row.toggleDetails();
			this.local_data.row = row;
		},
		// 更新実行
		async update_row() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = import.meta.env.VITE_RESTAPI_SET_USER_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.usr_uuid = this.local_data.edit_data.usr_uuid;
			post_data.usr_name = this.local_data.edit_data.usr_name;
			post_data.usr_login_id = this.local_data.edit_data.usr_login_id;
			post_data.usr_cmp_id = this.local_data.edit_data.usr_cmp_id;
			post_data.usr_org_uuid = this.local_data.edit_data.usr_org_uuid;
			post_data.usr_post = this.local_data.edit_data.usr_post;
			post_data.usr_mail_address = this.local_data.edit_data.usr_mail_address;
			post_data.usr_password = this.local_data.edit_data.usr_password;
			//2023_07 削除操作行われていれば空に
			if (
				this.local_data.add_data.usr_profile_img_file == null &&
				(this.local_data.edit_data.usr_profile_img_src == "" || this.local_data.edit_data.usr_profile_img_src == " ")
			) {
				post_data.usr_photo = " ";
			} else {
				post_data.usr_photo = this.local_data.edit_data.usr_profile_img_src;
			}
			post_data.usr_sort_order = String(this.local_data.edit_data.usr_sort_order);
			post_data.usr_multi_auth_invalid = this.local_data.edit_data.usr_multi_auth_invalid;

			try {
				let response = await axios.put(url, post_data);
				//console.log(this.$route.path+":upd_response="+JSON.stringify(response.data) );
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					// リロード
					await this.users_count();
					await this.user_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				//console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}

			// if(this.local_data.edit_data.usr_dpt_uuid == 0) {
			// 	this.message.is_alert = true;
			// 	this.message.variant = "danger";
			// 	this.message.message = "部署が未選択です。";
			// } else {
			// 	this.message.is_alert = false;
			// 	this.message.variant = "";
			// 	this.message.message = "";
			// }
		},
		cancel_row(row) {
			this.message.is_alert = false;
			this.message.variant = "";
			this.message.message = "";
			this.local_data.selected = false;
			row.toggleDetails();
		},
		delete_confirm() {
			this.$refs["confirm-modal"].show();
		},
		delete_cancel() {
			this.$refs["confirm-modal"].hide();
		},
		// 削除実行
		async delete_row() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = import.meta.env.VITE_RESTAPI_SET_USER_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.usr_uuid = this.local_data.edit_data.usr_uuid;
			post_data.org_uuid = this.local_data.edit_data.usr_org_uuid;

			//console.log(this.$route.path+":del post_data="+ JSON.stringify(post_data));
			try {
				let response = await axios.delete(url, { data: post_data });
				//console.log(this.$route.path+":del_response="+JSON.stringify(response.data) );
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					// リロード
					await this.users_count();
					await this.user_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				//console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				this.$refs["confirm-modal"].hide();
			}
			this.$refs["confirm-modal"].hide();
		},
		addFormatNames() {
			this.formatNames(this.local_data.add_data.usr_profile_img_file, this.local_data.add_data);
		},
		editFormatNames() {
			this.formatNames(this.local_data.edit_data.usr_profile_img_file, this.local_data.edit_data);
		},
		formatNames(fileElement, data) {
			if (fileElement == null) {
				return;
			}
			this.loader.is_loading = true;
			let reader = new FileReader();
			reader.onload = () => {
				Jimp.read(reader.result)
					.then((image) => {
						// Do stuff with the image.
						image
							.cover({ w: 48, h: 48 })
							.getBase64(JimpMime.png)
							.then((src) => {
								//console.log(src);
								data.usr_profile_img_src = src;
								// ローディング終了
								this.loader.is_loading = false;
							})
							.catch((err) => {
								// Handle an exception.
								console.log("err=" + JSON.stringify(err));
								this.loader.is_loading = false;
							});
					})
					.catch((err) => {
						// Handle an exception.
						console.log("err=" + JSON.stringify(err));
						this.loader.is_loading = false;
					});
			};
			reader.readAsDataURL(fileElement);
		},

		async download() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;
			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			let url =
				import.meta.env.VITE_RESTAPI_GET_USER_CSV +
				"?sid=" +
				this.$store.state.sid +
				"&cmp_id=" +
				this.bind_data.search_cmp_id +
				"&usr_name=" +
				this.bind_data.search_name;
			//console.log(this.$route.path+":url="+url);
			try {
				let response = await axios.get(url);
				//console.log(this.$route.path+":response="+JSON.stringify(response.data));
				if (response.data.result == "OK") {
					let rows = [];
					for (let i = 0; i < response.data.user_list.length; i++) {
						let cols = response.data.user_list[i];
						rows.push('"' + cols.join('","') + '"');
					}

					let link = document.createElement("a");
					link.download = "user_list.csv";
					let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		async csv_upload() {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = import.meta.env.VITE_RESTAPI_SET_USERS_CSV;
			let post_data = new FormData();
			post_data.append("sid", this.$store.state.sid);
			post_data.append("csv_file", this.bind_data.csv_file);

			try {
				let response = await axios.post(url, post_data);
				console.log("csv_upload:response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.message.is_alert = true;
					this.message.variant = "info";
					this.message.message = response.data.message;
					// リロード
					await this.users_count();
					await this.user_list();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 会社ドロップダウンリスト選択値変更（新規登録時）
		async add_cmp_change() {
			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;

			await this.set_org_map("selslf", this.local_data.add_data.usr_cmp_id, null);
			this.bind_data.search_cmp_id = this.local_data.add_data.usr_cmp_id;
			await this.users_count();
			await this.seach_users_list();

			this.state_data.treeselectdialogs = true;

			// ローディング終了
			this.loader.is_loading = false;
		},

		// 会社ドロップダウンリスト選択値変更（編集時）
		async edit_cmp_change() {
			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;

			await this.set_org_map("selslf", this.local_data.edit_data.usr_cmp_id, null);

			this.state_data.treeselectdialogs = true;

			// ローディング終了
			this.loader.is_loading = false;
		},
	},
};
</script>
