// ----------------------------------------------------------------
// 権限関数
// perm.js Ver 1.0
// ----------------------------------------------------------------

/**
 * 指定した権限を持っているかをチェックする
 * ALLSYS,会社SYSが常に使える場合
 * @param {*} perm 権限
 */
function hasPerm(store, perm) {
	const idx = store.state.users_info.organization_list.findIndex((o) => o.org_ath_uuids.indexOf(perm) !== -1);
	const idxAllSys = store.state.users_info.organization_list.findIndex((o) => o.org_ath_uuids.indexOf("all_sys") !== -1);
	const idxCmpSys = store.state.users_info.organization_list.findIndex((o) => o.org_ath_uuids.indexOf("cmp_sys") !== -1);
	return idx !== -1 || idxAllSys !== -1 || idxCmpSys !== -1;
}
/**
 * 指定した権限を持っているかをチェックする
 * ALLSYSのみ常に使える場合
 * @param {*} perm 権限
 */
function hasPerm2(store, perm) {
	const idx2 = store.state.users_info.organization_list.findIndex((o) => o.org_ath_uuids.indexOf(perm) !== -1);
	const idxAllSys2 = store.state.users_info.organization_list.findIndex((o) => o.org_ath_uuids.indexOf("all_sys") !== -1);
	return idx2 !== -1 || idxAllSys2 !== -1;
}

export default {
	computed: {
		/**
		 * スーパーユーザーALLSYS
		 */
		hasAllSysPerm() {
			return hasPerm2(this.$store, "all_sys");
		},

		/**
		 * スーパーユーザー会社SYS
		 */
		hasCmpSysPerm() {
			return hasPerm(this.$store, "cmp_sys");
		},

		/**
		 * 事案に図面を登録出来る権限
		 */
		hasDrawingRegistPerm() {
			return hasPerm(this.$store, "mbl_drawing_regist");
		},

		// 権限整理により廃止
		// /**
		//  * 会社を設定出来る権限
		//  */
		//  hasWebCompanysSettingPerm() {
		//     return hasPerm(this.$store, 'web_companys_setting');
		// },

		// 権限整理により廃止
		// /**
		//  * 権限を会社に付与出来る権限
		//  */
		//  hasWebCompanysAuthorizationPerm() {
		//     return hasPerm(this.$store, 'web_companys_authorization');
		// },

		/**
		 * 組織を設定出来る権限
		 */
		hasWebOrganizationSettingPerm() {
			return hasPerm(this.$store, "web_organization_setting");
		},

		/**
		 * ユーザーを設定出来る権限
		 */
		hasWebUsersSettingPerm() {
			return hasPerm(this.$store, "web_users_setting");
		},

		/**
		 * 管理対象を設定出来る権限
		 */
		hasWebManagedSettingPerm() {
			return hasPerm2(this.$store, "web_managed_setting");
		},

		/**
		 * 管理対象の図面を設定出来る権限
		 */
		hasWebDrawingSettingPerm() {
			return hasPerm2(this.$store, "web_drawing_setting");
		},

		/**
		 * 区分を設定出来る権限
		 */
		hasWebClassesSettingPerm() {
			return hasPerm2(this.$store, "web_classes_setting");
		},

		/**
		 * フォームを編集出来る権限
		 */
		hasWebInputFormSettingPerm() {
			return hasPerm2(this.$store, "web_input_form_setting");
		},

		/**
		 * 出力帳票を設定出来る権限
		 */
		hasWebOutputFormSettingPerm() {
			return hasPerm2(this.$store, "web_output_form_setting");
		},

		/**
		 * 定期レポートを設定出来る権限
		 */
		hasWebRegularReportSettingPerm() {
			return hasPerm(this.$store, "web_regular_report_setting");
		},

		/**
		 * お知らせを設定出来る権限
		 */
		hasWebNewsSettingPerm() {
			return hasPerm(this.$store, "web_news_setting");
		},

		/**
		 * 所属先の変更申請を承認出来る権限
		 */
		hasWebAffiliationApprovalPerm() {
			return hasPerm2(this.$store, "web_affiliation_approval");
		},

		/**
		 * BCP管理者
		 */
		hasBbcpManagerPerm() {
			return hasPerm2(this.$store, "bcp_manager");
		},

		/**
		 * BCP一般
		 */
		hasBcpUserPerm() {
			return hasPerm2(this.$store, "bcp_user");
		},

		/**
		 * BCP閲覧者
		 */
		hasBcpViewerPerm() {
			return hasPerm2(this.$store, "bcp_viewer");
		},

		/**
		 * 所属先の変更を申請出来る権限
		 */
		hasWebAffiliationApplicationPerm() {
			return hasPerm(this.$store, "web_affiliation_application");
		},

		/**
		 * お知らせを表示出来る権限
		 */
		hasWebNewsDisplayPerm() {
			return hasPerm(this.$store, "web_news_display");
		},

		/**
		 * 報告データの詳細を閲覧できる権限
		 */
		hasReportBrowsingPerm() {
			return hasPerm2(this.$store, "web_report_browsing");
		},

		/**
		 * 報告データをダウンロード出来る権限
		 */
		hasReportDownloadPerm() {
			return hasPerm2(this.$store, "web_report_download");
		},

		/**
		 * 報告データを編集出来る権限
		 */
		hasReportEditPerm() {
			return hasPerm2(this.$store, "web_report_edit");
		},

		// 権限整理により廃止
		// /**
		//  * 報告データを確認出来る権限
		//  */
		// hasReportConfirmPerm() {
		//     return hasPerm2(this.$store, 'web_report_confirm');
		// },

		// 権限整理により廃止
		// /**
		//  * 報告データを完了・完了解除出来る権限
		//  */
		// hasReportCompletedPerm() {
		//     return hasPerm2(this.$store, 'web_report_completed');
		// },

		/**
		 * 報告データの編集を完了出来る権限
		 */
		hasReportEditCompletedPerm() {
			return hasPerm2(this.$store, "web_report_edit_completed");
		},

		// 権限整理により廃止
		// /**
		//  * 報告データにコメント出来る権限
		//  */
		// hasReportCommentPerm() {
		//     return hasPerm2(this.$store, 'web_report_comment');
		// },

		/**
		 * 報告データにコメントし確認済みに出来る権限
		 */
		hasReportCommentVerifiedPerm() {
			return hasPerm2(this.$store, "web_report_comment_verified");
		},

		/**
		 * 報告データを確認済みに出来る権限
		 */
		hasReportVerifiedPerm() {
			return hasPerm2(this.$store, "web_report_verified");
		},

		/**
		 * 報告データの書庫データを閲覧出来る権限
		 */
		hasArchiveBrowsingPerm() {
			return hasPerm2(this.$store, "web_archive_browsing");
		},

		// 権限整理により廃止
		// /**
		//  * 報告データを管理出来る権限
		//  */
		//  hasWebReportManagePerm() {
		//     return hasPerm2(this.$store, 'web_report_manage');
		// },

		/**
		 * 書庫データを登録出来る権限
		 */
		hasArchiveRegistPerm() {
			return hasPerm2(this.$store, "web_archive_regist");
		},

		// 権限整理により廃止
		// /**
		//  * 事案を編集出来る権限
		//  */
		// hasWebBasicsEditPerm() {
		//     return hasPerm(this.$store, 'web_basics_edit');
		// },

		// 権限整理により廃止
		// /**
		//  * 事案情報を共有（シェア）出来る権限
		//  */
		// hasWebBasicsSharePerm() {
		//     return hasPerm(this.$store, 'web_basics_share');
		// },

		// 権限整理により廃止
		// /**
		//  * 事案を処置完了・処置完了解除出来る権限
		//  */
		//  hasWebTreatmentCompletedPerm() {
		//     return hasPerm(this.$store, 'web_treatment_completed');
		// },

		// 権限整理により廃止
		// /**
		//  * 事案に図面を登録出来る権限
		//  */
		//  hasWebDrawingRegistPerm() {
		//     return hasPerm(this.$store, 'web_drawing_regist');
		// },

		// 権限整理により廃止
		// /**
		//  * タイムラインを入力・編集出来る権限
		//  */
		// hasWebTimelineInputPerm() {
		//     return hasPerm(this.$store, 'web_timeline_input');
		// },

		// 権限整理により廃止
		// /**
		//  * タイムラインにコメント出来る権限
		//  */
		//  hasWebTimelineCommentPerm() {
		//     return hasPerm(this.$store, 'web_timeline_comment');
		// },

		// 権限整理によりモバイル権限と統合
		// /**
		//  * 相談する事が出来る権限
		//  */
		// hasWebConsultationPerm() {
		//     return hasPerm(this.$store, 'web_consultation');
		// },
		/**
		 * 相談する事が出来る権限
		 */
		hasConsultPerm() {
			return hasPerm2(this.$store, "mbl_consultation");
		},

		// 権限整理により廃止
		// /**
		//  * 相談する事が出来る権限
		//  */
		// hasWebTimelineConsultationPerm() {
		//     return hasPerm(this.$store, 'web_timeline_consultation');
		// },

		// 権限整理により廃止
		// /**
		//  * 配信する事が出来る権限
		//  */
		//  hasWebDeliveryPerm() {
		//     return hasPerm(this.$store, 'web_delivery');
		// },
	},
};
