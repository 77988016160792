<template>
	<div class="Login">
		<!-- ココを更新 -->
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<Header></Header>
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>

		<!-- メインコンテンツ -->
		<div class="container" align="center">
			<!-- ログイン -->
			<div
				class="p-2 mt-3"
				style="border: 3px solid red; text-align: left; font-size: 1.1rem; margin-bottom: 2rem; max-width: 32rem">
				ログインはまだ完了しておりません。<br />
				認証番号を登録メールアドレスに送信しました。<br />
				6桁の認証番号を入力してログインを完了してください。
			</div>
			<b-card
				border-variant="primary"
				class="mt-3"
				header-text-variant="white"
				header-bg-variant="primary"
				header-tag="h3"
				style="max-width: 32rem">
				<div class="text-start">
					<label class="mb-0 font-weight-bold">認証番号</label>
				</div>
				<b-form-input
					v-model="bind_data.auth_number"
					required
					type="text"
					maxlength="6"
					placeholder="認証番号を入力してください"></b-form-input>
				<b-row>
					<b-col>
						<div class="d-grid">
							<b-button variant="secondary" block v-on:click="back()" class="mt-3">ログインに戻る</b-button>
						</div>
					</b-col>
					<b-col>
						<div class="d-grid"><b-button variant="primary" block v-on:click="auth()" class="mt-3">認証</b-button></div>
					</b-col>
				</b-row>
			</b-card>
			<div class="mt-3 p-1 text-secondary" style="text-align: left; font-size: 0.9rem; max-width: 32rem">
				認証コードが届かない場合は、再度ログイン画面からやりなおしてください。
			</div>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import common from "@/lib/common.js";
import Header from "@/components/Header.vue";
import axios from "axios";
import CommonDataSet from "@/lib/CommonDataSet.js";
import { BAlert, BFormInput, BButton, BCol, BRow, BCard } from "bootstrap-vue-next";

export default {
	name: "Login", // ココを更新
	components: {
		Loading,
		Header,
		BAlert,
		BFormInput,
		BButton,
		BCol,
		BRow,
		BCard,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// props: {
	// 	login_cmp_id: String,
	// 	login_usr_id: String,
	// 	login_password: String,
	// },

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},

			// バインドデータ
			bind_data: {
				auth_number: "",
			},

			// ローカルデータ
			local_data: {},

			// 状態ストレージ
			state_data: {},

			// propsの代替
			login_cmp_id: "",
			login_usr_id: "",
			login_password: "",
		};
	},

	// 既定計算
	computed: {},

	// インスタンス初期化後
	created() {
		this.login_cmp_id = history.state.login_cmp_id;
		this.login_usr_id = history.state.login_usr_id;
		this.login_password = history.state.login_password;
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");
	},

	// ローカル関数
	methods: {
		async auth() {
			if (this.bind_data.auth_number == "") {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "認証番号を入力してください";

				return;
			}

			// ローディング開始
			this.loader.is_loading = true;

			// ログインを行い、ユーザー情報を取得（権限を含む）
			let url = import.meta.env.VITE_RESTAPI_MULTI_AUTH_LOGIN;
			let post_data = {
				login_cmp_id: this.login_cmp_id,
				login_usr_id: this.login_usr_id,
				login_password: this.login_password,
				auth_number: this.bind_data.auth_number,
			};

			console.log(this.$route.path + ":regist:url=" + url);

			try {
				let response = await axios.post(url, post_data);

				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					// グローバル変数（store）に格納
					this.$store.dispatch("commit_set_sid", response.data.sid);

					// ユーザー情報をグローバル変数に格納
					let user_info = response.data.user_info;

					user_info.organization_list = response.data.organization_list;
					this.$store.dispatch("commit_set_users_info", user_info);
					this.$store.dispatch("commit_set_system_info", response.data.system_param);

					// 区分情報を取得しグローバル変数に格納
					let result2 = await this.set_class_list();

					if (!result2) {
						// ローディング終了
						this.loader.is_loading = false;

						return;
					}

					// 組織情報を取得しローカルストレージに格納
					let result3 = await this.set_org_map();

					if (!result3) {
						// ローディング終了
						this.loader.is_loading = false;

						return;
					}

					// クッキーに保存(仮)
					this.$cookies.config("72h");
					this.$cookies.set("sid", response.data.sid);
					//this.$cookies.set( 'login_cmp_id', this.login_cmp_id );
					//this.$cookies.set( 'login_usr_id', this.login_usr_id );

					//画面遷移
					this.transition("Home");
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;

					// ローディング終了
					this.loader.is_loading = false;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		back() {
			// 画面遷移
			this.transition("Login");
		},
	},
};
</script>
