<template>
	<div ref="container">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>

		<template v-if="state_data.bas_info && state_data.isView">
			<!-- 事案データ表示 -->
			<b-card header-bg-variant="light" class="mx-2 mb-4 p-0">
				<template #header>
					<h4 class="m-0 p-0 font-weight-bold">件名</h4>
				</template>
				<b-badge v-if="bind_data.bas_info.bas_rpt_type === 'emergency'" variant="danger" class="p-2">{{
					bind_data.bas_info.bas_rpt_type_name
				}}</b-badge>
				{{ bind_data.bas_info.bas_subject }}
			</b-card>
			<b-card header-bg-variant="light" class="mx-2 mb-4 p-0">
				<template #header>
					<h4 class="m-0 p-0 font-weight-bold">事案番号</h4>
				</template>
				{{ bind_data.bas_info.bas_case_number }}
			</b-card>
			<b-card header-bg-variant="light" class="mx-2 mb-4 p-0">
				<template #header>
					<h4 class="m-0 p-0 font-weight-bold">発生日時</h4>
				</template>
				{{ bind_data.bas_info.bas_occur_date_time.substr(0, 16) }}
			</b-card>
			<b-card header-bg-variant="light" class="mx-2 mb-4 p-0">
				<template #header>
					<h4 class="m-0 p-0 font-weight-bold">管理対象</h4>
				</template>
				<span v-for="bas_mng_info in bind_data.bas_info.bas_mng_list" :key="bas_mng_info.bas_mng_uuid" class="me-2">
					{{ bas_mng_info.bas_mng_uuid_name }}
				</span>
			</b-card>

			<FormDataUtils v-if="state_data.bas_info" :form_info="bind_data.form_info" :form_data="bind_data.form_data"></FormDataUtils>

			<b-card header-bg-variant="light" class="mx-2 mb-4">
				<template #header>
					<h4 class="mb-0 font-weight-bold">図面</h4>
				</template>
				<template v-if="bind_data.bas_info.bas_draw_info_exist === 'yes'">
					<div>
						<b-button variant="outline-secondary" class="ms-2 mt-2 float-end" @click="handleToggleDrawRotate()"
							>図面を表示
							<BootstrapIcon icon="caret-down-fill" :rotate="local_data.drawRotate"></BootstrapIcon>
						</b-button>
					</div>
					<div class="pt-2" style="clear: both">
						<template v-if="local_data.drawRotate === 180">
							<template v-for="draw2 in local_data.drawImage" :key="draw2">
								<b-card-group deck class="mt-3">
									<template v-for="draw in draw2" :key="draw">
										<b-card :header="draw.name" class="text-center">
											<b-card-text>
												<b-img :src="draw.image" class="w-100"></b-img>
											</b-card-text>
										</b-card>
									</template>
								</b-card-group>
							</template>
						</template>
					</div>
				</template>
				<template v-if="bind_data.bas_info.bas_draw_info_exist !== 'yes'"> 図面なし </template>
			</b-card>

			<!-- タイムラインデータ表示 -->
			<div v-if="state_data.bas_info">
				<div class="pt-2 mb-4" v-for="tml_item in bind_data.bas_info.timeline_list" :key="tml_item.tml_uuid">
					<div class="ms-2 font-weight-bold">タイムライン</div>
					<b-card class="ms-2">
						<b-row>
							<b-col xs="12" sm="12" md="6" lg="6" xl="6">
								<b-card header-bg-variant="light" class="mb-2">
									<template #header>
										<h4 class="mb-0 font-weight-bold">日時</h4>
									</template>
									{{ tml_item.tml_rpt_date_time.substr(0, 16) }}
								</b-card> </b-col
							><b-col xs="12" sm="12" md="6" lg="6" xl="6">
								<b-card header-bg-variant="light" class="mb-2">
									<template #header>
										<h4 class="mb-0 font-weight-bold">報告者</h4>
									</template>
									{{ tml_item.tml_reporter_name }}
								</b-card>
							</b-col>
						</b-row>
						<b-card header-bg-variant="light" class="mb-2">
							<template #header>
								<h4 class="mb-0 font-weight-bold">状況（説明）</h4>
							</template>
							<template v-for="statusExplain in statusExplains(tml_item.tml_status_explain)" :key="statusExplain">
								{{ statusExplain }}<br />
							</template>
						</b-card>

						<template v-if="tml_item.tml_media_type === 'image'">
							<!-- <div v-for="(media, index) in tml_item.mediaList" style="clear: both;" :key="media"> -->
							<div v-for="media in tml_item.mediaList" style="clear: both" :key="media">
								<div class="float-end">
									<b-button
										variant="outline-secondary"
										class="ms-2 mt-2"
										@click="handleToggleRotate(media.fileUuid, tml_item.tml_media_type, media.dataUrl)">
										画像を表示
										<BootstrapIcon icon="caret-down-fill" :rotate="rotate(media.fileUuid)"></BootstrapIcon>
									</b-button>
								</div>
								<div v-if="rotate(media.fileUuid) === 180" style="clear: both">
									<div style="float: right">
										<b-img :src="local_data.imageSrc" fluid max-width="800" class="mt-2"></b-img>
									</div>
								</div>
							</div>
						</template>

						<template v-if="tml_item.tml_media_type === 'video'">
							<div v-for="(media, index) in tml_item.mediaList" style="clear: both" :key="media">
								<div class="float-end">
									<b-button
										variant="outline-secondary"
										class="ms-2 mt-2"
										@click="handleDownloadVideo(tml_item.tml_save_id, index)">
										動画をダウンロード
									</b-button>
									<b-button
										variant="outline-secondary"
										class="ms-2 mt-2"
										@click="handleToggleRotate(media.fileUuid, tml_item.tml_media_type, media.dataUrl)">
										動画を表示
										<BootstrapIcon icon="caret-down-fill" :rotate="rotate(media.fileUuid)"></BootstrapIcon>
									</b-button>
								</div>
								<div v-if="rotate(media.fileUuid) === 180" style="clear: both">
									<div style="float: right">
										<video-player :options="local_data.video_options" class="mt-2" />
									</div>
								</div>
							</div>
						</template>
					</b-card>
				</div>
			</div>
		</template>
		<a ref="videoDownload"></a>
	</div>
</template>

<style></style>

<script>
import FormDataUtils from "@/components/FormDataUtils.vue";
import axios from "axios";
import VideoPlayer from "@/components/VideoPlayer.vue";
import "video.js/dist/video-js.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import image from "@/lib/image.js";
import { mapState } from "vuex";
import {
	BAlert,
	BButton,
	BCard,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
	BCardGroup,
	BModal,
	BCol,
	BRow,
	BCollapse,
	vBToggle,
	BImg,
} from "bootstrap-vue-next";

export default {
	name: "BaseDataUtils",

	components: {
		FormDataUtils,
		VideoPlayer,
		Loading,
		BAlert,
		BButton,
		BCard,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
		BCardGroup,
		BModal,
		BCol,
		BRow,
		BCollapse,
		vBToggle,
		BImg,
	},

	mixins: [image],

	props: {
		bas_info: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			bind_data: {
				bas_info: null,
				form_info: null,
				form_data: null,
			},
			state_data: {
				bas_info: false,
				isView: false,
			},
			local_data: {
				drawRotate: 0,
				drawImage: [],
				toggleRotate: null,
				rotate: 0,
				imageSrc: null,
				video_options: {
					width: 640, // 幅
					height: 360, // 高さ
					autoplay: true,
					controls: true,
					sources: [
						{
							src: "",
							type: "video/mp4",
						},
					],
				},
			},
		};
	},
	watch: {
		"bind_data.bas_info": function () {
			if (this.bind_data.bas_info != null) {
				this.state_data.bas_info = true;
			}
		},
	},

	computed: {
		...mapState(["system_info"]),

		timelinePictureCount() {
			return this.system_info.timeline_picture_count;
		},

		timelineMovieCount() {
			return this.system_info.timeline_movie_count;
		},

		statusExplains: function () {
			return function (orgStatusExplain) {
				return orgStatusExplain.split("\n");
			};
		},

		rotate() {
			return (fileUuid) => {
				if (this.local_data.toggleRotate === fileUuid) {
					return this.local_data.rotate;
				} else {
					return 0;
				}
			};
		},

		_rotate: function () {
			return function (tml) {
				if (this.local_data.toggleRotate === tml.tml_uuid) {
					return this.local_data.rotate;
				} else {
					return 0;
				}
			};
		},
	},

	async created() {},

	async mounted() {
		this.loader.is_loading = true;
		this.state_data.isView = false;

		this.bind_data.bas_info = this.bas_info;
		this.bind_data.form_info = this.$store.state.form_info;
		this.bind_data.form_data = JSON.parse(this.bas_info.bas_element);

		for (let i = 0; i < this.bind_data.bas_info.timeline_list.length; i++) {
			const tml = this.bind_data.bas_info.timeline_list[i];
			const tmlRptDatetime = tml.tml_rpt_date_time.split(" ");

			tml.tml_rpt_date = tmlRptDatetime[0];
			tml.tml_rpt_time = tmlRptDatetime[1];
			tml.selectedFile = null;
			tml.orgIdx = i;

			let tmlSaveIdJson = JSON.parse(tml.tml_save_id);
			console.log(tmlSaveIdJson);

			let mediaList = Array();

			// 複数添付未対応への対応
			if (tml.tml_web_view_url && !tml.tml_web_view_url.startsWith("{")) {
				// 複数添付未対応のバージョンで登録されたデータの場合

				console.log(tml.tml_web_view_url);

				let dataUrl = await this.getS3Media(tml.tml_web_view_url);
				let media = {
					fileUuid: this.getFileUuid(tml.tml_web_view_url),
					blob: null,
					dataUrl: dataUrl,
					width: "",
					height: "",
					isEditMode: false,
					isAttached: true,
					attachedIndex: 0,
				};

				mediaList.push(media);
			} else {
				let webViewUrlInfo = JSON.parse(tml.tml_web_view_url);

				if (webViewUrlInfo && webViewUrlInfo.url_list) {
					for (let idx in webViewUrlInfo.url_list) {
						let webViewUrl = webViewUrlInfo.url_list[idx];
						let dataUrl = await this.getS3Media(webViewUrl);
						let media = {
							fileUuid: this.getFileUuid(webViewUrl),
							blob: null,
							dataUrl: dataUrl,
							width: "",
							height: "",
							isEditMode: false,
							isAttached: true,
							attachedIndex: idx,
						};

						mediaList.push(media);
					}
				}
			}

			tml.mediaList = mediaList;
			this.bind_data.bas_info.timeline_list[i] = tml;
		}

		this.state_data.isView = true;
		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		getFileUuid: function (webViewUrl) {
			const urls = webViewUrl.split("?");
			const params = urls[1].split("&");
			const fileName = params.find((p) => p.substr(0, 10) === "file_name=");

			return fileName.replace(/^.*=/, "").replace(/\..*$/, "");
		},

		getS3Media: async function (webViewUrl) {
			try {
				console.log("import.meta.env.VITE_GET_LIMIT_URL = " + import.meta.env.VITE_GET_LIMIT_URL);
				console.log("import.meta.env.VITE_API_KEY = " + import.meta.env.VITE_API_KEY);

				const urls = webViewUrl.split("?");
				const params = urls[1].split("&");
				const basUuid = params.find((p) => p.substr(0, 9) === "bas_uuid=");
				const tmlUuid = params.find((p) => p.substr(0, 9) === "tml_uuid=");
				const fileName = params.find((p) => p.substr(0, 10) === "file_name=");
				const url = `${import.meta.env.VITE_GET_LIMIT_URL}?sid=${
					this.$store.state.sid
				}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
				const config = {
					headers: {
						"x-api-key": import.meta.env.VITE_API_KEY,
					},
				};

				const res = await this.$http.get(url, config);

				if (res.data.result === "OK") {
					return res.data.url;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;

					return "";
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "エラーが発生しました。" + JSON.stringify(e);

				return "";
			}
		},

		async handleToggleRotate(ftlUuid, tmlMediaType, dataUrl) {
			this.loader.is_loading = true;

			if (this.local_data.toggleRotate === ftlUuid) {
				this.local_data.rotate = this.local_data.rotate === 0 ? 180 : 0;
			} else {
				if (tmlMediaType === "image") {
					this.local_data.imageSrc = dataUrl;
				} else if (tmlMediaType === "video") {
					this.local_data.video_options.sources[0].src = dataUrl;
				}

				this.local_data.toggleRotate = ftlUuid;
				this.local_data.rotate = 180;
			}

			this.loader.is_loading = false;
		},

		/**
		 * タイムラインの画像、動画を取得する
		 * @param {*} tml
		 */
		async _handleToggleRotate(tml) {
			if (this.local_data.toggleRotate === tml.tml_uuid) {
				this.local_data.rotate = this.local_data.rotate === 0 ? 180 : 0;
			} else {
				this.loader.is_loading = true;
				await this.$nextTick();

				const urls = tml.tml_web_view_url.split("?");
				const params = urls[1].split("&");
				const basUuid = params.find((p) => p.substr(0, 9) === "bas_uuid=");
				const tmlUuid = params.find((p) => p.substr(0, 9) === "tml_uuid=");
				const fileName = params.find((p) => p.substr(0, 10) === "file_name=");

				const url = `${import.meta.env.VITE_GET_LIMIT_URL}?sid=${
					this.$store.state.sid
				}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
				const config = {
					headers: {
						"x-api-key": import.meta.env.VITE_API_KEY,
					},
				};

				try {
					const res = await axios.get(url, config);
					if (res.data.result === "OK") {
						if (tml.tml_media_type === "image") {
							this.local_data.imageSrc = res.data.url;
						} else if (tml.tml_media_type === "video") {
							this.local_data.video_options.sources[0].src = res.data.url;
						}
						this.local_data.toggleRotate = tml.tml_uuid;
						this.local_data.rotate = 180;
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch (e) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = "エラーが発生しました。" + JSON.stringify(e);
				}
				this.loader.is_loading = false;
			}
		},

		/**
		 * 図面を表示する
		 * @returns
		 */
		async handleToggleDrawRotate() {
			this.local_data.drawRotate = this.local_data.drawRotate === 0 ? 180 : 0;
			if (this.local_data.drawRotate === 0) {
				return;
			}

			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				const draw = JSON.parse(this.bind_data.bas_info.bas_draw_info);

				const drawInfoList = [];
				if (Array.isArray(draw)) {
					drawInfoList.push(...draw);
				} else {
					drawInfoList.push(draw);
				}

				this.local_data.drawImage = [];
				let drawImage = [];
				for (let i = 0; i < drawInfoList.length; i++) {
					const config = {
						params: {
							sid: this.$store.state.sid,
							bucket_name: drawInfoList[i].bucket_name,
							bucket_key: drawInfoList[i].bucket_key,
							file_name: drawInfoList[i].file_name,
						},
					};

					const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_S3_MEDIA_FILE, config);
					if (res.data.result === "OK") {
						drawImage.push({
							name: drawInfoList[i].drw_name,
							image: `data:${drawInfoList[i].content_type};base64,${res.data.media_file}`,
						});
						this.$store.dispatch("commit_set_sid", res.data.sid);

						if (drawImage.length == 2) {
							this.local_data.drawImage.push(drawImage);
							drawImage = [];
						}
					} else {
						this.$emit("error", res.data.message);
					}
				}
				//画像の数が奇数の時にスペースを追加
				if (drawImage.length > 0) {
					if (drawImage.length == 1) {
						drawImage.push({
							name: "",
							file_name: "",
							image: "",
						});
					}

					this.local_data.drawImage.push(drawImage);
				}
			} catch (e) {
				this.$emit("error", "エラーが発生しました。" + JSON.stringify(e));
			}
			this.loader.is_loading = false;
		},

		/**
		 * タイムラインに登録されている動画をダウンロードする
		 */
		async handleDownloadVideo(tmlSaveId, index) {
			if (!tmlSaveId) {
				return;
			}

			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				let saveIdJson = JSON.parse(tmlSaveId);
				let imageList = saveIdJson.image_list;
				let contentType = "";
				let fileName = "";

				if (imageList) {
					contentType = imageList[index].content_type;
					fileName = imageList[index].file_name;
				} else {
					contentType = saveIdJson.content_type;
					fileName = saveIdJson.file_name;
				}

				const config = {
					params: {
						sid: this.$store.state.sid,
						bucket_name: saveIdJson.download_bucket_name,
						bucket_key: saveIdJson.bucket_key,
						file_name: fileName,
					},
				};
				const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_S3_MEDIA_FILE, config);
				if (res.data.result === "OK") {
					const blob = this.base64ToBlobImage(res.data.media_file, contentType);
					const url = window.URL.createObjectURL(blob);
					const link = this.$refs.videoDownload;
					link.href = url;
					link.download = fileName;
					link.click();
				} else if (res.data.result === "NG") {
					this.$emit("message", {
						variant: "danger",
						message: res.data.message,
					});
				} else if (res.data.result === "ERR") {
					this.$emit("message", {
						variant: "danger",
						message: "既に動画は削除されています",
					});
				}
			} catch (e) {
				this.$emit("message", {
					variant: "danger",
					message: `エラーが発生しました: ${JSON.stringify(e)}`,
				});
			}

			this.loader.is_loading = false;
		},
	},
};
</script>
