<template>
	<div class="OrganizationRegist">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<!-- 検索フィールド -->
			<div class="mt-3">
				<b-button variant="outline-secondary" v-on:click="search_toggle()"
					>検索条件
					<BootstrapIcon icon="caret-down-fill" :rotate="bind_data.search_toggle_rotate"></BootstrapIcon>
				</b-button>
				<b-card v-show="bind_data.search_toggle" class="card-shadow border-1">
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? 'text-end' : 'text-start'" xs="12" sm="12" md="2" lg="2" xl="2">事案番号</b-col>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<b-form-input
								v-model="local_data.bas_case_number"
								placeholder="事案番号を入力して検索してください"
								maxlength="100"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? 'text-end' : 'text-start'" xs="12" sm="12" md="2" lg="2" xl="2">発生日時</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-datepicker v-model="local_data.search_date" label-no-date-selected="検索範囲の開始日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
							class="m-0" :state="state_data.search_date" today-button reset-button close-button ></b-form-datepicker> -->
							<VueDatePicker
								v-model="local_data.search_date"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の開始日を選択"
								:state="state_data.search_date"
								:enable-time-picker="false"
								:clearable="true"
								class="mb-2"
								week-start="0"
								required
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
							<template #action-extra="{ selectCurrentDate }">
								<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
							</template>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-timepicker v-model="local_data.between_edit_start_time" label-no-time-selected="時刻を選択" 
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" :state="state_data.between_edit_start_time" reset-button close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="local_data.between_edit_start_time"
								model-type="HH:mm"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="state_data.between_edit_start_time"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="true"
								class="m-0"
								auto-apply
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col class="text-start" xs="12" sm="12" md="2" lg="2" xl="2"></b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-datepicker v-model="local_data.search_date_end" label-no-date-selected="検索範囲の終了日を選択"
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" 
							class="m-0" :state="state_data.search_date_end" today-button reset-button close-button ></b-form-datepicker> -->
							<VueDatePicker
								v-model="local_data.search_date_end"
								model-type="yyyy-MM-dd"
								locale="ja"
								:format="date_format"
								placeholder="検索範囲の終了日を選択"
								:state="state_data.search_date_end"
								:enable-time-picker="false"
								:clearable="true"
								class="mb-2"
								week-start="0"
								required
								auto-apply>
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'calendar']" style="margin-left: 12px" />
								</template>
								<template #action-extra="{ selectCurrentDate }">
									<b-button variant="outline-secondary" size="sm" @click="selectCurrentDate()">今日</b-button>
								</template>
							</VueDatePicker>
						</b-col>
						<b-col xs="12" sm="12" md="5" lg="5" xl="5">
							<!-- <b-form-timepicker v-model="local_data.between_edit_end_time" label-no-time-selected="時刻を選択" 
							:locale="datepicker.locale" v-bind="datepicker.labels[datepicker.locale] || {}"
							class="m-0" :state="state_data.between_edit_end_time" reset-button close-button now-button></b-form-timepicker> -->
							<VueDatePicker
								v-model="local_data.between_edit_end_time"
								model-type="HH:mm"
								locale="ja"
								format="HH:mm"
								placeholder="時刻を選択"
								:state="state_data.between_edit_end_time"
								time-picker
								:config="{ modeHeight: 150 }"
								:clearable="true"
								class="m-0"
								auto-apply
								:action-row="{ showCancel: false }"
								select-text="閉じる">
								<template #input-icon>
									<font-awesome-icon :icon="['far', 'clock']" style="margin-left: 12px" />
								</template>
							</VueDatePicker>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col :class="isNotMobilePhone ? 'text-end' : 'text-start'" xs="12" sm="12" md="2" lg="2" xl="2">事案名</b-col>
						<b-col xs="12" sm="12" md="10" lg="10" xl="10">
							<b-form-input
								v-model="local_data.search_subject"
								placeholder="事案名（部分一致）を入力して検索してください"
								maxlength="100"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col>
							<b-button
								variant="outline-secondary"
								class="ms-2 float-end"
								v-on:click="get_comment_list()"
								:disabled="state_search_button"
								>検索</b-button
							>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<!-- コメント履歴一覧 -->
			<b-table class="mt-3" striped hover :items="bind_data.list" :fields="local_data.fields">
				<!-- ヘッダーの文字を2行に -->
				<template #head(talk_room)>
					<div class="text-center">
						<div>トーク</div>
						<div>ルーム</div>
					</div>
				</template>
				<template #head(time_line)>
					<div class="text-center">
						<div>タイム</div>
						<div>ライン</div>
					</div>
				</template>
				<template #head(basics_comment)>
					<div class="text-center">
						<div>相談</div>
						<div>(事案)</div>
					</div>
				</template>
				<template #head(form_basics_comment)>
					<div class="text-center">
						<div>相談</div>
						<div>(報告)</div>
					</div>
				</template>
				<!-- トークルーム -->
				<template #cell(talk_room)="row">
					<b-button block class="float-end text-nowrap" variant="outline-secondary" size="sm" v-on:click="output_talkroom(row)"
						>CSV出力</b-button
					>
				</template>
				<!-- タイムライン -->
				<template #cell(time_line)="row">
					<b-button block class="float-end text-nowrap" variant="outline-secondary" size="sm" v-on:click="output_timeline(row)"
						>CSV出力</b-button
					>
				</template>
				<!-- 上司相談（事案） -->
				<template #cell(basics_comment)="row">
					<b-button block class="float-end text-nowrap" variant="outline-secondary" size="sm" v-on:click="output_case(row)"
						>CSV出力</b-button
					>
				</template>
				<!-- 上司相談（報告） -->
				<template #cell(form_basics_comment)="row">
					<b-button block class="float-end text-nowrap" variant="outline-secondary" size="sm" v-on:click="output_form(row)"
						>CSV出力</b-button
					>
				</template>
			</b-table>
		</div>

		<br /><br /><br />
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
// import validator from 'validator';
import axios from "axios";
//import moment from 'moment-timezone';
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
// import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
// import treeselectdialogs2 from '@/components/TreeSelectDialogs2.vue';
import {
	BAlert,
	BFormGroup,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	BCollapse,
	BCol,
	BRow,
	BFormSelect,
	BFormSelectOption,
	BFormRadio,
	BFormRadioGroup,
	BTable,
} from "bootstrap-vue-next";

export default {
	name: "CommentHistory", // ココを更新
	components: {
		Loading,
		// treeselectdialogs,
		// treeselectdialogs2,
		BAlert,
		BFormGroup,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		BCollapse,
		BCol,
		BRow,
		BFormSelect,
		BFormSelectOption,
		BFormRadio,
		BFormRadioGroup,
		BTable,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// 検索条件表示トグル
				search_toggle: false,
				search_toggle_rotate: "0",

				// 検索結果/組織一覧
				list: [],
			},
			// ローカルデータ
			local_data: {
				//検索/事案番号
				bas_case_number: "",
				//検索/事案名
				search_subject: "",
				// 検索条件：日付
				search_date: "",
				between_edit_start_time: null,
				search_date_end: null,
				between_edit_end_time: null,

				// 選択行
				row: null,
				fields: [
					{ key: "bas_case_number", label: "事案番号", sortable: true },
					{ key: "bas_subject", label: "事案名", sortable: true },
					{ key: "bas_occur_date_time", label: "発生日時", sortable: true },
					{ key: "talk_room", label: "トークルーム" },
					{ key: "time_line", label: "タイムライン" },
					{ key: "basics_comment", label: "相談(事案)" },
					{ key: "form_basics_comment", label: "相談(報告)" },
					{ key: "app_cmd", label: "", class: "text-nowrap" },
				],
			},
			state_data: {
				// treeselectdialogs: true,
				// treeselectdialogs2: true,

				// 検索条件：日付
				search_date: null,
				between_edit_start_time: null,
				search_date_end: null,
				between_edit_end_time: null,
			},
		};
	},

	// 既定計算
	computed: {
		state_search_button() {
			return !this.validSearch();
		},
	},
	// インスタンス初期化後
	created() {
		// // タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "コメント履歴" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		await this.get_comment_list();

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		// 検索条件トグル
		search_toggle() {
			if (this.bind_data.search_toggle) {
				this.bind_data.search_toggle_rotate = "0";
			} else {
				this.bind_data.search_toggle_rotate = "180";
			}
			this.bind_data.search_toggle = !this.bind_data.search_toggle;
		},

		/**
		 * 検索条件チェック
		 * 発生日時はセットで指定されないとエラーにする
		 */
		validSearch() {
			this.state_data.between_edit_start_time = null;
			this.state_data.search_date = null;
			this.state_data.between_edit_end_time = null;
			this.state_data.search_date_end = null;

			let result = true;
			if (this.local_data.search_date && !this.local_data.between_edit_start_time) {
				result = false;
				this.state_data.between_edit_start_time = false;
			} else if (!this.local_data.search_date && this.local_data.between_edit_start_time) {
				result = false;
				this.state_data.search_date = false;
			}
			if (this.local_data.search_date_end && !this.local_data.between_edit_end_time) {
				result = false;
				this.state_data.between_edit_end_time = false;
			} else if (!this.local_data.search_date_end && this.local_data.between_edit_end_time) {
				result = false;
				this.state_data.search_date_end = false;
			}

			return result;
		},

		//コメント履歴一覧の取得
		async get_comment_list() {
			//日付の入力が不正なら検索を行わない
			if (!this.validSearch()) {
				return;
			}

			// ローディング開始
			this.loader.is_loading = true;

			let startDatetime = "";
			if (this.local_data.search_date && this.local_data.between_edit_start_time) {
				startDatetime = `${this.local_data.search_date} ${this.local_data.between_edit_start_time}`;
			}
			let endDatetime = "";
			if (this.local_data.search_date_end && this.local_data.between_edit_end_time) {
				endDatetime = `${this.local_data.search_date_end} ${this.local_data.between_edit_end_time}`;
			}

			let url =
				import.meta.env.VITE_GET_COMMENT_HISTORY_LIST +
				"?sid=" +
				this.$store.state.sid +
				"&bas_subject=" +
				encodeURIComponent(this.local_data.search_subject) +
				"&bas_case_number=" +
				encodeURIComponent(this.local_data.bas_case_number) +
				"&bas_occur_start_date_time=" +
				startDatetime +
				"&bas_occur_end_date_time=" +
				endDatetime;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				this.bind_data.list = [];

				if (response.data.result == "OK") {
					if (response.data.comment_history_list.length > 0) {
						this.bind_data.list = response.data.comment_history_list;
					} else {
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "検索結果がありません。";
					}
				} else {
					console.log("その他のエラー");
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//トークルーム出力
		async output_talkroom(row) {
			console.log("トークルーム出力");
			console.log(row);

			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			let url =
				import.meta.env.VITE_BTALERT_CHAT_GET_ALL +
				"?cnt_uuid=" +
				"btalert" +
				"&chat_send_uuid=" +
				String(row.item.bas_chat_group_uuid) +
				"&chat_type=" +
				"group";

			let header = {
				"x-api-key": import.meta.env.VITE_API_KEY,
			};

			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url, { headers: header });
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let bas_case_number = row.item.bas_case_number;
					let bas_subject = row.item.bas_subject;
					let rows = [];
					rows.push('"事案番号","件名","発信日時","発信者","メッセージ","写真","位置情報","ドキュメント"');
					for (let i = 0; i < response.data.chat_info.length; i++) {
						let cols =
							'"' +
							bas_case_number +
							'","' +
							bas_subject +
							'","' +
							response.data.chat_info[i].chat_time +
							'","' +
							response.data.chat_info[i].chat_sent_name +
							'","' +
							response.data.chat_info[i].chat_message +
							'"';
						rows.push(cols);
					}
					let link = document.createElement("a");
					// link.download = "talkroom_comment_history.csv";
					link.download = "トークルームコメント履歴.csv";
					let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//タイムライン出力
		async output_timeline(row) {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			let url =
				import.meta.env.VITE_GET_TIMELINES_COMMENT_CSV +
				"?sid=" +
				this.$store.state.sid +
				"&bas_uuid=" +
				String(row.item.bas_uuid);
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let rows = [];
					for (let i = 0; i < response.data.timelines_comment_list.length; i++) {
						let cols = response.data.timelines_comment_list[i];
						rows.push('"' + cols.join('","') + '"');
					}
					let link = document.createElement("a");
					// link.download = "timelines_comment_history.csv";
					link.download = "タイムラインコメント履歴.csv";
					let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//上司相談（事案）出力
		async output_case(row) {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			let url =
				import.meta.env.VITE_GET_CONSULTATION_BASICS_COMMENT_CSV +
				"?sid=" +
				this.$store.state.sid +
				"&bas_uuid=" +
				String(row.item.bas_uuid);
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let rows = [];
					for (let i = 0; i < response.data.consultation_basics_comment_list.length; i++) {
						let cols = response.data.consultation_basics_comment_list[i];
						rows.push('"' + cols.join('","') + '"');
					}
					let link = document.createElement("a");
					// link.download = "consultation_basics_comment.csv";
					link.download = "相談事案履歴.csv";
					let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//上司相談（帳票出力時）出力
		async output_form(row) {
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let UTF_8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

			let url =
				import.meta.env.VITE_GET_CONSULTATION_FORM_BASICS_COMMENT_CSV +
				"?sid=" +
				this.$store.state.sid +
				"&bas_uuid=" +
				String(row.item.bas_uuid);
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let rows = [];
					for (let i = 0; i < response.data.consultation_form_basics_comment_list.length; i++) {
						let cols = response.data.consultation_form_basics_comment_list[i];
						rows.push('"' + cols.join('","') + '"');
					}
					let link = document.createElement("a");
					// link.download = "consultation_form_basics_comment.csv";
					link.download = "相談報告履歴.csv";
					let blob = new Blob([UTF_8_BOM, rows.join("\n")], { type: "text/csv;charset=utf-8" });
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
	},
};
</script>
