import { createRouter, createWebHistory } from "vue-router";
import Init from "@/views/Init.vue";
import Login from "@/views/Login.vue";
import Auth from "@/views/Auth.vue";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";

import ReportingData from "@/views/ReportingData.vue";
import DownLoad from "@/views/DownLoad.vue";
import DownLoadSelect from "@/views/DownLoadSelect.vue";
import Notice from "@/views/Notice.vue";
import ListDownLoad from "@/views/ListDownLoad.vue";
import CaseDetails from "@/views/CaseDetails.vue";
import CaseDetailsDiff from "@/views/CaseDetailsDiff.vue";
import CaseDetailsView from "@/views/CaseDetailsView.vue";
import CaseDetailsEdit from "@/views/CaseDetailsEdit.vue";
import CaseDetailsEditConfirm from "@/views/CaseDetailsEditConfirm.vue";
import CaseDetailsSelect from "@/views/CaseDetailsSelect.vue";
import CaseDetailsViewSelect from "@/views/CaseDetailsViewSelect.vue";
import OutputFormRegist from "@/views/OutputFormRegist.vue";
import OrganizationManage from "@/views/OrganizationManage.vue";
import UserManage from "@/views/UserManage.vue";
import AffiliationApproval from "@/views/AffiliationApproval.vue";
import PeriodReportConfig from "@/views/PeriodReportConfig.vue";
import AffiliationRequest from "@/views/AffiliationRequest.vue";
import ProfileManage from "@/views/ProfileManage.vue";
import PasswordManage from "@/views/PasswordManage.vue";
import AuthManage from "@/views/AuthManage.vue";
import CompanysManage from "@/views/CompanysManage.vue";
import CompanysAccessControlManage from "@/views/CompanysAccessControlManage.vue";
import ManagedManage from "@/views/ManagedManage.vue";
import BlueprintManage from "@/views/BlueprintManage.vue";
import ClassManage from "@/views/ClassManage.vue";
import FormManage from "@/views/FormManage.vue";
import NoticeManage from "@/views/NoticeManage.vue";
import CabinetManage from "@/views/CabinetManage.vue";
import OrganizationView from "@/views/OrganizationView.vue";
import CommentHistory from "@/views/CommentHistory.vue";
import AccessLogDownload from "@/views/AccessLogDownload.vue";
import LinkTimeoutManage from "@/views/LinkTimeoutManage.vue";

const routes = [
	{
		path: "/",
		name: "Init",
		component: Init,
	},
	{
		path: "/Login",
		name: "Login",
		component: Login,
	},
	{
		path: "/Auth",
		name: "Auth",
		component: Auth,
		props: true,
	},
	{
		path: "/Home",
		name: "Home",
		component: Home,
		children: [
			{
				path: "Dashboard",
				name: "/Home/Dashboard",
				component: Dashboard,
			},
			{
				// 報告データ
				path: "ReportingData",
				name: "/Home/ReportingData",
				component: ReportingData,
				props: true,
			},
			{
				// ダウンロード【未使用？】
				path: "DownLoad",
				name: "/Home/DownLoad",
				component: DownLoad,
			},
			{
				// ダウンロード
				path: "DownLoadSelect",
				name: "/Home/DownLoadSelect",
				component: DownLoadSelect,
			},
			{
				// 一覧ダウンロード【未使用？】
				path: "ListDownLoad",
				name: "/Home/ListDownLoad",
				component: ListDownLoad,
			},
			{
				// お知らせ
				path: "Notice",
				name: "/Home/Notice",
				component: Notice,
			},
			{
				// 詳細選択
				path: "CaseDetailsSelect",
				name: "/Home/CaseDetailsSelect",
				component: CaseDetailsSelect,
			},
			{
				// 詳細選択
				path: "CaseDetailsViewSelect",
				name: "/Home/CaseDetailsViewSelect",
				component: CaseDetailsViewSelect,
			},
			{
				// 詳細
				path: "CaseDetails",
				name: "/Home/CaseDetails",
				component: CaseDetails,
			},
			{
				// 詳細比較
				path: "CaseDetailsDiff",
				name: "/Home/CaseDetailsDiff",
				component: CaseDetailsDiff,
			},
			{
				// 閲覧
				path: "CaseDetailsView",
				name: "/Home/CaseDetailsView",
				component: CaseDetailsView,
			},
			{
				// 詳細編集
				path: "CaseDetailsEdit",
				name: "/Home/CaseDetailsEdit",
				component: CaseDetailsEdit,
			},
			{
				// 詳細編集確認（相談）
				path: "CaseDetailsEditConfirm",
				name: "/Home/CaseDetailsEditConfirm",
				component: CaseDetailsEditConfirm,
			},
			{
				// 管理対象設定
				path: "ManagedManage",
				name: "/Home/ManagedManage",
				component: ManagedManage,
			},
			{
				// 権限設定
				path: "AuthManage",
				name: "/Home/AuthManage",
				component: AuthManage,
			},
			{
				// 組織設定
				path: "OrganizationManage",
				name: "/Home/OrganizationManage",
				component: OrganizationManage,
			},
			{
				// 会社設定
				path: "CompanysManage",
				name: "/Home/CompanysManage",
				component: CompanysManage,
			},
			{
				// 会社アクセス制限設定
				path: "CompanysAccessControlManage",
				name: "/Home/CompanysAccessControlManage",
				component: CompanysAccessControlManage,
			},
			{
				// ユーザー設定
				path: "UserManage",
				name: "/Home/UserManage",
				component: UserManage,
			},
			{
				// 所属承認
				path: "AffiliationApproval",
				name: "/Home/AffiliationApproval",
				component: AffiliationApproval,
			},
			{
				// 出力帳票登録
				path: "OutputFormRegist",
				name: "/Home/OutputFormRegist",
				component: OutputFormRegist,
			},
			{
				// 定期レポート出力設定
				path: "PeriodReportConfig",
				name: "/Home/PeriodReportConfig",
				component: PeriodReportConfig,
			},
			{
				// 所属申請
				path: "AffiliationRequest",
				name: "/Home/AffiliationRequest",
				component: AffiliationRequest,
			},
			{
				// プロフィール更新
				path: "ProfileManage",
				name: "/Home/ProfileManage",
				component: ProfileManage,
			},
			{
				// パスワード更新
				path: "PasswordManage",
				name: "/Home/PasswordManage",
				component: PasswordManage,
			},
			{
				// 図面設定
				path: "BlueprintManage",
				name: "/Home/BlueprintManage",
				component: BlueprintManage,
			},
			{
				// 区分設定
				path: "ClassManage",
				name: "/Home/ClassManage",
				component: ClassManage,
			},
			{
				// フォーム編集
				path: "FormManage",
				name: "/Home/FormManage",
				component: FormManage,
			},
			{
				// お知らせ設定
				path: "NoticeManage",
				name: "/Home/NoticeManage",
				component: NoticeManage,
			},
			{
				// 書庫
				path: "CabinetManage",
				name: "/Home/CabinetManage",
				component: CabinetManage,
			},
			{
				// 組織表示
				path: "OrganizationView",
				name: "/Home/OrganizationView",
				component: OrganizationView,
			},
			{
				// コメント履歴
				path: "CommentHistory",
				name: "/Home/CommentHistory",
				component: CommentHistory,
			},
			{
				// アクセスログダウンロード
				path: "AccessLogDownload",
				name: "/Home/AccessLogDownload",
				component: AccessLogDownload,
			},
			{
				// リンク有効期限設定
				path: "LinkTimeoutManage",
				name: "/Home/LinkTimeoutManage",
				component: LinkTimeoutManage,
			},
		],
	},
];

// const router = new VueRouter({
//   mode: 'history',
//   base: ( process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stress' ) ? '/btalert/' : '/',
//   routes
// })

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	//  base: ( process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stress' ) ? '/btalert/' : '/',
	routes,
});

export default router;
