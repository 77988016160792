<template>
	<div class="FormManage">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- Notivue -->
		<Notivue v-slot="item">
			<Notification :item="item" />
		</Notivue>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div class="mt-4" v-if="state_data.template_table">
				<b-row>
					<b-col>
						<table class="table table-striped table-bordered timelimit-list">
							<thead>
								<tr class="thead-dark">
									<th width="5%" nowrap>No</th>
									<th>リンクの種類</th>
									<th class="timelimit" width="160px">時間</th>
								</tr>
							</thead>
							<tbody tag="tbody">
								<tr v-for="item in bind_data.row_data" :key="item.id">
									<td nowrap>{{ item.index }}</td>
									<td>
										{{ item.prm_name }}
									</td>
									<td nowrap>
										<b-input-group class="timelimit" append="時間">
											<b-form-input
												type="number"
												v-model="item.prm_value"
												class="m-0"
												:formatter="numberFormatter"
												min="1"
												max="999"
												maxlength=""
												:state="checkValue(item)"></b-form-input>
										</b-input-group>
									</td>
								</tr>
							</tbody>
						</table>
					</b-col>
				</b-row>
				<div class="mb-3">
					<div class="float-end">
						<b-button variant="outline-secondary" class="m-1" :disabled="isFormInputError" v-on:click="update_confirm()"
							>更新する</b-button
						>
					</div>
				</div>
			</div>

			<!-- モーダルダイアログ -->
			<b-modal
				ref="confirm-modal"
				title="有効期限の更新"
				@cancel="update_cancel()"
				cancel-variant="outline-secondary"
				cancel-title="キャンセル"
				@ok="update()"
				ok-variant="danger"
				ok-title="更新">
				<span style="word-break: keep-all">各リンクの有効期限（時間）を更新します。よろしいですか?</span>
			</b-modal>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import CommonDataSet from "@/lib/CommonDataSet.js";
import { BAlert, BButton, BRow, BCol, BInputGroup, BFormInput, BModal } from "bootstrap-vue-next";
import { Notivue, Notification, push } from "notivue";

export default {
	name: "FormManage", // ココを更新
	components: {
		Loading,
		Notivue,
		Notification,
		push,
		BAlert,
		BButton,
		BRow,
		BCol,
		BInputGroup,
		BFormInput,
		BModal,
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},

			// バインドデータ
			bind_data: {
				row_data: [],
			},

			// ローカルデータ
			local_data: {
				param_list: [],
			},

			// 状態データ
			state_data: {
				template_table: false,
			},
		};
	},

	// 既定計算
	computed: {
		/**
		 * 値チェック
		 *
		 * @returns
		 */
		checkValue() {
			return (item) => {
				if (item.prm_value < 1 || item.prm_value > 999) {
					item.state = false;
				} else {
					item.state = true;
				}

				return item.state;
			};
		},

		/**
		 * フォーム入力チェック
		 *
		 * @returns
		 */
		isFormInputError() {
			for (const elem of this.bind_data.row_data) {
				if (elem.state == false) {
					return true;
				}
			}

			return false;
		},
	},

	// インスタンス初期化後
	async created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "リンク有効期限設定" );

		this.state_data.template_table = false;
	},

	// インスタンス破棄後
	destroyed: function () {},

	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// データの読み込み
		this.local_data.param_list = await this.loadData();

		// リスト生成
		this.make_list();

		this.state_data.template_table = true;
	},

	// ローカル関数
	methods: {
		/**
		 * 数値フォーマッター
		 *
		 * @param {*} value
		 * @returns
		 */
		numberFormatter: function (value) {
			console.log(value);

			// 数字以外除去、先頭0づめを除去
			value = value.replaceAll(/[^0-9]/g, "").replaceAll(/^0*/g, "");

			// 桁数チェック
			if (value.length > 3) {
				// 3桁を超えたら3桁までにする

				value = value.substring(0, 3);
			}

			return value;
		},

		/**
		 * 読み込み
		 */
		loadData: async function () {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_GET_SHARED_URL_TIMELIMIT + "?sid=" + this.$store.state.sid;
			let paramList = [];

			try {
				let response = await axios.get(url);

				console.log(this.$route.path + ":response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					paramList = response.data.param_list;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				// ローディング終了
				this.loader.is_loading = false;
			}

			console.log(this.$route.path + ":param_list=" + JSON.stringify(paramList));

			return paramList;
		},

		/**
		 * 表の生成
		 */
		make_list: function () {
			for (const elem of this.local_data.param_list) {
				let prmName = elem.prm_name.split(":");
				let paramInfo = {
					index: prmName[0],
					prm_key: elem.prm_key,
					prm_name: prmName[1],
					prm_value: elem.prm_value,
					state: true,
				};

				this.bind_data.row_data.push(paramInfo);
			}
		},

		update_confirm() {
			this.$refs["confirm-modal"].show();
		},

		update_cancel() {
			this.$refs["confirm-modal"].hide();
		},

		async update() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_SHARED_URL_TIMELIMIT;
			let post_data = {
				sid: this.$store.state.sid,
			};

			for (const elem of this.bind_data.row_data) {
				console.log(elem);

				let paramKey = elem.prm_key.replaceAll("sid_time_limit_", "");

				post_data[paramKey] = elem.prm_value;
			}

			post_data.sid = this.$store.state.sid;

			console.log(post_data);

			try {
				let response = await axios.put(url, post_data);

				console.log(this.$route.path + ":add_response=" + JSON.stringify(response));

				if (response.data.result == "OK") {
					//ローディング終了

					this.loader.is_loading = false;

					// this.$bvToast.toast( '有効期限を更新しました。', {
					// 	title: "更新完了",
					// 	autoHideDelay: 3000,
					// 	appendToast: true,
					// 	variant: "success",
					// 	solid: true
					// } );

					push.success({
						title: "更新完了",
						message: "有効期限を更新しました。",
					});
				} else {
					// ローディング終了

					this.loader.is_loading = false;
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了

				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			} finally {
				this.$refs["confirm-modal"].hide();
			}
		},
	},
};
</script>
