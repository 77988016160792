<template>
	<div class="CaseDetails">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-button v-on:click="back()" variant="outline-secondary" class="mt-2 mb-2">戻る</b-button>

			<div v-if="state_data.bas_info">
				<b-card header-bg-variant="light" class="mt-2 mb-2">
					<template #header>
						<h4 class="mb-0 font-weight-bold">件名</h4>
					</template>
					{{ bind_data.basics_info.bas_subject_disp }}
				</b-card>
				<b-card header-bg-variant="light" class="mt-2 mb-2">
					<template #header>
						<h4 class="mb-0 font-weight-bold">事案番号</h4>
					</template>
					{{ bind_data.basics_info.bas_case_number_disp }}
				</b-card>

				<template v-if="state_data.form_info_load">
					<b-table class="mt-3" hover :items="bind_data.fbs_lists" :fields="local_data.fbs_fields" responsive="sm">
						<!-- <template #head(fbs_report_title)="scope"> -->
						<template #head(fbs_report_title)>
							<div class="text-nowrap">名称</div>
						</template>
						<!-- -->
						<template #cell(fbs_status)="row">
							<template v-if="isConsulted(row.item) == 1">
								<b-badge variant="primary" class="float-start p-2">相談あり</b-badge>
							</template>
							<template v-if="isConsulted(row.item) == 2">
								<b-badge variant="primary" class="float-start p-2">相談中</b-badge>
							</template>
						</template>
						<!-- 編集ボタン -->
						<template #cell(app_cmd)="row">
							<b-button v-on:click="auth(row.item)" variant="outline-secondary" size="sm" class="float-end m-1">閲覧</b-button>
						</template>
					</b-table>
				</template>
			</div>
		</div>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import perm from "@/lib/perm.js";
import { mapActions } from "vuex";
import {
	BAlert,
	BCol,
	BRow,
	BFormFile,
	BModal,
	BFormTextarea,
	BButton,
	BCard,
	BTable,
	BBadge,
	BCardTitle,
	BCardText,
} from "bootstrap-vue-next";

export default {
	name: "CaseDetails", // ココを更新
	components: {
		Loading,
		BAlert,
		BCol,
		BRow,
		BFormFile,
		BModal,
		BFormTextarea,
		BButton,
		BCard,
		BTable,
		BBadge,
		BCardTitle,
		BCardText,
	},

	// 共通関数
	mixins: [common, perm],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				bas_info: null,
				fbs_lists: [],
				basics_info: {
					bas_subject_disp: "",
					bas_case_number_disp: "",
				},
			},
			// ローカルデータ
			local_data: {
				fbs_fields: [
					{ key: "fbs_status", label: "", class: "text-nowrap" },
					{ key: "fbs_version_number", label: "番号", class: "text-nowrap", formatter: "hyphen_formatter" },
					{ key: "fbs_original_number", label: "コピー元", class: "text-nowrap", formatter: "hyphen_formatter" },
					{ key: "fbs_save_date_time_show", label: "保存日付", class: "text-nowrap" },
					{ key: "fbs_report_title", label: "名称" },
					{ key: "fbs_form_comp_type_name", label: "状態", class: "text-nowrap" },
					{ key: "fbs_reporter", label: "報告者", class: "text-nowrap" },
					{ key: "app_cmd", label: "", class: "text-nowrap" },
				],
			},
			// 状態
			state_data: {
				bas_info: null,
				form_info_load: false,
			},
		};
	},

	// 既定計算
	computed: {},
	// インスタンス初期化後
	created() {
		// this.$eventHub.$emit( "navbar_title", "閲覧データ選択");
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		this.state_data.form_info_load = false;

		let basUuid = this.$store.state.selected_bas_info.bas_uuid;

		try {
			const ret = await this.getForm({
				_this: this,
				basUuid: basUuid,
			});

			if (ret.result === "OK") {
				this.$store.dispatch("commit_set_form_info", JSON.parse(ret.form_json_data));
				this.$store.dispatch("commit_set_sid", ret.sid);
				this.state_data.form_info_load = true;
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = ret.message;
			}
		} catch (err) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify(err);
		}

		try {
			const config = {
				params: {
					sid: this.$store.state.sid,
					bas_uuid: basUuid,
				},
			};

			//報告データのリストを取得
			const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_FORM_BASICS_VIEW_LIST, config);
			if (res.data.result === "OK") {
				this.bind_data.fbs_lists = res.data.form_basics_list;

				for (let i = 0; i < this.bind_data.fbs_lists.length; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					fbs.fbs_save_date_time_show = fbs.fbs_save_date_time.substr(0, 16);
					if (fbs.fbs_form_comp_type == "confirmpending") {
						fbs._rowVariant = "success";
					}
					if (fbs.fbs_form_comp_type == "fixpending") {
						fbs._rowVariant = "warning";
					}
				}
				if (res.data.message !== "") {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}

				this.bind_data.basics_info = res.data.basics_info;
				this.$store.dispatch("commit_set_sid", res.data.sid);
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		} catch (e) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify(e);
		}

		this.bind_data.bas_info = this.$store.state.selected_bas_info;

		// 画面表示
		this.state_data.bas_info = true;

		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		...mapActions("api", {
			getForm: "getForm",
		}),

		back() {
			//画面遷移
			this.transition("/Home/ReportingData");
		},

		auth(fbs_item) {
			// 選択された報告をストアに保管する
			this.$store.dispatch("commit_set_selected_fbs_info", fbs_item); // グローバル変数（store）に格納
			//画面遷移
			this.trans_forward("/Home/CaseDetailsView");
		},

		isConsulted(item) {
			console.log(item.fbs_consult_usr_uuid);
			if (item.fbs_consult_usr_uuid != null) {
				if (item.fbs_form_comp_type === "confirmpending") {
					if (item.fbs_consult_usr_uuid == this.$store.state.users_info.usr_uuid) {
						return 1; //相談あり
					}
				}
				if (item.fbs_form_comp_type === "fixpending") {
					if (item.fbs_request_usr_uuid == this.$store.state.users_info.usr_uuid) {
						return 2; //相談中
					}
				}
			}
			return 0;
		},
		//コピー元の数値が空ならハイフンを入れる
		hyphen_formatter(check_number) {
			if (check_number) {
				return check_number;
			} else {
				return "-";
			}
		},
	},
};
</script>
