<template>
	<b-navbar toggleable="lg" v-b-color-mode="'dark'" style="background-color: #005eae">
		<!-- ハンバーガーメニューボタン -->
		<b-navbar-brand href="#" v-on:click="$emit('sidemenuOpenFunction')"
			><BootstrapIcon icon="list"></BootstrapIcon
		></b-navbar-brand>

		<!-- タイトル -->
		<b-navbar-nav>
			<b-nav-text class="text-start">
				<span class="nav-text-style me-3" v-on:click="link_to('/Home/Dashboard')">{{ system_name }}</span>
				<span class="nav-subtext-style">{{ subTitle }}</span>
			</b-nav-text>
		</b-navbar-nav>

		<b-navbar-nav class="ms-auto">
			<b-nav-form>
				<template v-if="hasNewsDisplayPerm">
					<div style="position: relative">
						<BootstrapIcon
							icon="bell"
							class="border rounded p-1"
							v-on:click="link_to('/Home/Notice')"
							variant="secondary"
							style="background-color: #ffffff; cursor: pointer"
							font-scale="2"
							size="2x"></BootstrapIcon>
						<div v-if="isNewArrivalNews" class="news_badge"><p class="news_count"></p></div>
					</div>
				</template>
				<!-- 未読のお知らせがある時は、variant="outline-danger" にする -->
				<b-nav-item-dropdown right class="ms-2">
					<template #button-content>
						<template v-if="isNotMobilePhone">
							<em>{{ bind_data.usr_name }}</em>
						</template>
						<template v-if="!isNotMobilePhone">
							<BootstrapIcon
								icon="person"
								class="border rounded p-1"
								variant="secondary"
								style="background-color: #ffffff"
								font-scale="2"></BootstrapIcon>
						</template>
					</template>
					<b-dropdown-item v-on:click="link_to('/Home/AffiliationRequest')">所属申請</b-dropdown-item>
					<b-dropdown-item v-on:click="link_to('/Home/ProfileManage')">プロフィール表示</b-dropdown-item>
					<b-dropdown-item v-on:click="link_to('/Home/PasswordManage')">パスワード更新</b-dropdown-item>
					<b-dropdown-item v-on:click="logout()">ログアウト</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-nav-form>
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
import common from "@/lib/common.js";
import axios from "axios";
import { mapGetters } from "vuex";
import {
	BAlert,
	BButton,
	BCard,
	BFormCheckbox,
	BInputGroup,
	BInputGroupText,
	BFormInput,
	BModal,
	BNavbarBrand,
	vBToggle,
	BNavText,
	BDropdownItem,
	BNavItemDropdown,
	BNavForm,
	BListGroup,
	BListGroupItem,
	BNavbar,
	BNavbarNav,
	vBColorMode
} from "bootstrap-vue-next";

export default {
	name: "Navbar",
	components: {
		BAlert,
		BButton,
		BCard,
		BFormCheckbox,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BModal,
		BNavbarBrand,
		vBToggle,
		BNavText,
		BDropdownItem,
		BNavItemDropdown,
		BNavForm,
		BListGroup,
		BListGroupItem,
		BNavbar,
		BNavbarNav,
		vBColorMode,
	},

	props: ["subTitle"],

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			system_name: import.meta.env.VITE_SYSTEM_NAME,
			// バインドデータ
			bind_data: {
				nav_title: "",
				usr_name: "",
				news_count: 0,
			},
			// エラーメッセージ
			errors: [],
			error_message: {},
		};
	},

	directives: {
		BColorMode: vBColorMode,
	},

	// 既定計算
	computed: {
		...mapGetters("api", ["isNewArrivalNews"]),

		hasNewsDisplayPerm() {
			const idx = this.$store.state.users_info.organization_list.findIndex(
				(o) => o.org_ath_uuids.indexOf("web_news_display") !== -1
			);
			const idxAllSys = this.$store.state.users_info.organization_list.findIndex(
				(o) => o.org_ath_uuids.indexOf("all_sys") !== -1
			);
			return idx !== -1 || idxAllSys !== -1;
		},
		badgeNum: function () {
			return function (num) {
				if (num > 99) {
					return "99+";
				} else {
					return num;
				}
			};
		},
	},

	// インスタンス初期化後
	created: function () {},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		// タイトル更新イベント
		// this.$eventHub.$on( "navbar_title", this.title_set );
		// ユーザー名セット
		this.bind_data.usr_name = this.$store.state.users_info.usr_name;
		await this.news_count();
	},

	// ローカル関数
	methods: {
		async news_count() {
			this.loader.is_loading = true;
			try {
				let url =
					import.meta.env.VITE_RESTAPI_GET_NEWS_COUNT +
					"?sid=" +
					this.$store.state.sid +
					"&org_uuid=" +
					String(this.$store.state.users_info.usr_org_uuid);
				const res = await axios.get(url);
				if (res.data.result === "OK") {
					this.bind_data.news_count = Number(res.data.news_count);
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}

			this.loader.is_loading = false;
		},

		// title_set: function( title ){
		//   this.bind_data.nav_title = title;
		// },

		link_to: function (to) {
			//console.log("link_to="+this.$route.path);
			if (this.$route.path != to) {
				// 画面遷移
				this.$router.push({ path: to });
			}
		},

		logout: function () {
			// クッキーを削除
			this.$cookies.remove("sid");
			// // ストアの初期化
			// this.$store.dispatch("commit_reset_state");
			// 画面遷移
			window.location = import.meta.env.VITE_LOGIN_URL;

			if (common.bcpWindow && !common.bcpWindow.closed) {
				common.bcpWindow.close();
			}
		},
	},
};
</script>

<style scoped>
.menu-icon {
	font-style: bold;
}
.nav-dropdown-style {
	width: 240px;
}
.nav-text-style {
	font-size: 1.5em;
	font-weight: bold;
	color: white;
	cursor: pointer;
}
.nav-subtext-style {
	font-size: 1em;
	font-weight: bold;
	color: #eeeeee;
}

div.news_badge {
	top: -8px;
	position: absolute;
	right: -8px;
	background-color: #f00;
	border: 1px solid #fff;
	border-radius: 50%;
	text-align: center;
	padding-top: 2px;
	padding-left: 1px;
	padding-right: 2px;
	height: 18px;
	min-width: 18px;
}

.news_count {
	color: #fff;
	font-size: 0.7rem;
	font-weight: bold;
	margin-bottom: 0;
}
</style>
