<template>
	<div class="OutputFormRegist">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<div class="mt-3">
				<b-button variant="outline-secondary" v-on:click="add()">新規</b-button>
			</div>
			<!-- 新規 -->
			<div v-if="local_data.add_data.selected">
				<b-card class="card-shadow border-1 mt-2">
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">帳票名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-input
								v-model="local_data.add_data.ofm_name"
								placeholder="帳票名"
								:state="state_add_ofm_name"
								maxlength="100"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-select
								v-model="local_data.add_data.ofm_cmp_uuid"
								:options="bind_data.org_cmp_uuid_options"
								placeholder="選択してください"
								@change="add_cmp_change()" />
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">管理対象施設</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<template v-if="bind_data.mng_options_state">
								<treeselect
									v-model="local_data.add_data.mng_selected"
									:multiple="true"
									:disable-branch-nodes="false"
									:options="bind_data.mng_options"
									placeholder="選択してください" />
							</template>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col xs="12" sm="4" md="4" lg="3" xl="2">Excelファイル</b-col>
						<b-col xs="12" sm="8" md="8" lg="9" xl="10">
							<b-form-file
								v-model="local_data.add_data.ofm_file"
								placeholder="ファイルを選択するか、ここにファイルをドロップしてください。"
								drop-placeholder="ここにファイルをドロップしてください。"
								accept=".xlsx"
								browse-text="参照">
							</b-form-file>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col>
							<b-button variant="outline-secondary" class="ms-2 float-end" v-on:click="add_new()">登録</b-button>
							<b-button variant="outline-secondary" class="float-end" v-on:click="add_cancel()">キャンセル</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>

			<!-- 出力帳票一覧 -->
			<b-table v-if="state_data.list" class="mt-3" striped hover :items="bind_data.list" :fields="bind_data.fields">
				<!-- 編集ボタン -->
				<template #cell(app_cmd)="row">
					<b-button class="float-end" variant="outline-secondary" size="sm" v-on:click="edit_row(row)">編集</b-button>
				</template>
				<!-- 編集カード -->
				<template v-slot:row-details="row">
					<b-card class="card-shadow border-1 mt-2">
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">帳票名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-input v-model="local_data.edit_data.ofm_name" placeholder="帳票名" maxlength="100"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">会社名</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-form-select
									v-model="local_data.edit_data.ofm_cmp_uuid"
									:options="bind_data.org_cmp_uuid_options"
									placeholder="選択してください"
									@change="edit_cmp_change()" />
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">管理対象施設</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<template v-if="bind_data.mng_options_state">
									<treeselect
										v-model="local_data.edit_data.mng_selected"
										:multiple="true"
										:disable-branch-nodes="false"
										:options="bind_data.mng_options"
										placeholder="選択してください" />
								</template>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col xs="12" sm="4" md="4" lg="3" xl="2">Excelファイル</b-col>
							<b-col xs="12" sm="8" md="8" lg="9" xl="10">
								<b-button variant="outline-secondary" size="sm" v-on:click="download()">ダウンロード</b-button
								><span class="ms-2">ファイル名：{{ local_data.edit_data.ofm_file_name }} </span>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col>
								<b-button variant="danger" class="float-start" v-on:click="delete_confirm()">削除</b-button>
								<b-button variant="outline-secondary" class="ms-2 float-end" v-on:click="update_row(row)">更新</b-button>
								<b-button variant="outline-secondary" class="float-end" v-on:click="cancel_row(row)">キャンセル</b-button>
							</b-col>
						</b-row>
					</b-card>
				</template>
			</b-table>
		</div>

		<!-- モーダルダイアログ -->
		<b-modal
			ref="confirm-modal"
			title="削除"
			ok-title="削除"
			ok-variant="danger"
			cancel-title="キャンセル"
			cancel-variant="outline-secondary"
			@ok="delete_row()"
			@cancel="delete_cancel()">
			<p>出力帳票：{{ local_data.delete_data.ofm_name }} を削除します。よろしいですか?</p>
			<!-- <template #modal-footer>
        <b-button v-on:click="delete_cancel()" variant="outline-secondary" class="float-end">キャンセル</b-button>
        <b-button v-on:click="delete_row()" variant="danger" class="float-end">削除</b-button>
      </template> -->
		</b-modal>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import validator from "validator";
import common from "@/lib/common.js";
import b64toblob from "b64-to-blob";
import { BAlert, BFormFile, BButton, BCard, BFormInput, BModal, BCol, BRow, BFormSelect, BTable } from "bootstrap-vue-next";

export default {
	name: "OutputFormRegist", // ココを更新
	components: {
		Loading,
		BAlert,
		BFormFile,
		BButton,
		BCard,
		BFormInput,
		BModal,
		BCol,
		BRow,
		BFormSelect,
		BTable,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				list: [],
				fields: [
					{ key: "ofm_name", label: "帳票名", sortable: true },
					{ key: "cmp_name", label: "会社名", sortable: true },
					{ key: "ofm_save_date_time", label: "登録日時", sortable: true, class: "text-nowrap" },
					{ key: "ofm_file_name", label: "ファイル名", sortable: true },
					{ key: "app_cmd", label: "", class: "text-nowrap" },
				],
				org_cmp_uuid_options: [],
				mng_options: [],
				mng_options_state: false,
			},
			// ローカルデータ
			local_data: {
				// 情報(新規)
				add_data: {
					ofm_name: "",
					ofm_cmp_uuid: "",
					mng_selected: [],
					ofm_file: null,
					selected: false,
				},
				// 編集
				edit_data: {
					ofm_uuid: "",
					ofm_name: "",
					ofm_cmp_uuid: "",
					mng_selected: [],
					ofm_file: null,
				},
				// 削除
				delete_data: {
					ofm_name: "",
				},

				row: null,
				selected: false,
			},
			state_data: {
				list: false,
			},
		};
	},

	// 既定計算
	computed: {
		state_add_ofm_name() {
			return !validator.isEmpty(this.local_data.add_data.ofm_name);
		},
	},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "出力帳票設定" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		console.log(this.$route.path + ".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		await this.get_output_from_list();

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		// 会社リスト用会社一覧取得
		async companys_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_COMPANY_LIST + "?sid=" + this.$store.state.sid + "&cmp_type=jrebt,owner";
			try {
				let response = await axios.get(url);
				if (response.data.result == "OK") {
					this.bind_data.access_ok = true; //画面を有効化する
					this.bind_data.org_cmp_uuid_options = [];
					if (response.data.company_list.length > 0) {
						for (let i = 0; i < response.data.company_list.length; i++) {
							let options = {};
							options.text = response.data.company_list[i].cmp_name;
							options.value = response.data.company_list[i].cmp_id;
							this.bind_data.org_cmp_uuid_options.push(options);

							if (i == 0) {
								// 初期値セット
								this.local_data.add_data.ofm_cmp_uuid = response.data.company_list[i].cmp_id;
								this.bind_data.search_cmp_id = response.data.company_list[i].cmp_id;
							}
						}
					}

					// 初期化
					this.local_data.add_data.ofm_name = "";
					//this.local_data.add_data.ofm_cmp_uuid = "";
					this.local_data.add_data.mng_selected = [];
					this.local_data.add_data.ofm_file = null;
					this.local_data.add_data.elected = false;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//管理対象のプルダウン
		async get_managed_list() {
			this.bind_data.mng_options_state = false;

			//let url = import.meta.env.VITE_RESTAPI_GET_MANAGED_PULLDOWN_LIST+"?sid="+this.$store.state.sid+"&cmp_uuid="+cmp_uuid;
			let url = import.meta.env.VITE_RESTAPI_GET_MANAGED_PULLDOWN_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.mng_options = [];
					if (response.data.managed_list.length > 0) {
						for (let i = 0; i < response.data.managed_list.length; i++) {
							let item = {};
							item.label = response.data.managed_list[i].mng_name;
							item.id = response.data.managed_list[i].mng_uuid;
							this.bind_data.mng_options.push(item);
						}
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				this.bind_data.mng_options_state = true;
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		async add_cmp_change() {
			this.local_data.add_data.mng_selected = [];
			await this.get_managed_list(this.local_data.add_data.ofm_cmp_uuid);
		},
		async edit_cmp_change() {
			this.local_data.edit_data.mng_selected = [];
			await this.get_managed_list(this.local_data.edit_data.ofm_cmp_uuid);
		},

		//出力帳票一覧
		async get_output_from_list() {
			let url = import.meta.env.VITE_RESTAPI_GET_OUTPUT_FORM_LIST + "?sid=" + this.$store.state.sid;
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					this.bind_data.list = [];
					if (response.data.output_form_list.length > 0) {
						for (let i = 0; i < response.data.output_form_list.length; i++) {
							let item = {};
							item.ofm_uuid = response.data.output_form_list[i].ofm_uuid;
							item.ofm_name = response.data.output_form_list[i].ofm_name;
							item.ofm_cmp_uuid = response.data.output_form_list[i].ofm_cmp_uuid;
							item.cmp_name = response.data.output_form_list[i].cmp_name;
							if (response.data.output_form_list[i].ofm_mng_uuids != null) {
								item.ofm_mng_uuids = response.data.output_form_list[i].ofm_mng_uuids.split(",");
							} else {
								item.ofm_mng_uuids = [];
							}
							item.ofm_save_date_time = response.data.output_form_list[i].ofm_save_date_time;
							item.ofm_save_info = response.data.output_form_list[i].ofm_save_info;
							let file_info = JSON.parse(response.data.output_form_list[i].ofm_save_info);
							item.ofm_file_name = file_info.file_name;
							this.bind_data.list.push(item);
						}
					}
					this.state_data.list = true;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		async add() {
			await this.companys_list();
			await this.get_managed_list(this.local_data.add_data.ofm_cmp_uuid);
			this.bind_data.mng_selected = [];

			this.local_data.add_data.selected = true;
			if (this.local_data.selected) {
				this.local_data.row.toggleDetails();
				this.local_data.selected = false;
			}
		},
		add_cancel() {
			this.local_data.add_data.selected = false;
		},
		async add_new() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_OUTPUT_FORM;
			let post_data = new FormData();
			post_data.append("sid", this.$store.state.sid);
			post_data.append("ofm_name", this.local_data.add_data.ofm_name);
			post_data.append("ofm_file", this.local_data.add_data.ofm_file);
			post_data.append("cmp_uuid", this.local_data.add_data.ofm_cmp_uuid);
			post_data.append("mng_uuids", this.local_data.add_data.mng_selected.join(","));
			console.log("add_data=" + JSON.stringify(this.local_data.add_data));
			try {
				let response = await axios.post(url, post_data);
				console.log(this.$route.path + ":add_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.add_data.selected = false;
					// リロード
					await this.get_output_from_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
		async edit_row(row) {
			await this.companys_list();
			await this.get_managed_list(row.item.ofm_cmp_uuid);

			if (this.local_data.row != null) {
				if (this.local_data.selected) {
					this.local_data.row.toggleDetails();
				}
			}
			this.local_data.edit_data.ofm_uuid = row.item.ofm_uuid;
			this.local_data.edit_data.ofm_name = row.item.ofm_name;
			this.local_data.edit_data.ofm_cmp_uuid = row.item.ofm_cmp_uuid;
			this.local_data.edit_data.mng_selected = row.item.ofm_mng_uuids;

			this.local_data.edit_data.ofm_file_info = JSON.parse(row.item.ofm_save_info);
			this.local_data.edit_data.ofm_file_name = row.item.ofm_file_name;

			this.local_data.delete_data.ofm_name = row.item.ofm_name;

			this.local_data.add_data.selected = false;
			this.local_data.selected = true;
			row.toggleDetails();
			this.local_data.row = row;
		},
		async update_row(row) {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_OUTPUT_FORM;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.ofm_uuid = this.local_data.edit_data.ofm_uuid;
			post_data.ofm_name = this.local_data.edit_data.ofm_name;
			post_data.cmp_uuid = this.local_data.edit_data.ofm_cmp_uuid;
			post_data.mng_uuids = this.local_data.edit_data.mng_selected.join(",");

			try {
				let response = await axios.put(url, post_data);
				console.log(this.$route.path + ":upd_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					row.toggleDetails();
					// リロード
					await this.get_output_from_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
		cancel_row(row) {
			this.message.is_alert = false;
			this.message.variant = "";
			this.message.message = "";
			this.local_data.selected = false;
			row.toggleDetails();
		},
		// 削除実行
		delete_confirm() {
			this.$refs["confirm-modal"].show();
		},
		delete_cancel() {
			this.$refs["confirm-modal"].hide();
		},
		async delete_row() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = import.meta.env.VITE_RESTAPI_SET_OUTPUT_FORM;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.ofm_uuid = this.local_data.edit_data.ofm_uuid;

			console.log(this.$route.path + ":del post_data=" + JSON.stringify(post_data));
			try {
				let response = await axios.delete(url, { data: post_data });
				console.log(this.$route.path + ":del_response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					// リロード
					await this.get_output_from_list();
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				this.$refs["confirm-modal"].hide();
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				this.$refs["confirm-modal"].hide();
			}
		},

		async download() {
			let url =
				import.meta.env.VITE_RESTAPI_GET_S3_MEDIA_FILE +
				"?sid=" +
				this.$store.state.sid +
				"&bucket_name=" +
				this.local_data.edit_data.ofm_file_info.bucket_name +
				"&bucket_key=" +
				this.local_data.edit_data.ofm_file_info.bucket_key +
				"&file_name=" +
				this.local_data.edit_data.ofm_file_info.file_name;
			console.log(this.$route.path + ":url=" + url);
			try {
				let response = await axios.get(url);
				console.log(this.$route.path + ":response=" + JSON.stringify(response));
				if (response.data.result == "OK") {
					let contentType = this.local_data.edit_data.ofm_file_info.content_type;
					let blob = b64toblob(response.data.media_file, contentType);
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = this.local_data.edit_data.ofm_file_info.file_name;
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				console.log(this.$route.path + ":ex=" + JSON.stringify(ex));
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},
	},
};
</script>
