<template>
	<div class="DownLoadSelect">
		<!-- ローディング -->
		<loading v-model:active="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
		<!-- ヘッダー -->
		<!-- メッセージ -->
		<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
		<!-- メインコンテンツ -->
		<div class="container text-start">
			<b-button v-on:click="back()" variant="outline-secondary" class="mt-2 mb-2">戻る</b-button>

			<div v-if="state_data.bas_info">
				<b-card header-bg-variant="light" header="件名" header-tag="h5" class="mt-2 mb-2">
					{{ bind_data.bas_info.bas_subject }}
				</b-card>

				<div class="mt-3 font-weight-bold">編集データ</div>
				<b-table hover :items="bind_data.fbs_lists" :fields="local_data.fbs_fields">
					<!-- ラジオボタン -->
					<template #cell(app_cmd)="row">
						<b-form-radio v-model="bind_data.fbs_selected" name="edit_data" :value="row.item.fbs_uuid"></b-form-radio>
					</template>
				</b-table>

				<div class="mt-3 font-weight-bold">出力帳票フォーマット</div>
				<b-table hover :items="bind_data.format_lists" :fields="local_data.format_fields">
					<!-- ラジオボタン -->
					<template #cell(app_cmd)="row">
						<b-form-radio v-model="bind_data.format_selected" name="output_format" :value="row.item.ofm_uuid"></b-form-radio>
					</template>
				</b-table>

				<b-button variant="outline-secondary" class="float-end m-1" @click="handleDownload('pdf')" :disabled="downloadState"
					>PDFダウンロード</b-button
				>
				<b-button variant="outline-secondary" class="float-end m-1" @click="handleDownload('xlsx')" :disabled="downloadState"
					>Excelダウンロード</b-button
				>
			</div>
		</div>

		<b-modal ref="confirmForceDownload" title="出力確認" ok-title="出力" cancel-title="キャンセル" @ok="handleForceDownload()"
			>画像が正しく登録されていないデータがあります。<br />出力しますか？</b-modal
		>
		<a ref="reportDownload"></a>
	</div>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import common from "@/lib/common.js";
import image from "@/lib/image.js";
import { BAlert, BButton, BCard, BTable, BModal, BFormRadio } from "bootstrap-vue-next";

export default {
	name: "DownLoadSelect", // ココを更新
	components: {
		Loading,
		BAlert,
		BButton,
		BCard,
		BTable,
		BModal,
		BFormRadio,
	},

	// 共通関数
	mixins: [common, image],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				fbs_info: null,
				fbs_selected: "",
				fbs_lists: [],
				format_selected: "",
				format_lists: [],
			},
			// ローカルデータ
			local_data: {
				fbs_fields: [
					{ key: "app_cmd", label: "" },
					{ key: "fbs_save_date_time_show", label: "保存日付" },
					{ key: "fbs_report_title", label: "名称", class: "text-nowrap" },
					{ key: "fbs_form_comp_name", label: "状態", class: "text-nowrap" },
					{ key: "fbs_reporter", label: "報告者", class: "text-nowrap" },
					{ key: "fbs_confirmer_uuid_name", label: "確定者", class: "text-nowrap" },
				],
				format_fields: [
					{ key: "app_cmd", label: "" },
					{ key: "ofm_save_date_time_show", label: "保存日付" },
					{ key: "ofm_name", label: "名称", class: "text-nowrap" },
				],
				download_params: null,
			},
			// 状態
			state_data: {
				bas_info: null,
				force_download: false,
			},
		};
	},

	// 既定計算
	computed: {
		downloadState: function () {
			if (this.bind_data.fbs_selected && this.bind_data.format_selected) {
				return false;
			}

			return true;
		},
	},
	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		// this.$eventHub.$emit( "navbar_title", "報告データダウンロード" );
	},
	// インスタンス破棄後
	destroyed: function () {},
	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		await this.$nextTick();

		this.bind_data.bas_info = this.$store.state.selected_bas_info;

		try {
			const config = {
				params: {
					sid: this.$store.state.sid,
					fbs_bas_uuid: this.bind_data.bas_info.bas_uuid,
				},
			};

			const res = await axios.get(import.meta.env.VITE_RESTAPI_GET_FORM_BASICS_DOWNLOAD_LIST, config);
			if (res.data.result === "OK") {
				this.bind_data.fbs_lists = res.data.form_basics_list;
				this.bind_data.format_lists = res.data.output_form_list;

				for (let i = 0; i < this.bind_data.fbs_lists.length; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					fbs.fbs_save_date_time_show = fbs.fbs_save_date_time.substr(0, 16);
				}
				for (let i = 0; i < this.bind_data.format_lists.length; i++) {
					const format = this.bind_data.format_lists[i];
					format.ofm_save_date_time_show = format.ofm_save_date_time.substr(0, 16);
				}

				if (this.bind_data.fbs_lists.length !== 0) {
					this.bind_data.fbs_selected = this.bind_data.fbs_lists[0].fbs_uuid;
				}
				if (this.bind_data.format_lists.length !== 0) {
					this.bind_data.format_selected = this.bind_data.format_lists[0].ofm_uuid;
				}
				this.$store.dispatch("commit_set_sid", res.data.sid);
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		} catch (e) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify(e);
		}

		for (let i = 0; i < this.bind_data.fbs_lists.length; i++) {
			this.bind_data.fbs_lists[i].bas_info = this.$store.state.selected_bas_info;
		}

		this.bind_data.bas_info = this.$store.state.selected_bas_info;

		// 画面表示
		this.state_data.bas_info = true;

		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		back() {
			//画面遷移
			this.transition("/Home/ReportingData");
		},

		/**
		 * ダウンロード実行
		 */
		async handleDownload(type) {
			try {
				let params = null;

				if (this.state_data.force_download && this.local_data.download_params) {
					params = this.local_data.download_params;
					params.force_download = this.state_data.force_download;
				} else {
					params = {
						sid: this.$store.state.sid,
						fbs_uuid: this.bind_data.fbs_selected,
						ofm_uuid: this.bind_data.format_selected,
						ofm_type: type,
					};
				}

				const res = await axios.get(import.meta.env.VITE_RESTAPI_DOWNLOAD_FORM_BASICS_DATA, { params });

				this.state_data.force_download = false;
				this.local_data.download_params = null;

				if (res.data.result === "OK") {
					this.$store.dispatch("commit_set_sid", res.data.sid);

					const blob = this.base64ToBlobImage(res.data.media_file, res.data.mime_type);
					const url = window.URL.createObjectURL(blob);

					const link = this.$refs.reportDownload;
					link.href = url;
					const fbs = this.bind_data.fbs_lists.find((f) => f.fbs_uuid === this.bind_data.fbs_selected);
					const format = this.bind_data.format_lists.find((f) => f.ofm_uuid === this.bind_data.format_selected);
					const now = this.$dayjs().tz("Asia/Tokyo").format("YYYYMMDDHHmm");
					link.download = `【報告書】${this.bind_data.bas_info.bas_subject}-${fbs.fbs_report_title}-${format.ofm_name}-${now}.${res.data.ofm_type}`;
					link.click();
				} else {
					if (res.data.message == "no_image") {
						this.local_data.download_params = params;
						this.$refs["confirmForceDownload"].show();
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				}
			} catch (e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		async handleForceDownload() {
			this.state_data.force_download = true;

			await this.handleDownload();
		},
	},
};
</script>
