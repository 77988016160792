import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
// import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'

import { BootstrapIcon } from "@dvuckovic/vue3-bootstrap-icons";
import { injectBootstrapIcons } from "@dvuckovic/vue3-bootstrap-icons/utils";
import BootstrapIcons from "bootstrap-icons/bootstrap-icons.svg?raw";
import "@dvuckovic/vue3-bootstrap-icons/dist/style.css";

import { createNotivue } from "notivue";
import "notivue/notification.css"; // Only needed if using built-in notifications
import "notivue/animations.css"; // Only needed if using built-in animations

const notivue = createNotivue({
	position: "top-right",
	limit: Infinity,
	enqueue: true,
	avoidDuplicates: true,
	notifications: {
		global: {
			duration: 2000,
		},
	},
});

injectBootstrapIcons(BootstrapIcons);

import { createBootstrap } from "bootstrap-vue-next";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

//import EventHub from './lib/EventHub'
import VueCookies from "vue3-cookies";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";

import Treeselect from "@zanmato/vue3-treeselect";
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";

import uuid from "vue3-uuid";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import axios from "axios";

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import vue3TreeOrg from "vue3-tree-org";
import "vue3-tree-org/lib/vue3-tree-org.css";

import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc.js";
// ロケール設定
dayjs.locale("ja");

// プラグイン組み込み
dayjs.extend(utc);
dayjs.extend(timezone);

// タイムゾーンのデフォルトをJST化
dayjs.tz.setDefault("Asia/Tokyo");

const app = createApp(App);

app.component("BootstrapIcon", BootstrapIcon);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("treeselect", Treeselect);
app.component("VueDatePicker", VueDatePicker);

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
// Vue.use(EventHub)
// Vue.use(VueCookies)
// Vue.use(uuid);
// Vue.component('treeselect', Treeselect)

// library.add(fas,far)
// Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.component('font-awesome-layers', FontAwesomeLayers)
// Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// Vue.config.productionTip = false

// Vue.prototype.$http = axios;

//  new Vue({
//  	router,
//  	store,
//  	render: h => h(App)
//  }).$mount('#app')

app.use(createBootstrap());
app.use(router);
app.use(store);
// app.use(IonicVue);
app.use(uuid);
app.use(VueCookies);
// app.use(EventHub);
// // // app.use(i18n);
// // app.use(notivue);
// // app.use( PromiseDialog );
app.use(VueSidebarMenu);
app.use(notivue);
app.use(vue3TreeOrg);

library.add(fas, far);

// const db = new Dexie('btalert');
// db.version(1).stores({
// 	hoge: '++id, value',
// 	senddata: '++id, type, time, data, blob',
// 	cachedata: '[type+key], time, data',
// });

app.config.globalProperties.$http = axios;
// // app.config.globalProperties.$db = db;
app.config.globalProperties.$dayjs = dayjs;

app.mount("#app");
